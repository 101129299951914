import React from 'react';
import Button from '@snsw/react-component-library/build/Components/Button/Button';
import {ComponentWrapper, ContentWrapper, Heading, Body} from './RequestAccessComponent.styled';

const RequestAccessComponent = () => {
    const navigateToDigitalMarketplace =() => {
        window.open('https://nswgov.service-now.com/sp?id=sc_cat_item&sys_id=98201aa51b17e51077fdecac274bcbd7', '_blank');
    };
    return (
        <ComponentWrapper>
            <ContentWrapper>
                <div><Heading>Request portal access</Heading></div>
                <Body>
                    {/* eslint-disable-next-line max-len */}
                    <p>Do you work for the Department of Customer Service or Service NSW? You can get access to the Digital Notifications Portal by creating a ServiceNow ticket. </p>
                </Body>
                <div>
                    <Button
                        onClick={ navigateToDigitalMarketplace }
                        variant='secondary'
                        id='serviceNowBtn'
                    >Go to ServiceNow</Button>
                </div>
            </ContentWrapper>
        </ComponentWrapper>
    );
};

export default RequestAccessComponent;
