import Col from '@snsw/react-component-library/build/layout/Grid/Col.styled';
import {Textarea} from '@snsw/react-component-library';
import Heading from '@snsw/react-component-library/build/Components/Headings/Heading';
import FormInput from '@snsw/react-component-library/build/Components/Form/FormInput';
import Fieldset from '@snsw/react-component-library/build/Components/Form/Fieldset';
import Button from '@snsw/react-component-library/build/Components/Button/Button';
import Row from '@snsw/react-component-library/build/layout/Grid/Row.styled';
import React from 'react';
import {pushFormField, validateTextField} from '../CreateNotificationComponent/CreateNotificationComponent';

const PushContentFormComponent = ({
    inputErrors,
    setInputErrors,
    handleChange,
    pushSubject,
    pushContent,
    setShowPushPreviewModal,
    inProd
}) => {
    const isInProd = (inProd != null) ? inProd : false;
    const openPreview = async () => {
        let previewIsValid = true;
        if (!validateTextField(pushSubject, 'pushSubject', setInputErrors)) {
            setInputErrors(prevState => ({
                ...prevState,
                pushSubject: {
                    hasError: true,
                    errorMessage: 'Provide the push subject to preview or create notification'
                }
            }));
            previewIsValid = false;
        }
        if (!validateTextField(pushContent, 'pushContent', setInputErrors)) {
            setInputErrors(prevState => ({
                ...prevState,
                pushContent: {
                    hasError: true,
                    errorMessage: 'Provide push content to preview or create notification'
                }
            }));
            previewIsValid = false;
        }
        if (previewIsValid) {
            setShowPushPreviewModal(true);
        } else {
            const errorElement = document.querySelectorAll('span[class^=\'FormError\']')[0]?.previousSibling;
            errorElement?.scrollIntoView({behavior: 'smooth'});
        }
    };
    return (<>
        <Row style={ {paddingBottom:'4px'} }>
            <Col span={ 9 }>
                <Heading level={ 4 } style={ {height: 'fit-content', margin: '24px 0 0 0'} }>
                    {pushFormField.heading}
                </Heading>
                <FormInput
                    label={ pushFormField.subject.label }
                    maxLength={ 65 }
                    name={ pushFormField.subject.name }
                    placeholder={ pushFormField.subject.placeholder }
                    errorMessage={ inputErrors.pushSubject.errorMessage }
                    hasError={ inputErrors.pushSubject.hasError }
                    helpMessage={ pushFormField.subject.helpMessage }
                    margin={ { top: 16 } }
                    onBlur={ () => validateTextField(pushSubject, 'pushSubject', setInputErrors) }
                    onChange={ handleChange }
                    disabled={ isInProd }
                    value={ pushSubject }
                />
                <Fieldset
                    legend= { pushFormField.content.legend }
                    smallLegend
                    margin={ {top: 16} }
                    errorMessage={ inputErrors.pushContent.errorMessage }
                    helpMessage={ pushFormField.content.helpMessage }
                    hasError={ inputErrors.pushContent.hasError }
                >
                    <Textarea
                        label={ pushFormField.content.label }
                        name={ 'pushContent' }
                        data-testid='pushTextArea'
                        hasError={ inputErrors.pushContent.hasError }
                        rows={ 'medium' }
                        maxLength={ 240 }
                        onChange={ handleChange }
                        onBlur={ () => validateTextField(pushContent, 'pushContent', setInputErrors) }
                        disabled={ isInProd }
                        value={ pushContent }
                    />
                </Fieldset>
                <div style={
                    {
                        display: 'flex',
                        flexWrap: 'nowrap',
                        justifyContent: 'start',
                        flexDirection: 'column',
                        rowGap: '32px',
                        marginTop: '32px'
                    }
                }>
                    <div style={
                        {
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            columnGap: '25px'
                        }
                    }>
                        <Button
                            data-testid='previewPushDesignButton'
                            variant='secondary'
                            onClick={ openPreview }
                        >
                            Preview Push
                        </Button>
                    </div>
                </div>
            </Col>
        </Row>
    </>);
};

export default PushContentFormComponent;