import styled from 'styled-components';
export const IphonePreview = styled.div`
  display: flex;
  height: 624px;
  padding: 0 610px 1px 0;
  align-items: center;
  align-self: stretch;
`;
export const IphoneScreen = styled.div`
  width: 320px;
  height: 576px;
  flex-shrink: 0;
  background: url('/assets/images/push-notification-phone-background.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  box-shadow: 0 12px 16px 0 rgb(0 0 0 / 14%);
  position: relative;
  border: solid 1px #DEE3E5
`;
export const PushNotificationContainer = styled.div`
  position: relative;
  top: 190px;
  left: 9px;
  display: flex;
  width: 300px;
  padding: 12px 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 14px;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(150px);
`;
export const NotificationContentContainer = styled.div`
  display: flex;
  padding: 0 10px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  width: 100%;
`;
export const PushTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  width: 87%;
  overflow-wrap: break-word;
  p {
    margin: 0;
    white-space: pre-line;
  }
`;
export const PushIcon = styled.img`
    grid-row: 1 / 3;
    max-width: 24px;
    width: auto;
`;
export const PushTitle = styled.p`
  align-self: stretch;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 18.2px */
  letter-spacing: -0.14px;
  max-width: 85%;
`;
export const PushContent = styled.p`
  align-self: stretch;
  color: rgba(0, 0, 0, 0.70);
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
  letter-spacing: -0.28px;
  overflow-wrap: break-word;
`;
export const PushTime = styled.p`
  position: absolute;
  right: 10.475px;
  top: 10px;
  color: rgba(0, 0, 0, 0.38);
  text-align: right;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.11px;
  text-transform: lowercase;
`;