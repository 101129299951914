import React from 'react';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import PropTypes from 'prop-types';

const SwaggerUIComponent = ({
    swaggerSpec
}) => {

    const customSwaggerUIStyle = {
        '.topbar': {
            backgroundColor: 'blue',
        },
        '.info': {
            padding: '20px',
        },
        '.parameters': {
            border: '1px solid #ccc',
        },
    };
    if (!swaggerSpec) {
        return <div>Loading...</div>;
    }

    return (
        <div style={ { width: '100%', backgroundColor: 'white'} }>
            <SwaggerUI
                spec={ swaggerSpec }
                tryItOutEnabled={ false }
                customSwaggerUIStyle={ customSwaggerUIStyle }
                docExpansion={ 'list' }
                defaultModelsExpandDepth={ 0 } />
        </div>
    );
};

SwaggerUIComponent.propTypes = {
    tag: PropTypes.string
};
export default SwaggerUIComponent;