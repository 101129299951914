import React from 'react';
import Heading from '@snsw/react-component-library/build/Components/Headings/Heading';
import { SubjectComponent } from './SubjectComponent';
import PropTypes from 'prop-types';
import { IconNotifications } from '@snsw/react-component-library/build/Icons/system';
import {NotificationComponentSubHeader} from '../SendNotificationComponent/SendNotificationComponent.styled';
import { MoreInfoModal } from '@snsw/react-component-library';

export const InboxContentComponent = ({webInboxCallback, inputErrors, value}) => {
    return <div>
        <NotificationComponentSubHeader>
            <IconNotifications/>
            <Heading level={ 4 } style={ {display: 'inline', paddingLeft: '8px', paddingRight: '8px'} } >
                Digital Notification Inbox
            </Heading>
            <MoreInfoModal
                helpText='Inbox notifications can be accessed via the MyAccount website and SNSW Mobile App.
                The Notification Title is used as the bolded title for the notification when it is opened in both the app and the website.
                The App Title is used as the bolded title for the notification when it is viewed in the notification list page of the
                Mobile App. The Notification Title is used as the bolded title for the notification when it is viewed in the notification
                list page of the Website'
                linkText='More Info'
                questionIconFlag
                subTitle='What you need to know'
                title='Inbox Notifications'
            />
        </NotificationComponentSubHeader>
        <SubjectComponent
            name='content'
            id='inbox-content'
            dataTest= 'inbox-content'
            value={ value.content }
            label='Notification Title'
            helpMessage='The title when viewing notification details in web and app'
            onChange={ webInboxCallback }
            inputError={ inputErrors.inboxContent }
        />
        <SubjectComponent
            name='title'
            id='inbox-title'
            dataTest= 'inbox-title'
            value={ value.title }
            label='App Title'
            helpMessage='The title on the app notification list screen'
            onChange={ webInboxCallback }
            inputError={ inputErrors.inboxTitle }
        />
    </div>;
};
InboxContentComponent.propTypes = {
    onChange: PropTypes.func
};
