import React from 'react';
import {
    ComponentWrapper,
    Heading,
    HeadingWrapper,
    CardWrapper,
    IconWrapper,
    CustomCard, CardParagraph,
} from './ProductsAndServiceComponent.styled';
import { CardTitle } from '@snsw/react-component-library/build/Components';
import {BrandIconMobile, BrandIconReporting, BrandIconFines} from '@snsw/react-component-library';

const ProductsAndServiceComponent = () => {
    return (
        <ComponentWrapper>
            <HeadingWrapper><Heading>Products and services</Heading></HeadingWrapper>
            <CardWrapper>
                <CustomCard>
                    <IconWrapper><BrandIconMobile size='xxl' /></IconWrapper>
                    <CardTitle>One-off updates and reminders</CardTitle>
                    <div>
                        <CardParagraph>
                            Contact customers based on actions they take, or time-based triggers,
                            such as digital vehicle registration reminders
                        </CardParagraph>
                    </div>
                </CustomCard>
            </CardWrapper>
            <CardWrapper>
                <CustomCard>
                    <IconWrapper><BrandIconReporting size='xxl'/></IconWrapper>
                    <CardTitle>Campaigns and group notifications</CardTitle>
                    <div>
                        <CardParagraph>
                            Contact many customers with the same message to drive action or keep them informed,
                            like a voucher launch
                        </CardParagraph>
                    </div>
                </CustomCard>
            </CardWrapper>
            <CardWrapper>
                <CustomCard>
                    <IconWrapper><BrandIconFines size='xxl'/></IconWrapper>
                    <CardTitle>Learn about pricing</CardTitle>
                    <div>
                        <CardParagraph>
                            Once you fill out the form, our team can talk you through
                            pricing options and packages to make sure they meet your needs.
                        </CardParagraph>
                    </div>
                </CustomCard>
            </CardWrapper>
        </ComponentWrapper>
    );
};

export default ProductsAndServiceComponent;
