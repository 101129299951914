import styled from 'styled-components';
import {colors} from '../CommonComponents/Typography/Typography.styled';
import {TertiaryButton} from '../CommonComponents/CustomComponents/CustomComponents.styled';

export const CampaignComponentHeader = styled.div`height: fit-content`;

export const AudienceContainer = styled.div`
  margin-top: 1rem;
  border-radius: 8px;
  border: 1px solid ${colors.grey.geyser};
  width: fit-content;
  background-color: white;
  width: 660px;
  padding: 0 1.25rem 1.25rem 1.25rem;
`;

export const CampaignDescription = styled.div`
  width: 1170px;
  height: fit-content;
  color: rgb(100, 105, 116);
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 12px;
`;

export const AddSegmentBox = styled.div`
    height: fit-content;
    display: flex;
    align-items: flex-end; 
    justify-content: flex-end;
    flex-direction: column;
    padding: 1rem 0px 0.5rem 0px;
    gap: 10px;
    border-radius: 4px;
    background-color: ${colors.grey.iceBlue};
    margin-top: 1rem;
`;

export const AddSegmentContainer = styled.div`
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 0;
`;

export const AddSegmentButton = styled(TertiaryButton)`
    margin-top: 0.875rem;
`;

export const RemoveSegmentButton = styled(TertiaryButton)``;