import React, {useEffect, useState} from 'react';
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom';
import {Button, Col, ComponentLoader, Heading, InPageAlert, Row} from '@snsw/react-component-library';
import {IconAdd} from '@snsw/react-component-library/build/Icons/system';
import apiServices from '../../services/api-services';
import ServiceListItem from '../ServiceListItem/ServiceListItem';
import {scrollToTop, useGlobalState, useUserRoleState} from '../GlobalStateComponent/GlobalState';
import {alertTimeoutValues} from '../../constants/applicationConstants';
import {AutoSuggest} from '@snsw/react-component-library/build/Components';
import {CustomTableContainer} from '../CommonComponents/CustomComponents/CustomComponents.styled';

const ServiceListComponent = () => {
    const {state} = useLocation();
    const {agencyCode} = useParams();
    const [agencyName, setAgencyName] = useState('');
    const [agency, setAgency] = useState(null);
    const [services, setServices] = useState([]);
    const [notificationsServiceMap, setNotificationsServiceMap] = useState({});
    const [loading, setLoading] = useState(true);
    const [expandedAccordionServices, setExpandedAccordionServices] = useState({});
    const [refreshAllServices, setRefreshAllServices] = useState(true);
    const [fetchNotificationsMap, setFetchNotificationsMap] = useState(true);
    const [showAlert, setShowAlert] = useState(true);
    const [showSyncAlerts, setShowSyncAlerts] = useState(false);
    const [alert, setAlert] = useState({
        variant: '',
        title: '',
        description: '',
        visible: false
    });
    const [inProd] = useGlobalState();
    const {userRole} = useUserRoleState();
    const navigate = useNavigate();
    const [filter, setFilter] = useState('');
    const fromLandingPage = state && state.fromLandingPage;

    useEffect(() => {
        scrollToTop();
        apiServices.searchAgencyCode(agencyCode, inProd)
            .then(agency => {
                const services = agency.services;
                delete agency.services;
                setServices(orderServicesAlphabetically(services));
                setAgency(agency);
                setAgencyName(agency.description);
                setLoading(false);
            })
            .catch((error) => {
                // redirect to home
                if (inProd) {
                    navigate('/agencies', {
                        state: {
                            variant: 'warning',
                            title: 'No Agency Found',
                            description: `Agency ${agencyCode} does not exists in Prod`,
                            visible: true

                        }
                    });
                } else {
                    navigate('/agencies', {
                        state: {
                            variant: 'error',
                            title: 'Network Error',
                            description: error.message,
                            visible: true

                        }
                    });
                }
            });
        if (state !== null && state.isExpanded !== null && state.serviceCode !== null) {
            updateExpandedAccordionState(state.serviceCode, true);
        }
        setFetchNotificationsMap(true);// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [agencyCode, inProd, refreshAllServices]);

    useEffect(() => {
        if (state && state.alert && state.alert.visible) {
            setAlert(state.alert);
            //Set the showAlert to true so that it will be displayed
            setShowAlert(true);
            const timer = setTimeout(() => {
                setShowAlert(false);
                if (state && state.alert) {
                    state.alert.visible = false;
                }
            }, alertTimeoutValues[state.alert.variant]);
            return () => {
                clearTimeout(timer);
            };
        } // eslint-disable-next-line react-hooks/exhaustive-deps
        if (state && state.syncToLowerStatus && state.syncToLowerStatus.visible) {
            //Set the showAlert to true so that it will be displayed
            setShowSyncAlerts(true);
            const timer = setTimeout(() => {
                setShowSyncAlerts(false);
                if (state && state.syncToLowerStatus) {
                    state.syncToLowerStatus.visible = false;
                }
            }, alertTimeoutValues['error']);
            return () => {
                clearTimeout(timer);
            };
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    const orderServicesAlphabetically = (services) => {
        services.sort(function (a, b) {
            if (a.description < b.description) {
                return -1;
            }
            if (a.description > b.description) {
                return 1;
            }
            return 0;
        });
        return services;
    };
    const updateExpandedAccordionState = (serviceCode, updatedExpandedAccordionState) => {
        const updatedAccordions = {...expandedAccordionServices};
        updatedAccordions[serviceCode] = updatedExpandedAccordionState;
        setExpandedAccordionServices(updatedAccordions);
    };

    const onFilter = (suggestion, value) => {
        if (value !== '' && suggestion) {
            setFilter(notificationsServiceMap[suggestion]);
        } else {
            setFilter('');
        }
    };

    const filterCustomSuggestions = (value) => {
        return Object.keys(notificationsServiceMap).filter(notification => (
            notification
                .toLowerCase()
                .trim()
                .includes(value.trim().toLowerCase())
        ));
    };

    const onSearchBarChange = () => {
        if (fetchNotificationsMap) {
            // const environment = inProd ?
            apiServices.getNotificationsServiceMap(agencyCode, inProd ? 'DST' : 'SRC').then(map => {
                setNotificationsServiceMap(map);
            })
                .catch(error => console.log(error.message));
            setFetchNotificationsMap(false);
        }
    };

    return (
        state &&
        <CustomTableContainer>
            <div data-testid='ServiceListHeaderContainer' style={ {marginBottom: '2rem'} }>
                {!fromLandingPage ? <Button variant='back' as={ Link } href='/agencies'>Back</Button> :
                    <Button variant='back' as={ Link } href='/'>Back</Button>}
                <Heading
                    className='page-title'
                    style={ {height: 'fit-content', margin: '1.125rem 0 0 0', wordBreak: 'break-word'} }
                    level={ 2 }
                >{agencyName} Notifications</Heading>
                <div style={ {
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: '3.5rem'
                } }>
                    {agency !== null &&
                        <>
                            <div style={ {
                                width: '30vw',
                                maxWidth: '400px',
                                minWidth: '200px'
                            } }>
                                <AutoSuggest
                                    id='notificationCodeSearch'
                                    name='notificationCodeSearch'
                                    suggestions={ Object.keys(notificationsServiceMap) }
                                    onSelect={ onFilter }
                                    onChange={ onSearchBarChange }
                                    onBlur={ onFilter }
                                    // minSearchChars={ 3 }
                                    inputWidth={ 'xl' }
                                    placeholder={ 'Search for a notification code' }
                                    filterCustomSuggestions={ filterCustomSuggestions }
                                />
                            </div>
                            {!inProd && userRole?.permissions?.SERVICE?.includes('CREATE') &&
                                <Button as={ Link }
                                    variant='secondary'
                                    href='/add-service'
                                    state={ {agency: Object.assign(agency)} }
                                    style={ {
                                        maxHeight: '48px',
                                        textDecoration: 'none',
                                        alignItems: 'center',
                                        display: 'flex'
                                    } }
                                >
                                    Add new service
                                    <IconAdd color='secondaryBlue'
                                        style={ {marginLeft: '9px', width: '14px', height: '14px'} }/>
                                </Button>
                            }
                        </>
                    }
                </div>
            </div>
            {loading && <ComponentLoader/>}
            {!loading && services && services.length === 0 &&
                <InPageAlert variant='warning' title='No services have been onboarded'>
                    <p>Click 'Add Service' to create a new service.</p>
                </InPageAlert>}
            {(alert.visible && showAlert) &&
                <InPageAlert id='service-status' variant={ alert.variant } title={ alert.title }>
                    <p data-test='serviceStatusDesc'>{alert.description}</p>
                </InPageAlert>}
            {
                (state.syncToLowerStatus && showSyncAlerts) &&
                state.syncToLowerStatus.alerts.map((row, index) => (
                    <Row key={ index }>
                        <Col span={ 9 }>
                            <InPageAlert id={ `notification-status-${index}` } variant={ row.variant } title={ row.title }>
                                <p data-test={ `notStatusDesc-${index}` }>{row.description}</p>
                            </InPageAlert>
                        </Col>
                    </Row>
                ))}
            {agency && services && services.map((service, index) => {
                let match = true;
                if (filter.length > 0) {
                    match = service.serviceCode === filter;
                }
                if (match) {
                    return (<ServiceListItem
                        serviceCode={ service.serviceCode }
                        serviceTitle={ service.description }
                        status={ service.status }
                        isExpanded={ (expandedAccordionServices[service.serviceCode] || filter.length > 0) ?? false }
                        refreshAllServices={ setRefreshAllServices }
                        updateExpandedAccordionState={ updateExpandedAccordionState }
                        key={ service.serviceCode }
                        agency={ agency }
                    />);
                } else {
                    return (<></>);
                }
            })}
        </CustomTableContainer>
    );
};

export default ServiceListComponent;
