import Row from '@snsw/react-component-library/build/layout/Grid/Row.styled';
import Col from '@snsw/react-component-library/build/layout/Grid/Col.styled';
import {Textarea} from '@snsw/react-component-library';
import Heading from '@snsw/react-component-library/build/Components/Headings/Heading';
import FormInput from '@snsw/react-component-library/build/Components/Form/FormInput';
import FormSelect from '@snsw/react-component-library/build/Components/Form/FormSelect';
import Fieldset from '@snsw/react-component-library/build/Components/Form/Fieldset';
import Button from '@snsw/react-component-library/build/Components/Button/Button';
import React from 'react';
import {
    emailFormField,
    validateEmailSourceApplicationTUTD, validateTextField
} from '../CreateNotificationComponent/CreateNotificationComponent';
import DOMPurify from 'dompurify';

const EmailContentFormComponent = ({
    templateOptions,
    notificationConfig,
    setNotificationConfig,
    handleChange,
    setShowEmailPreviewModal,
    emailContentValidated,
    inputErrors,
    setInputErrors,
    inProd
}) => {
    const isProd = (inProd != null) ? inProd : false;
    const validateContent = () => {
        emailContentValidated.current = true;
        setInputErrors(prevState => ({
            ...prevState,
            emailContent: {hasError: false, errorMessage: 'Error'},
        }));
        const validContent = DOMPurify.sanitize(notificationConfig.emailContent);
        setNotificationConfig(prevState => ({
            ...prevState,
            emailContent: validContent
        }));
    };
    const emailSourceApplicationTUTDChangeHandle = async () => {
        return validateEmailSourceApplicationTUTD(notificationConfig.emailSourceApplicationTUTD,
            notificationConfig.sendEmailEnabled,
            notificationConfig.emailFeedbackTemplate,
            templateOptions,
            setInputErrors);
    };
    const validatePreviewContents = async () => {
        let isValid = true;
        if (notificationConfig.emailSubject.length === 0) {
            setInputErrors(prevState => ({
                ...prevState,
                emailSubject: {
                    hasError: true,
                    errorMessage: 'Provide the email subject to preview or create notification'
                }
            }));
            isValid = false;
        }
        if (notificationConfig.emailFooterTemplate.length === 0) {
            setInputErrors(prevState => ({
                ...prevState,
                emailFooterTemplate: {
                    hasError: true,
                    errorMessage: 'Select an email footer to preview or create notification'
                }
            }));
            isValid = false;
        }
        if (notificationConfig.emailHeaderTemplate.length === 0) {
            setInputErrors(prevState => ({
                ...prevState,
                emailHeaderTemplate: {
                    hasError: true,
                    errorMessage: 'Select an email header to preview or create notification'
                }
            }));
            isValid = false;
        }
        if (notificationConfig.emailContent.length === 0) {
            setInputErrors(prevState => ({
                ...prevState,
                emailContent: {
                    hasError: true,
                    errorMessage: 'Enter email body content in html format'
                }
            }));
            isValid = false;
        }
        return isValid;
    };
    const openPreview = async () => {
        const previewIsValid = await validatePreviewContents();
        if (previewIsValid) {
            setShowEmailPreviewModal(true);
        } else {
            const errorElement = document.querySelectorAll('span[class^=\'FormError\']')[0]?.previousSibling;
            errorElement?.scrollIntoView({behavior: 'smooth'});
        }
    };
    return (
        <>
            <Row style={ {paddingBottom:'4px'} }>
                <Col span={ 9 }>
                    <Heading level={ 4 } style={ {height: 'fit-content', margin: '24px 0 0 0'} }>
                        {emailFormField.heading}
                    </Heading>
                    <FormInput
                        label={ emailFormField.subject.label }
                        name={ emailFormField.subject.name }
                        placeholder={ emailFormField.subject.placeholder }
                        errorMessage={ inputErrors.emailSubject.errorMessage }
                        hasError={ inputErrors.emailSubject.hasError }
                        helpMessage={ emailFormField.subject.helpMessage }
                        margin={ { top: 16 } }
                        onBlur={ () => validateTextField(notificationConfig.emailSubject, 'emailSubject', setInputErrors) }
                        onChange={ handleChange }
                        disabled={ isProd }
                        value={ notificationConfig.emailSubject }
                    />
                    <FormSelect
                        title={ 'emailHeader' }
                        label={ emailFormField.header.label }
                        name={ 'emailHeaderTemplate' }
                        helpMessage={ emailFormField.header.helpMessage }
                        errorMessage={ inputErrors.emailHeaderTemplate.errorMessage }
                        hasError={ inputErrors.emailHeaderTemplate.hasError }
                        options={ templateOptions.headers }
                        margin={ {top: 16} }
                        onChange={ handleChange }
                        disabled={ isProd }
                        value={ notificationConfig.emailHeaderTemplate }
                    />
                    <Fieldset
                        legend= { emailFormField.content.legend }
                        smallLegend
                        margin={ {top: 16} }
                        errorMessage={ inputErrors.emailContent.errorMessage }
                        helpMessage={ emailFormField.content.helpMessage }
                        hasError={ inputErrors.emailContent.hasError }
                    >
                        <Textarea
                            label={ emailFormField.content.label }
                            name={ 'emailContent' }
                            placeholder={ emailFormField.content.placeholder }
                            data-testid='emailTextArea'
                            hasError={ inputErrors.emailContent.hasError }
                            rows={ 'medium' }
                            onChange={ handleChange }
                            disabled={ isProd }
                            value={ notificationConfig.emailContent }
                        />
                    </Fieldset>
                    <Button
                        onClick={ validateContent }
                        name={ 'emailContent' }
                        data-testid={ 'emailHTMLValidation' }
                        variant='secondary'
                        id='emailSanitizerBtn'
                        style={ {margin: '10px 0 0 0'} }>
                    Check HTML
                    </Button>
                    <FormSelect
                        title={ 'emailFooter' }
                        label={ emailFormField.footer.label }
                        name={ 'emailFooterTemplate' }
                        placeholder= { emailFormField.footer.placeholder }
                        errorMessage={ inputErrors.emailFooterTemplate.errorMessage }
                        hasError={ inputErrors.emailFooterTemplate.hasError }
                        options={ templateOptions.footers }
                        margin={ {top: 16} }
                        onChange={ handleChange }
                        disabled={ isProd }
                        value={ notificationConfig.emailFooterTemplate }
                    />
                    <FormSelect
                        title={ 'emailFeedback' }
                        label={ emailFormField.feedback.label }
                        name={ 'emailFeedbackTemplate' }
                        data-testid={ 'emailFeedbackTemplate' }
                        placeholder= { emailFormField.feedback.placeholder }
                        helpMessage= { emailFormField.feedback.helpMessage }
                        isOptional
                        options={ templateOptions.feedbacks }
                        margin={ {top: 16} }
                        onChange={ handleChange }
                        disabled={ isProd }
                        value={ notificationConfig.emailFeedbackTemplate }
                    />
                    {(notificationConfig.emailFeedbackTemplate === templateOptions.feedbacks
                        .find(feedback => feedback.text === 'Feedback-TUTD')?.value) &&
                    <FormInput
                        label={ emailFormField.emailSourceApplication.label }
                        name='emailSourceApplicationTUTD'
                        data-testid={ 'emailSourceApplicationTUTD' }
                        hasError={ inputErrors.emailSourceApplicationTUTD.hasError }
                        errorMessage={ inputErrors.emailSourceApplicationTUTD.errorMessage }
                        margin={ {top: 16} }
                        onBlur={ emailSourceApplicationTUTDChangeHandle }
                        onChange={ handleChange }
                        value={ notificationConfig.emailSourceApplicationTUTD }
                        disabled={ isProd }
                    />
                    }
                    <div style={
                        {
                            display: 'flex',
                            flexWrap: 'nowrap',
                            justifyContent: 'start',
                            rowGap: '32px',
                            marginTop: '32px'
                        }
                    }>
                        <div style={
                            {
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                columnGap: '25px'
                            }
                        }>
                            <Button
                                data-testid='previewEmailDesignButton'
                                variant='secondary'
                                onClick={ openPreview }
                            >
                            Preview email
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default EmailContentFormComponent;