import {ContentContainer, Heading} from '@snsw/react-component-library';
import styled, {createGlobalStyle} from 'styled-components';
import FormInput from '@snsw/react-component-library/build/Components/Form/FormInput';
import FormSelect from '@snsw/react-component-library/build/Components/Form/FormSelect';
import {baseSearchInput, defaultSearchInputWidth} from '../SearchBar/SearchBar.styled';
import {colors, fontSize, fontWeight, globalFont} from '../Typography/Typography.styled';
import {Button} from '@snsw-gel/react';
import {Button as Gel2Button} from '@snsw/react-component-library';

export const CustomTableContainer = styled(ContentContainer)`
    padding-bottom: 0.5em;

    > div {
        margin-left: 0;
        max-width: unset;
    }
`;
export const PortalFormContainer = styled(ContentContainer)`
    > div {
        margin-left: 0;
    }
`;

export const BackButtonContainer = styled.div`
    margin-top: -32px;

    > a {
        font-family: ${globalFont};
        font-size: 12px;
        line-height: 10px;
        --gap: 7px;
    }

    .iconStart > svg {
        width: 9px;
        height: 9px;
    }
`;

export const PortalH1Heading = styled(Heading)`
    height: fit-content;
    margin: 18px 0 0 0;
    font-family: ${globalFont};
    font-size: 24px;
    line-height: 2.25rem;
    color: ${colors.brand.snswPrimaryBlue};
`;

export const PortalH5Heading = styled(Heading)`
    font-family: ${globalFont};
    font-size: 18px;
    font-weight: 700;
    line-height: 1.5rem;
    color: ${colors.brand.snswPrimaryBlue};
`;

export const PortalFormInput = styled(FormInput)`
    margin-top: 16px;
    min-width: 250px;
    max-width: 528px;
    font-family: ${globalFont};
    font-size: 14px;
    font-feature-settings: "clig" off, "liga" off;

    > label {
        font-weight: 500;
        font-feature-settings: "clig" off, "liga" off;
    }

    > input {
        padding: 12px 16px;
        height: 42px;
        font-family: ${globalFont};
        font-size: 14px;
        border: 1px solid ${colors.grey.geyser};
    }
`;

export const PortalFormSelect = styled(FormSelect)`
    margin-top: 16px;
    width: 528px;
    font-family: ${globalFont};
    font-size: 14px;
    font-feature-settings: "clig" off, "liga" off;

    > label {
        font-weight: 500;
        font-feature-settings: "clig" off, "liga" off;
        display: ${(props) => (props.hideLabel ? 'none' : 'block')};
    }

    > select {
        width: 528px;
        height: 42px;
        font-family: ${globalFont};
        font-size: 14px;
        border: 1px solid ${colors.grey.geyser};
        color: #808080;
        background-image: url("/assets/icons/SelectChevronDown.svg");
        background-position: center right 8px;
    }
`;

export const AutosuggestGlobalStyle = createGlobalStyle`
    #notification-code-input {
        ${baseSearchInput};

        &:focus {
            border: 1.5px solid ${colors.brand.snswPrimaryBlue};
        }

        border-radius: 8px;
        margin-top: 0.5rem;
        width: ${props => (props.width ? `${props.width}px` : defaultSearchInputWidth)};
        background: url('/assets/images/search-icon.svg') white no-repeat right 10px center;
    }

    .react-autosuggest__suggestions-container {
        width: ${props => (props.width ? `${props.width}px` : defaultSearchInputWidth)} !important;
        border: 1px solid ${colors.grey.geyser} !important;
        font-size: 14px;
        font-family: ${globalFont};
        margin-top: 2px !important;
        box-shadow: 0px 4px 10px 0px #959DA51A !important;
    }

    .react-autosuggest__suggestion {
        background-color: #fff !important;
    }

    .react-autosuggest__suggestion--highlighted {
        color: ${colors.black} !important;
        background-color: ${colors.grey.iceBlue} !important;
    }
`;

export const RadioButtonError = styled.div`

    /* Para - Extra Small */
    font-family: 'Public Sans', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height, or 150% */

  color: ${colors.status.errorRed.default};

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 1;

    /* Display error message
     */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 0px;
    gap: 6px;

    width: 335px;
    height: 28px;

`;

export const SmallButton = styled(Button)`
    color: ${colors.brand.snswPrimaryBlue} !important;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;

export const TertiaryButton = styled(SmallButton)`
    font-weight: ${fontWeight.semibold};
    font-size: ${fontSize.body};
`;

export const SecondaryButton = styled(Gel2Button)`
    height: 42px;
    padding: 8px 16px 8px 16px;
    text-decoration: auto;
    font-family: ${globalFont};
    border-radius: 4px;
    border-color: ${colors.brand.snswPrimaryBlue};
    color: ${colors.brand.snswPrimaryBlue};

    &:hover {
        color: white;
        background-color: ${colors.brand.snswPrimaryBlue};
    }
`;