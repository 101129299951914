/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {trackMe} from '@snsw/react-component-library/build/Components/ComponentAnalytics/componentAnalytics';
import {StyledTableContainer} from '@snsw/react-component-library/build/Components/Table/Table.styled';
import {Heading} from '@snsw/react-component-library';

export const TableContainer = ({ className, title, description, children, id }) => {
    useEffect(() => {
        trackMe('Table [GEL]');
    }, []);

    return (
        <StyledTableContainer className={ className } data-testid='table-container'>
            {title && <Heading level={ 3 } id={ id }>{title}</Heading>}
            {description && <p>{description}</p>}
            <div
                role='region'
                aria-labelledby={ id }
                tabIndex='0'
            >
                {children}
            </div>
        </StyledTableContainer>
    );
};

TableContainer.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    children: PropTypes.node.isRequired,
};