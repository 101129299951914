import React from 'react';
import {ContentContainer} from '@snsw/react-component-library';
import InPageAlert from '@snsw/react-component-library/build/Components/InPageAlert/InPageAlert';
import {PortalInfo} from '../LandingPage/LandingPage';
import {useLocation} from 'react-router-dom';

const OutageBannerComponent = () => {
    const { state } = useLocation();

    return (
        <ContentContainer role='main'>
            <PortalInfo/>
            {state && state.visible ?
                <>
                    <div style={ { marginTop: '2rem'} }>
                        <InPageAlert data-testid='access-status-warning'
                            id='outage-alert-title' variant={ state.variant } title={ state.title }>
                            <p id='outage-alert-description'>{ state.description }</p>
                        </InPageAlert>
                    </div>
                </>
                : null}
        </ContentContainer>
    );
};

export default OutageBannerComponent;
