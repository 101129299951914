import styled from 'styled-components';
import {
    colors, fontSize,
    fontWeight,
    globalFont,
    Heading1, lineHeight,
    screenSize
} from '../../CommonComponents/Typography/Typography.styled';

const adjustableSize = screenSize.tablet;

export const ComponentWrapper = styled.div`
    display: flex;
    font-family: ${globalFont};
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 0 0 58.33%;
    justify-content: center;
    padding-right: 24px;

    @media only screen and (max-width: ${adjustableSize}) {
        flex: 0 0 100%;
        padding: 56px 0px;
        gap: 24px;
    }
`;

export const Heading = styled(Heading1)`
    font-size: 48px;
    line-height: 60px;
    color: ${colors.grey.darkGrey};

    @media only screen and (max-width: ${adjustableSize}) {
        font-size: 28px;
        line-height: 36px;
    }
`;

export const Body = styled.div`
    font-weight: ${fontWeight.regular};
    font-size: ${fontSize.h6};
    line-height: ${lineHeight.h6};
    > p {
        margin: 0px;
        + p {
            margin-top: 16px;
        }
    }
`;

export const SpanBold = styled.span`
    font-weight: ${fontWeight.bold};
    font-size: ${fontSize.h6};
    line-height: ${lineHeight.h6};
`;

export const SpanNormal = styled.span`
    font-size: ${fontSize.h6};
    line-height: ${lineHeight.h6};
`;

export const ImgWrapper = styled.div`
    flex: 0 0 41.67%;
    justify-content: center;
    @media only screen and (max-width: ${adjustableSize}) {
        display: none;
    }
`;

export const Img = styled.img`
    width: 100%;
    height: 100%;
    objectFit: cover;
`;