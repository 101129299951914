import ReactGA from 'react-ga';

const initializeGa = () => {
    //console.log('Intialize GA');
    ReactGA.initialize(
        'UA-129074441-2'
    );
    ReactGA.ga('set', 'page', '/portal');
    ReactGA.ga('send', 'pageview', '/portal');
};

const sendEvent = (event) => {
    //event.preventDefault();
    sendGaEvent(
        event.target.getAttribute('ga_category'),
        event.type,
        event.target.getAttribute('ga_label')
    );
};

const sendGaEvent = (attribute, action, label) => {
    //console.log('GA event attribue --- action--- label' + attribute + action + label);
    ReactGA.event({
        category: attribute,
        action: action,
        label: label
    });
};

//export default initializeGa;
export {sendEvent, sendGaEvent,initializeGa};