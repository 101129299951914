import React from 'react';

const imagePath = process.env.PUBLIC_URL + '/assets/images/';

const BlockerAlertToolTip = () => {

    return (<div>
        <p>The Blocker alert will appear under the service when it does not meet the associated prerequisite check.
            The alert will appear as an amber warning colour.
        </p>
        <span style={ { fontWeight: 500 } }>
            Alert Title
        </span>
        <p>The title of the alert is the bold text displayed at the top of the alert as shown by the red highlight in the image below.</p>
        <img
            src={ `${imagePath}blockerAlertTitle.png` }
            alt='A blocker alert with the title section highlighted'
            style={ { width: 'calc(50vw - 4rem)' } }
        />
        <span style={ { fontWeight: 500, paddingTop: '1rem', display: 'block' } }>
            Alert Description
        </span>
        <p>The description of the alert is the text displayed at the bottom of the alert. This field accepts HTML content so that you can
            achieve bold text or add links.
        </p>
        <img
            src={ `${imagePath}blockerAlertDescription.png` }
            alt='A blocker alert with the description section highlighted'
            style={ { width: 'calc(50vw - 4rem)' } }
        />
    </div>);
};

export default BlockerAlertToolTip;
