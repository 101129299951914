import React from 'react';
import {preferenceAlertTypes} from '../../constants/applicationConstants';
import {Textarea, Fieldset, FormInput, FormGroup, MoreInfoModal} from '@snsw/react-component-library';
import ByOptingInToolTip from './ByOptingInToolTip';
import ByOptingOutToolTip from './ByOptingOutToolTip';
import BlockerAlertToolTip from './BlockerAlertToolTip';
import LinkActionToolTip from './LinkActionToolTip';

const PreferenceAlertComponent = (props) => {

    const moreInfoDescriptions = {
        OPT_IN: <ByOptingInToolTip/>,
        OPT_OUT: <ByOptingOutToolTip/>,
        INELIGIBLE: <BlockerAlertToolTip/>,
        DUPLICATE_ACCOUNT: <BlockerAlertToolTip/>,
        USER_ACTION: <LinkActionToolTip/>,
    };

    return (
        <Fieldset
            legend={ props.legend }
            helpMessage={ props.helpMessage }
            smallLegend
            margin={ { top: 'xl' } }
        >
            <MoreInfoModal
                helpText={ moreInfoDescriptions[props.type] }
                subTitle='What you need to know'
                title={ props.legend }
                linkText='More Info'
                questionHyperLink
            />
            <FormInput
                id={ `${props.type}-title-input` }
                name='title'
                label='Title'
                onChange={ (event) => props.callback(event, props.type) }
                disabled={ props.disabled }
                value={ props.alertValues?.title ? props.alertValues.title : '' }
                errorMessage={ props.inputErrors.title.errorMessage }
                hasError={ props.inputErrors.title.hasError }
            />
            {props.type !== preferenceAlertTypes.USER_ACTION &&
                <FormGroup
                    label='Description'
                    id={ `${props.type}-description-input` }
                    helpMessage='You can enter html as well as regular text'
                    errorMessage={ props.inputErrors.description.errorMessage }
                    hasError={ props.inputErrors.description.hasError }
                >
                    <Textarea
                        name='description'
                        onChange={ (event) => props.callback(event, props.type) }
                        disabled={ props.disabled }
                        value={ props.alertValues?.description ? props.alertValues.description : '' }
                    />
                </FormGroup>
            }
            {props.type === preferenceAlertTypes.USER_ACTION && <>
                <FormInput
                    id='action_link_text-input'
                    name='actionLinkText'
                    label='Action Link text'
                    onChange={ (event) => props.callback(event, props.type) }
                    disabled={ props.disabled }
                    value={ props.alertValues?.actionLinkText ? props.alertValues.actionLinkText : '' }
                    errorMessage={ props.inputErrors.actionLinkText.errorMessage }
                    hasError={ props.inputErrors.actionLinkText.hasError }
                />
                <FormInput
                    id='action_link_href-input'
                    name='actionLinkHref'
                    label='Action Link href'
                    onChange={ (event) => props.callback(event, props.type) }
                    disabled={ props.disabled }
                    value={ props.alertValues?.actionLinkHref ? props.alertValues.actionLinkHref : '' }
                    errorMessage={ props.inputErrors.actionLinkHref.errorMessage }
                    hasError={ props.inputErrors.actionLinkHref.hasError }
                />
            </>}
        </Fieldset>
    );
};

export default PreferenceAlertComponent;
