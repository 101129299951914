import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import apiServices from '../../services/api-services';
import {Button, Modal, StatusLabel, TableCell, TableRow} from '@snsw/react-component-library';
import {useBoolean} from '@snsw/react-component-library/build/utils';
import {Card} from '@snsw/react-component-library/build/Components';
import {SimpleTreeView} from '@mui/x-tree-view/SimpleTreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {TreeItem} from '@mui/x-tree-view/TreeItem';
import ComponentLoader from '@snsw/react-component-library/build/Loader/ComponentLoader';
import PropTypes from 'prop-types';
import {portalConfigStatus} from '../../constants/applicationConstants';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {colors as $colors} from '../CommonComponents/Typography/Typography.styled';

export const CustomisedEditIcon = () => {
    return <span style={ {marginLeft: '0.25rem', verticalAlign: 'middle'} }>
        <EditOutlinedIcon style={ {width: '1.125rem', height: '1.125rem'} }
            aria-hidden='true' color={ $colors.brand.snswSecondaryBlue }/></span>;
};

const AgencyListItem = props => {
    const [pageLoading, setPageLoading] = useState({status: false, label: ''});
    const [showPromoteToProdModal, openPromoteToProdModal, onPromoteToProdModelClose] = useBoolean(false);
    const [pendingChangesStatus, setPendingChangesStatus] = useState({
        agencyCode: '',
        pendingChanges: false,
        inProd: true,
        servicesExists: false
    });

    const populateAgencyPendingChanges = data => {
        const agencyChangesToBePromoted = {
            id: props.agency.agencyCode,
            name: props.agency.description,
            pendingChanges: data.pendingChanges,
            defaultExpanded: [props.agency.agencyCode],
            inProd: data.inProd
        };
        setPendingChangesStatus(agencyChangesToBePromoted);
    };
    const onPromoteToProdModelOpen = (event) => {
        event.stopPropagation();
        openPromoteToProdModal();
        apiServices.compare(props.agency.agencyCode)
            .then(data => {
                populateAgencyPendingChanges(data);
            })
            .catch(() => {
                props.setAgencyAlert({
                    variant: 'warning',
                    title: 'Network Error',
                    description: 'An Error occurred while trying to compare Non-Prod and prod configs.' +
                        ' No Pending changes are being shown ',
                    visible: true
                });
            });
    };
    const onPromoteToProdConfirmation = (event) => {
        event.stopPropagation();
        onPromoteToProdModelClose();
        setPageLoading({status: true, label: 'Promote to production in progress...'});
        apiServices.promoteAgencyToDestination(props.agency.agencyCode).then(data => {
            setPageLoading({status: false, label: ''});
            props.setAgencyAlert({
                variant: 'success',
                title: 'Done',
                description: 'Your agency has been promoted to production successfully',
                visible: true
            });
            props.refreshAllAgencies(true);
        })
            .catch(e => {
                setPageLoading({status: false, label: ''});
                props.setAgencyAlert({
                    variant: 'error',
                    title: 'Promote service to production error',
                    description: e.message,
                    visible: true
                });
                props.refreshAllAgencies(true);
            });
    };

    const onPromoteToProdModelBackClicked = (event) => {
        event.stopPropagation();
        onPromoteToProdModelClose();
    };

    const promoteToProdModalButtons = [
        {text: 'Confirm', id: 'promoteChangesToProdBtn', onClick: onPromoteToProdConfirmation},
        {text: 'Back', id: 'promoteChangesBackBtn', onClick: onPromoteToProdModelBackClicked}
    ];
    const renderAgencyPendingChangesOnModelWithTreeView = () => {
        return pendingChangesStatus !== undefined ? (
            <SimpleTreeView defaultExpanded={ pendingChangesStatus.defaultExpanded }
                disableSelection={ true } disabled={ true } defaultCollapseIcon={ <ExpandMoreIcon/> }
                onNodeFocus={ (event) => {
                    event.stopPropagation();
                } }
                onClick={ (event) => {
                    event.stopPropagation();
                } }
                style={ {pointerEvents: 'none'} }
            >
                <TreeItem
                    key={ pendingChangesStatus.id }
                    nodeId={ pendingChangesStatus.id }
                    label={ <div style={ {display: 'flex', alignItems: 'center', gap: '1rem', margin: 10} }>
                        Agency: {pendingChangesStatus.name} {pendingChangesStatus.pendingChanges ?
                            <StatusLabel text={ 'PENDING' }
                                variant={ 'warning' }/> : null}
                    </div> }
                />
            </SimpleTreeView>) : null;
    };

    const editButton = () => {
        return <Button variant='link' as={ Link } href={ `/agencies/update-agency/${props.agency.agencyCode}` }>
            Edit agency
            <CustomisedEditIcon/>
        </Button>;
    };

    const viewServicesButton = () => {
        return <Button variant='secondary' as={ Link } href={ `/services/${props.agency.agencyCode}` }
            state={ {agency: Object.assign(props.agency)} }
            style={ {textDecoration: 'none'} }>
            Agency dashboard
        </Button>;
    };

    const promoteButton = () => {
        return <Button
            id={ `promoteAgencyBtn-${props.agency.agencyCode}` }
            data-testid={ `promoteAgencyBtn-${props.agency.agencyCode}` }
            variant='primary'
            as={ Link }
            onClick={ onPromoteToProdModelOpen }
        >
            Push to Prod
        </Button>;
    };

    return (
        <TableRow key={ props.index }>
            {pageLoading.status && <ComponentLoader fullPage label={ pageLoading.label }/>}
            <TableCell key={ props.index + '.1' } style={ {wordBreak: 'break-word'} }>{props.agency.description}</TableCell>
            <TableCell key={ props.index + '.2' }>{props.agency.agencyCode}</TableCell>
            {props.displayAgencyStatus && <TableCell key={ props.index + '.3' }>
                {(props.agency.status === portalConfigStatus.IN_REVIEW) ?
                    <StatusLabel role='status' data-testid={ `${props.agency.agencyCode}-review-status-label` }
                        text='In review' variant='warning'/>
                    : <StatusLabel role='status' data-testid={ `${props.agency.agencyCode}-live-status-label` }
                        text='Live' variant='success'/>}
            </TableCell>}
            <TableCell key={ props.index + '.4' }>
                <div style={ {display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '1rem'} }>
                    {props.displayEditButton && editButton()}
                    {viewServicesButton()}
                    {(props.displayAgencyStatus && props.agency.status === portalConfigStatus.IN_REVIEW) && promoteButton()}
                </div>

                {showPromoteToProdModal && (
                    <Modal
                        title='Promote to production'
                        description='Following are the list of pending changes that will
                        be promoted to production. Please confirm if you want to continue.'
                        buttons={ promoteToProdModalButtons }
                    >
                        <Card style={ {marginBottom: 20} }>
                            <div>
                                {renderAgencyPendingChangesOnModelWithTreeView()}
                            </div>
                        </Card>
                    </Modal>
                )}
            </TableCell>
        </TableRow>
    );
};

AgencyListItem.propTypes = {
    index: PropTypes.number,
    agency: PropTypes.object,
    key: PropTypes.string,
    setAgencyAlert: PropTypes.func,
    refreshAllAgencies: PropTypes.func,
    displayAgencyStatus: PropTypes.bool,
    displayEditButton: PropTypes.bool
};

export default AgencyListItem;
