import styled from 'styled-components';
import {
    colors, fontSize,
    fontWeight,
    globalFont,
    Heading1, lineHeight,
    screenSize
} from '../../CommonComponents/Typography/Typography.styled';
import {Fieldset, FormInput, Button, InPageAlert} from '@snsw/react-component-library';

const adjustableSize = screenSize.tablet;

export const ComponentWrapper = styled.div`
    display: flex;
    font-family: ${globalFont};
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 0 0 58.33%;
    justify-content: center;
    padding-right: 24px;
    padding-top: 48px;
    padding-bottom: 48px;
    

    @media only screen and (max-width: ${adjustableSize}) {
        flex: 0 0 100%;
        padding: 56px 0px;
        gap: 24px;
    }
`;

export const Heading = styled(Heading1)`
    font-size: 48px;
    line-height: 60px;
    color: ${colors.grey.darkGrey};

    @media only screen and (max-width: ${adjustableSize}) {
        font-size: 28px;
        line-height: 36px;
    }
`;

export const Body = styled.div`
    font-weight: ${fontWeight.regular};
    font-size: ${fontSize.h6};
    line-height: ${lineHeight.h6};
    > p {
        margin: 0px;
    }
`;

export const ImgWrapper = styled.div`
    flex: 0 0 41.67%;
    justify-content: center;
    @media only screen and (max-width: ${adjustableSize}) {
        display: none;
    }
`;

export const Img = styled.img`
    width: 100%;
    height: 100%;
    objectFit: cover;
`;

export const InquireFormInput = styled(FormInput)`
    width: 70%;
    margin-top: 8px;
    @media only screen and (max-width: ${adjustableSize}) {
        width: 100%;
    }

`;
export const InquireFieldset = styled(Fieldset)`
    width: 70%;
    margin-top: 8px;
    @media only screen and (max-width: ${adjustableSize}) {
        width: 100%;
    }

`;
export const InquireButton = styled(Button)`
    margin-top: 24px;

`;
export const Alert = styled(InPageAlert)`
    margin: 0
`;

export const P = styled.p`
    
    @media only screen and (max-width: 767px) {
        margin: -24px 0 0 24px!important;
}
    @media (max-width: 820px) and (min-width: 768px) {
        margin: -24px 0 0 0!important;
    }
    @media (min-width: 820px) and (max-width: 900px) {
        margin: -24px 0 0 24px!important;
    }
    @media only screen and (min-width: ${screenSize.desktop}) {
        display: inline-block
    }
`;