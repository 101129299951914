import React from 'react';
import {
    ComponentWrapper,
    Heading,
    HeadingWrapper,
    ElementWrapper,
    IconWrapper,
    CardParagraph,
} from './WorkFlowComponent.styled';
import {BrandIconQuestion, BrandIconTraining, BrandIconDesktop, BrandIconFast} from '@snsw/react-component-library';

const WorkFlowComponent = () => {
    return (
        <ComponentWrapper>
            <HeadingWrapper><Heading>How it works</Heading></HeadingWrapper>
            <ElementWrapper>
                <IconWrapper><BrandIconQuestion size='xxl' /></IconWrapper>
                <div>Plan</div>
                <div>
                    <CardParagraph>
                        Send a once-off notification to a cohort of customers to drive action or keep them informed
                    </CardParagraph>
                </div>
            </ElementWrapper>
            <ElementWrapper>
                <IconWrapper><BrandIconTraining size='xxl'/></IconWrapper>
                <div>Review</div>
                <div>
                    <CardParagraph>
                        Our Design team will make sure your content aligns with the NSW Government style guide.
                    </CardParagraph>
                </div>
            </ElementWrapper>
            <ElementWrapper>
                <IconWrapper><BrandIconDesktop size='xxl'/></IconWrapper>
                <div>Upload</div>
                <div>
                    <CardParagraph>
                        Add your content to our portal and preview your design. We can help and test it too.
                    </CardParagraph>
                </div>
            </ElementWrapper>
            <ElementWrapper>
                <IconWrapper><BrandIconFast size='xxl'/></IconWrapper>
                <div>Send</div>
                <div>
                    <CardParagraph>
                        Once we’ve checked the final design, we’ll either send or schedule your notification.
                    </CardParagraph>
                </div>
            </ElementWrapper>
        </ComponentWrapper>
    );
};

export default WorkFlowComponent;
