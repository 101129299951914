import styled from 'styled-components';

export const NotificationComponentHeader = styled.div`height: fit-content`;

export const NotificationComponentSubHeader = styled.div`
  height: 30px;
  line-height: 26px;
  margin: 32px 0 24px 0;
`;

export const ButtonContainer = styled.div`
  margin-top: 54px;
`;
