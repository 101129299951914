import styled from 'styled-components';
import {
    globalFont,
    fontWeight,
    colors, fontSize, lineHeight, Heading2, Heading3
} from '../CommonComponents/Typography/Typography.styled';

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-family: ${globalFont};
    font-size: ${fontSize.body};
    line-height: ${lineHeight.body};
    font-weight: ${fontWeight.regular};
    padding-bottom: 56px;
`;

export const Paragraph = styled.p`
    margin: 0px;
    + p {
        margin-top: 10px;
    }
`;

export const Heading = styled(Heading2)`
    font-size: ${fontSize.h5};
    line-height: ${lineHeight.h5};
    font-weight: ${fontWeight.bold};
    font-family: ${globalFont};
`;

export const SubHeading = styled(Heading3)`
    font-weight: ${fontWeight.bold};
    font-size: ${fontSize.h6};
    line-height: ${lineHeight.h6};
    font-family: ${globalFont};
    color: ${colors.brand.snswDarkGrey};
`;

export const UnorderedList = styled.ul`
    margin: 10px 0rem;  
`;

export const ListItem = styled.li`
    margin-bottom: 0rem;  
`;