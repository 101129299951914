import styled from 'styled-components';

export const BodyContainer = styled.div`
    display: flex; 
    margin-top: 1rem;
`;

/* Content Container Section */
export const ContentSection = styled.div`
    min-width: 726px;
    //border-top: 1px solid orange;
`;

export const GapSection = styled.div`
    flex: 0 0 125px;
    //border-top: 1px solid red;
`;

export const AnchorMenuSection = styled.div`
    flex: 0 0 266px;
    //border-top: 1px solid green;
`;