import React, {useEffect, useState} from 'react';
import Heading from '@snsw/react-component-library/build/Components/Headings/Heading';
import Button from '@snsw/react-component-library/build/Components/Button/Button';
import apiServices from '../../services/api-services';
import Row from '@snsw/react-component-library/build/layout/Grid/Row.styled';
import Col from '@snsw/react-component-library/build/layout/Grid/Col.styled';
import FormInput from '@snsw/react-component-library/build/Components/Form/FormInput';
import InPageAlert from '@snsw/react-component-library/build/Components/InPageAlert/InPageAlert';
import Form from '@snsw/react-component-library/build/Components/Form/Form';
import {useNavigate} from 'react-router-dom';
import {scrollToTop, useGlobalState} from '../GlobalStateComponent/GlobalState';
import ComponentLoader from '@snsw/react-component-library/build/Loader/ComponentLoader';
import {Modal} from '@snsw/react-component-library';
import {useBoolean} from '@snsw/react-component-library/build/utils';
import {PortalFormContainer} from '../CommonComponents/CustomComponents/CustomComponents.styled';

const CreateAgencyComponent = () => {
    const navigate = useNavigate();
    const [inputErrors, setInputErrors] = useState({
        agencyCode: {hasError: false, errorMessage: 'Please provide an Agency code'},
        description: {hasError: false, errorMessage: 'Please provide an Agency description'},
        dataExchangeAgencyCode: {hasError: false, errorMessage: 'Invalid Data Exchange Agency Code'}
    });
    const [successStatus, setSuccessStatus] = useState({
        variant: 'success',
        title: 'Done',
        description: 'Your agency has been created successfully',
        visible: false
    });
    const [newAgencyConfig, setNewAgencyConfig] = useState(
        {
            'agencyCode': '',
            'description': '',
            'dataExchangeAgencyCode': '',
        }
    );
    const [inProd] = useGlobalState();
    const [loading, setLoading] = useState(false);
    const [showModal, open, close] = useBoolean(false);

    useEffect(() => {
        scrollToTop();
        if (inProd) {
            //navigate and show error
            navigate('/agencies', {
                state: {
                    variant: 'warning',
                    title: 'Page does not exist in Prod',
                    description: 'You can not onboard an agency directly to prod. Onboard in Non-prod and then promote to prod.',
                    visible: true
                }});
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inProd]);

    const handleChange = event => {
        setSuccessStatus(prevState => ({
            ...prevState,
            visible: false
        }));
        const {name, value} = event.target;
        setNewAgencyConfig(prevState => ({
            ...prevState,
            [name]: value
        }));
        setInputErrors(prevState => ({
            ...prevState,
            [name]: {hasError: false, errorMessage: 'Error'},
        }));
    };
    const validateAgencyCode = async () => {
        const pattern = /^[A-Z_]{3,}$/;
        const code = newAgencyConfig.agencyCode;
        let valid;
        if (code.length === 0) {
            setInputErrors(prevState => ({
                ...prevState,
                agencyCode: {hasError: true, errorMessage: 'Please provide an Agency code'}
            }));
            valid = false;
        }else if (code.length > 50) {
            /**
             * customer_notification repo table's have limit of 50 characters for notification_code in notification_batch table
             * Hence applying this max length check for service code as well.
             */
            setInputErrors(prevState => ({
                ...prevState,
                agencyCode: {hasError: true, errorMessage: 'Max 50 characters allowed in an Agency code'}
            }));
            valid = false;
        } else {
            valid = pattern.test(code);
            const err = valid ? '' : 'Invalid Agency Code';
            setInputErrors(prevState => ({
                ...prevState,
                agencyCode: {hasError: !valid, errorMessage: err}
            }));
            if (valid) {
                await apiServices.searchAgencyCode(code, false).then(() => {
                    setInputErrors(prevState => ({
                        ...prevState,
                        agencyCode: {hasError: true, errorMessage: 'Agency code already exists'}
                    }));
                    valid = false;
                })
                    .catch(() => {
                        /**
                         * Search Notification returns error if notification does not exists with notificationCode
                         * So set the error to empty string and return valid = true
                         */
                        const err = '';
                        setInputErrors(prevState => ({
                            ...prevState,
                            agencyCode: {hasError: !valid, errorMessage: err}
                        }));
                        valid = true;
                    });
            }
        }
        return valid;
    };

    const validateRequest = async () => {
        let valid = await validateAgencyCode();
        if (newAgencyConfig.description.length === 0) {
            setInputErrors(prevState => ({
                ...prevState,
                description: {hasError: true, errorMessage: 'Please provide an Agency description'}
            }));
            valid = false;
        }
        return valid;
    };

    const createAgency = async () => {
        setLoading(true);
        validateRequest().then((valid) => {
            if (valid) {
                apiServices.postAgencyConfig(newAgencyConfig).then((response) => {
                    navigate('/agencies', {
                        state: {
                            variant: 'success',
                            title: 'Done',
                            description: 'Your agency has been created successfully',
                            visible: true
                        }
                    });
                })
                    .catch(e => {
                        setLoading(false);
                        setSuccessStatus({
                            variant: 'error',
                            title: 'Network Error',
                            description: e.message,
                            visible: true
                        });
                    });
            } else {
                setLoading(false);
            }
        });
    };

    return (
        <PortalFormContainer>
            { loading && <ComponentLoader fullPage label='Creating the Agency...'/> }
            <Heading
                className='page-title'
                style={ { height: 'fit-content', margin: '6px 0 2rem 0'} }
                level={ 1 }
            >Set up a new agency</Heading>
            <Form data-test='new-agency-form'>
                <Row>
                    <Col span={ 6 }>
                        <FormInput
                            label='Agency Code'
                            name='agencyCode'
                            errorMessage={ inputErrors.agencyCode.errorMessage }
                            helpMessage='Uppercase A-Z only, and underscores (_)'
                            hasError={ inputErrors.agencyCode.hasError }
                            margin={ { top: 'lg' } }
                            onChange={ handleChange }
                            value={ newAgencyConfig.agencyCode }
                        />
                        <FormInput
                            label='Description'
                            name='description'
                            errorMessage={ inputErrors.description.errorMessage }
                            hasError={ inputErrors.description.hasError }
                            margin={ { top: 'lg' } }
                            onChange={ handleChange }
                            value={ newAgencyConfig.description }
                        />
                        <FormInput
                            label='Data Exchange Code'
                            name='dataExchangeAgencyCode'
                            errorMessage={ inputErrors.dataExchangeAgencyCode.errorMessage }
                            hasError={ inputErrors.dataExchangeAgencyCode.hasError }
                            margin={ { top: 'lg' } }
                            onChange={ handleChange }
                            value={ newAgencyConfig.dataExchangeAgencyCode }
                            isOptional
                        />
                    </Col>
                </Row>
                <div style={ { marginTop: '4rem', display: 'flex', gap: '2rem' } }>
                    <Button
                        onClick={ createAgency }
                        variant='primary'
                        id='createNotificationBtn'
                    >Create agency</Button>
                    <Button variant='secondary' id='backBtn' onClick={ open }>Back</Button>
                    {showModal && (
                        <Modal
                            title='Are you sure you want to discard your unsaved changes?' // eslint-disable-next-line max-len
                            description='By going back you will loose all the progress on this form and no Agency will be created.'
                            buttons={ [
                                { text: 'Discard', onClick: navigate('/agencies') },
                                { text: 'Cancel', onClick: () => close() }
                            ] }
                        />
                    )}
                </div>
                {successStatus.visible ? <InPageAlert id='agency-status' variant={ successStatus.variant } title={ successStatus.title }>
                    <p data-test='notStatusDesc'>{ successStatus.description }</p>
                </InPageAlert> : null}
            </Form>
        </PortalFormContainer>
    );
};

export default CreateAgencyComponent;
