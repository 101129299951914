import styled from 'styled-components';
import {
    colors, fontSize,
    fontWeight,
    globalFont,
    Heading1, lineHeight,
    screenSize
} from '../../CommonComponents/Typography/Typography.styled';

const adjustableSize = screenSize.tablet;

export const ComponentWrapper = styled.div`
    padding: 145px 0px 84px 0px;
    display: grid;
    grid-template-columns: auto 37%;
    gap: 24px 81px;
    font-family: ${globalFont};
    
    @media only screen and (max-width: ${adjustableSize}) {
        padding: 56px 0px;
        grid-template-columns: auto;
        gap: 24px 0px;
    }
`;

export const Heading = styled(Heading1)`
    font-size: 48px;
    line-height: 60px;
    color: ${colors.grey.darkGrey};

    @media only screen and (max-width: ${adjustableSize}) {
        font-size: 28px;
        line-height: 36px;
    }
`;

export const ImgWrapper = styled.div`
    grid-column: 2;
    grid-row: 1 / span 3;
    @media only screen and (max-width: ${adjustableSize}) {
        grid-column: 1;
        grid-row: 2;
    }
`;

export const Img = styled.img`
    width: 100%;
    objectFit: cover;
`;

export const Body = styled.div`
    font-weight: ${fontWeight.regular};
    font-size: ${fontSize.h6};
    line-height: ${lineHeight.h6};
    > p {
        margin: 0px;
    }
`;
