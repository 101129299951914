import React from 'react';
import {
    ContentContainer,
    Paragraph,
    Heading,
    UnorderedList,
    ListItem,
    SubHeading
} from './ContentGuidancePushContentComponent.styled';

const ContentGuidancePushContentComponent = (props) => {

    const anchorMenuMetaData = props.menuMetaData;

    return (
        <ContentContainer>
            <div>
                <img width='285' src={ `${process.env.PUBLIC_URL}/assets/images/push-content-sample.png` }
                    alt='push-content-sample'/>
            </div>
            <div id={ `${anchorMenuMetaData.data[1].id}` }><Heading>Overview</Heading></div>
            <div>
                <Paragraph>
                    A push notification is a message sent through our app. They’re always sent with a MyServiceNSW
                    Account Inbox notification.</Paragraph>
                <Paragraph>
                    This guidance is for anyone designing push notifications, particularly those writing the
                    notification content. It can help you decide when and if to use a push notification.</Paragraph>
            </div>
            <div><SubHeading>When to use push</SubHeading></div>
            <div>
                <Paragraph>It’s best to use push notifications as a channel when you need quick contact or engagement
                    from your customers.
                </Paragraph>
                <Paragraph>Use them for:</Paragraph>
                <UnorderedList>
                    <ListItem>announcements</ListItem>
                    <ListItem>reminders</ListItem>
                    <ListItem>updates</ListItem>
                    <ListItem>alerts</ListItem>
                </UnorderedList>
            </div>
            <div><SubHeading>When not to use push</SubHeading></div>
            <div>
                <Paragraph>There are times when push notifications may not be the best solution or most effective
                    channel.</Paragraph>
                <Paragraph>Do not use them for:</Paragraph>
                <UnorderedList>
                    <ListItem>confirmation messaging</ListItem>
                    <ListItem>customer cohorts who may not have our app installed</ListItem>
                </UnorderedList>
            </div>
            <div><SubHeading>Principles for using push</SubHeading></div>
            <div>
                <Paragraph>Push notifications can work well when you need quick communication, engagement, or action
                    from your customers.
                    This is because they can:</Paragraph>
                <UnorderedList>
                    <ListItem>enhance the user experience</ListItem>
                    <ListItem>drive user engagement</ListItem>
                    <ListItem>help achieve a business goal</ListItem>
                </UnorderedList>
                <Paragraph>Customers can be inundated with push notifications, so it’s important to make yours stand out
                    and engage
                    your customers effectively.</Paragraph>
                <Paragraph>Our research shows customers feel comfortable receiving regular Service NSW push
                    notifications if they’re
                    timely, valuable and concise. You’ll also get the highest engagement rate when you follow these
                    principles.</Paragraph>
            </div>
            <div><SubHeading>Timely</SubHeading></div>
            <div>
                <Paragraph>Sending push notifications in real time allows customers to stay informed about important
                    updates.
                    This means the user is less likely to go searching for information themselves,
                    leading to a better customer experience.</Paragraph>
            </div>
            <div><SubHeading>Valuable</SubHeading></div>
            <div>
                <Paragraph>Push notifications are valuable when they share useful information that aligns with the
                    customer’s interests.
                </Paragraph>
                <Paragraph>Customers are more likely to interact with a push notification when it's genuinely helpful or
                    interesting to them. For example, content about vouchers, application updates, or appointment
                    reminders.
                </Paragraph>
                <Paragraph>These types of notification can help us connect with customers and drive meaningful
                    interactions. They also improve engagement and increase understanding of our service offering.
                </Paragraph>
            </div>
            <div><SubHeading>Concise</SubHeading></div>
            <div>
                <Paragraph>Push notifications should be short and snappy. Different devices will truncate at different
                    parts of the notification.
                </Paragraph>
            </div>
            <div><SubHeading>Character limits</SubHeading></div>
            <div>
                <Paragraph>Push title: 29 character limit</Paragraph>
                <Paragraph>Push body copy: For best engagement, we recommend a 78 character limit.</Paragraph>
            </div>
            <div id={ `${anchorMenuMetaData.data[2].id}` }><Heading>Measuring impact</Heading></div>
            <div><SubHeading>Unopened notifications</SubHeading></div>
            <div>
                <Paragraph>Our research shows open rates and success do not always correlate.
                    This is because customers can find push notifications valuable without opening them.</Paragraph>
                <Paragraph>An unopened notification can be useful for informative or operational purposes
                    like transaction updates or emergency alerts.</Paragraph>
            </div>
            <div id={ `${anchorMenuMetaData.data[3].id}` }><Heading>Average open rates</Heading></div>
            <div><SubHeading>Push vs inbox</SubHeading></div>
            <div>
                <Paragraph>Push notifications have higher open rates than MyServiceNSW Account Inbox
                    notifications.</Paragraph>
                <Paragraph>As of February 2024 open rates are:</Paragraph>
                <UnorderedList>
                    <ListItem>4.9% for Inbox</ListItem>
                    <ListItem>12.7% for push</ListItem>
                </UnorderedList>
            </div>
            <div><SubHeading>Marketing vs transactional </SubHeading></div>
            <div>
                <Paragraph>Marketing notifications aim to promote and increase uptake of a product or service.
                    The audience is typically customers who do not already use that product or service.
                    Examples include campaigns to promote new vouchers or prompts to change to digital rego reminders.</Paragraph>
                <Paragraph>Transactional notifications are about a product or service the customer is already using.
                    Examples include voucher expiry, or vehicle registration reminders.</Paragraph>
                <Paragraph>As of April 2024 open rates are:</Paragraph>
                <UnorderedList>
                    <ListItem>5 to 7% for marketing</ListItem>
                    <ListItem>19 to 20% for transactional</ListItem>
                </UnorderedList>
            </div>
        </ContentContainer>
    );
};

export default ContentGuidancePushContentComponent;
