import React, {useEffect, useState} from 'react';
import apiServices from '../../services/api-services';
import {
    InPageAlert,
    Form,
    FormSelect,
    FormInput,
    Col,
    Row,
    Button,
    Heading,
    FormRadioGroup,
    Modal,
} from '@snsw/react-component-library';
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom';
import {useBoolean} from '@snsw/react-component-library/build/utils';
import {scrollToTop, useGlobalState, useUserRoleState} from '../GlobalStateComponent/GlobalState';
import ComponentLoader from '@snsw/react-component-library/build/Loader/ComponentLoader';
import {PortalFormContainer} from '../CommonComponents/CustomComponents/CustomComponents.styled';

const UpdateServiceComponent = () => {
    const { serviceCode } = useParams();
    const radioOptions = [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' },
    ];
    const consentModeOptions = [
        { value: 'EXPLICIT', text: 'EXPLICIT' },
        { value: 'IMPLICIT', text: 'IMPLICIT' },
        { value: 'MARKETING', text: 'MARKETING' },
        { value: 'NOT_REQUIRED', text: 'NOT_REQUIRED' },
    ];
    const navigate = useNavigate();
    const [isServiceCodeUpdated, setIsServiceCodeUpdated] = useState(false);
    const [isServiceConfigUpdated, setIsServiceConfigUpdated] = useState(false);
    const { state } = useLocation();
    const [inputErrors, setInputErrors] = useState({
        serviceCode: {hasError: false, errorMessage: 'Please provide a Service code'},
        description: {hasError: false, errorMessage: 'Please provide a Service description'},
        consentMode: {hasError: false, errorMessage: 'You must select a consent mode'},
        linkingServiceId: {hasError: false, errorMessage: 'Please provide a linking Service Id'},
        dataExchangeAgencyServiceCode: {hasError: false, errorMessage: 'Please provide a DEX Agency Service Code'},
        myaccAgencyStore: {hasError: false, errorMessage: 'Please provide a MyAccount Agency Store name'},
        serviceChannels: {hasError: false, errorMessage: 'You must select at least one channel'},
    });
    const [successStatus, setSuccessStatus] = useState({
        variant: 'success',
        title: 'Done',
        description: 'Your service has been updated successfully',
        visible: false
    });
    const [inProd] = useGlobalState();
    const {userRole} = useUserRoleState();
    const [agencyConfig, setAgencyConfig] = useState({});
    const [serviceConfig, setServiceConfig] = useState({
        serviceCode: '',
        description: '',
        serviceType: 'DIGITAL',
        consentMode: '',
        linkingServiceId: '',
        showEmail: 'true',
        sendPreferenceChangeEventForAgency: false,
        dataExchangeAgencyServiceCode: '',
        myaccAgencyStore: '',
        showInPreferencePage: false,
        agency: null
    });
    const [initialServiceConfig, setInitialServiceConfig] = useState({});
    const [showModal, open, close] = useBoolean(false);
    const [loading, setLoading] = useState(false);
    const [removalModelDescription, setRemovalModelDescription] = useState('');
    const [removalModelButtons, setRemovalModelButtons] = useState([]);
    const [showRemoveModal, openRemoveModal, closeRemoveModal] = useBoolean(false);
    const [servicePendingChanges, setServicePendingChanges] = useState({
        pendingChanges: false,
        inProd: true,
        notificationsExists:false,
    });
    useEffect(() => {
        apiServices.searchServiceCode(serviceCode, inProd).then(data => {
            setAgencyConfig({
                ...data.agency
            });
            setInitialServiceConfig({
                ...data,
                agency: data.agency.id
            });

            setServiceConfig( {
                ...data,
                agency: data.agency.id
            });
            if (!inProd) {
                apiServices.compareService(serviceCode)
                    .then(data => {
                        setServicePendingChanges(data);
                    })
                    .catch(() => {
                        setSuccessStatus({
                            variant: 'warning',
                            title: 'Network Error',
                            description: 'An Error occurred while trying to compare Non-Prod and prod configs.',
                            visible: true
                        });
                    });
            } else {
                setServicePendingChanges({
                    pendingChanges: false,
                    inProd: true,
                    notificationsExists:false
                });
            }
        })
            .catch(error => {
                if (inProd) {
                    //navigate and show error
                    navigate(`/services/${agencyConfig.agencyCode}`, {
                        state: {
                            alert: {
                                variant: 'warning',
                                title: 'Page does not exist in Prod', // eslint-disable-next-line max-len
                                description: 'This Service does not exist in prod. Update in Non-prod and then promote to prod.',
                                visible: true
                            },
                        }
                    });
                } else {
                    navigate(`/services/${agencyConfig.agencyCode}`, {
                        state: {
                            alert: {
                                variant: 'error',
                                title: 'Network Error',
                                description: error,
                                visible: true
                            },
                        }
                    });
                }
            });
        // eslint-disable-next-line
    }, [serviceCode, inProd]);

    useEffect(() => {
        if (JSON.stringify(initialServiceConfig) !== JSON.stringify(serviceConfig)) {
            setIsServiceConfigUpdated(true);
        } else {
            setIsServiceConfigUpdated(false);
        }// eslint-disable-next-line
    }, [serviceConfig]);

    useEffect(() => {
        scrollToTop();
        if(servicePendingChanges && servicePendingChanges.inProd){
            setRemovalModelDescription(`Service settings for '${serviceConfig.description}' can not be deleted. `+
                'It is already promoted to Production');
            setRemovalModelButtons([{ text: 'Back', onClick: onRemoveModelBackClicked }]);
        }else if(servicePendingChanges && servicePendingChanges.notificationsExists){
            setRemovalModelDescription(`Service settings for '${serviceConfig.description}' can not be deleted. ` +
                'There are notifications setting under this service settings');
            setRemovalModelButtons([{ text: 'Back', onClick: onRemoveModelBackClicked }]);
        }else if(servicePendingChanges){
            setRemovalModelDescription(`Service settings for '${serviceConfig.description}' will be permanently deleted. `+
                'Do you want to proceed.');
            setRemovalModelButtons([
                { text: 'Delete', onClick: onRemoveServiceSettings },
                { text: 'Back', onClick: onRemoveModelBackClicked }
            ]);
        }// eslint-disable-next-line
    }, [servicePendingChanges]);

    const handleChange = event => {
        const {name, value} = event.target;
        setServiceConfig(prevState => ({
            ...prevState,
            [name]: value
        }));
        if(name === 'serviceCode'){
            if(value !== serviceCode){
                setIsServiceCodeUpdated(true);
            } else {
                setIsServiceCodeUpdated(false);
            }
        }
        setInputErrors(prevState => ({
            ...prevState,
            [name]: {hasError: false, errorMessage: 'Error'},
        }));
        if (initialServiceConfig !== serviceConfig) {
            setIsServiceConfigUpdated(true);
        } else {
            setIsServiceConfigUpdated(false);
        }
    };

    const discardChanges = () => {
        navigate(`/services/${agencyConfig.agencyCode}`, { state: {
            isExpanded: true,
            serviceCode: serviceConfig.serviceCode
        } });
    };

    const validateServiceCode = async () => {
        const pattern = /^[A-Z_]{3,}$/;
        const code = serviceConfig.serviceCode;
        let valid;

        if (code.trim().length === 0) {
            setServiceConfig(prevState => ({
                ...prevState,
                serviceCode: ''
            }));
            setInputErrors(prevState => ({
                ...prevState,
                serviceCode: {hasError: true, errorMessage: 'Please provide a Service code'}
            }));
            valid = false;
        }else if (code.length > 50) {
            /**
             * customer_notification repo table's have limit of 50 characters for notification_code in notification_batch table
             * Hence applying this max length check for service code as well.
             */
            setInputErrors(prevState => ({
                ...prevState,
                serviceCode: {hasError: true, errorMessage: 'Max 50 characters allowed in a Service code'}
            }));
            valid = false;
        } else {
            valid = pattern.test(code);
            const err = valid ? '' : 'Invalid Service Code';
            setInputErrors(prevState => ({
                ...prevState,
                serviceCode: {hasError: !valid, errorMessage: err}
            }));
            if (valid && isServiceCodeUpdated) {
                await apiServices.searchServiceCode(code).then(() => {
                    setInputErrors(prevState => ({
                        ...prevState,
                        serviceCode: {hasError: true, errorMessage: 'Service code already exists'}
                    }));
                    valid = false;
                })
                    .catch(() => {
                        /**
                         * Search Services returns error if service does not exists with serviceCode
                         * So set the error to empty string and return valid = true
                         */
                        const err = '';
                        setInputErrors(prevState => ({
                            ...prevState,
                            serviceCode: {hasError: !valid, errorMessage: err}
                        }));
                        valid = true;
                    });
            }
        }
        return valid;
    };

    const validateRequest = async () => {
        let valid = servicePendingChanges?.inProd ? true : await validateServiceCode();
        if (serviceConfig.description.trim().length === 0) {
            setServiceConfig(prevState => ({
                ...prevState,
                description: ''
            }));
            setInputErrors(prevState => ({
                ...prevState,
                description: {hasError: true, errorMessage: 'Please provide a Service description'}
            }));
            valid = false;
        }
        if (serviceConfig.consentMode === '') {
            setInputErrors(prevState => ({
                ...prevState,
                consentMode: {hasError: true, errorMessage: 'You must select a consent mode'}
            }));
            valid = false;
        }
        if (serviceConfig.sendPreferenceChangeEventForAgency === true) {
            if(serviceConfig.linkingServiceId === null || serviceConfig.linkingServiceId.trim() === '') {
                setInputErrors(prevState => ({
                    ...prevState,
                    linkingServiceId: {hasError: true, errorMessage: 'Please provide a linking Service Id'}
                }));
                valid = false;
            }
            if(serviceConfig.dataExchangeAgencyServiceCode === null || serviceConfig.dataExchangeAgencyServiceCode.trim() === '') {
                setInputErrors(prevState => ({
                    ...prevState,
                    dataExchangeAgencyServiceCode: {hasError: true, errorMessage: 'Please provide a DEX Agency Service Code'}
                }));
                valid = false;
            }
            if(serviceConfig.myaccAgencyStore === null || serviceConfig.myaccAgencyStore.trim() === '') {
                setInputErrors(prevState => ({
                    ...prevState,
                    myaccAgencyStore: {hasError: true, errorMessage: 'Please provide a MyAccount Agency Store name'}
                }));
                valid = false;
            }
        }
        if(valid && serviceConfig.sendPreferenceChangeEventForAgency === false) {
            setServiceConfig(prevState => ({
                ...prevState,
                linkingServiceId: '',
                dataExchangeAgencyServiceCode: '',
                myaccAgencyStore: ''
            }));
        }
        return valid;
    };

    const updateService = async () => {
        setLoading(true);
        validateRequest().then((valid) => {
            if (valid) {
                //Using object destructing to remove non required attributes from the API request payload.
                const {notifications, createdByUser, createdDateTime, modifiedDateTime, status, ...serviceConfigToUpdate} = serviceConfig;
                apiServices.updateServiceConfig(serviceConfigToUpdate).then((response) => {
                    if(response.status === 200) {
                        navigate(`/services/${agencyConfig.agencyCode}`, {
                            state: {
                                alert: {
                                    variant: 'success',
                                    title: 'Done',
                                    description: 'Your service has been updated successfully',
                                    visible: true
                                },
                                serviceCode: serviceConfig.serviceCode,
                                isExpanded: true
                            }
                        });
                    }
                })
                    .catch(e => {
                        setLoading(false);
                        setSuccessStatus({
                            variant: 'error',
                            title: 'Network Error',
                            description: e.message,
                            visible: true
                        });
                    });
            } else {
                setLoading(false);
            }
        });
    };

    const onRemoveServiceSettings = (event) => {
        event.stopPropagation();
        closeRemoveModal();
        apiServices.removeService(serviceCode).then(data=>{
            navigate(`/services/${agencyConfig.agencyCode}`, {
                state: {
                    alert: {
                        variant: 'success',
                        title: 'Done',
                        description: 'Your service has been deleted successfully',
                        visible: true
                    }
                }
            });
            //props.setRefreshAllServices(true);
        })
            .catch(e => {
                navigate(`/services/${agencyConfig.agencyCode}`, {
                    state: {
                        alert:{
                            variant: 'error',
                            title: 'Delete Service Error',
                            description: e.message,
                            visible: true
                        },
                        isExpanded: true,
                        serviceCode: serviceCode,
                    }
                });
            });
    };

    const onRemoveModalOpen = (event) => {
        event.stopPropagation();
        openRemoveModal();
    };

    const onRemoveModelBackClicked = (event) => {
        event.stopPropagation();
        closeRemoveModal();
    };

    return (
        <PortalFormContainer>
            { loading && <ComponentLoader fullPage label='Updating the Service...'/> }
            { !isServiceConfigUpdated ?
                <Button variant='back' id='backBtn' onClick={ discardChanges }>Back</Button> :
                <>
                    <Button variant='back' id='backBtn' onClick={ open }>Back</Button>
                    {showModal && (
                        <Modal
                            title='Are you sure you want to discard your unsaved changes?'
                            description='By going back you will loose all the progress on
                                    this form and service will not be updated.'
                            buttons={ [
                                { text: 'Discard', onClick: discardChanges },
                                { text: 'Cancel', onClick: () => close() }
                            ] }
                        />
                    )}
                </>
            }
            <Heading className='page-title' style={ { height: 'fit-content', margin: '0.375rem 0 2rem 0'} } level={ 1 }>
                {inProd ? 'View service' : 'Update a service'}
            </Heading>
            <Form data-test='update-service-form'>
                <Row>
                    <Col span={ 6 }>
                        <FormInput
                            label='Service Code'
                            name='serviceCode'
                            disabled={ (servicePendingChanges != null ? servicePendingChanges.inProd : true) || inProd }
                            errorMessage={ inputErrors.serviceCode.errorMessage }
                            helpMessage='Uppercase A-Z only, and underscores (_)'
                            hasError={ inputErrors.serviceCode.hasError }
                            onChange={ handleChange }
                            value={ serviceConfig.serviceCode }
                        />
                        <FormInput
                            label='Description'
                            disabled={ inProd }
                            name='description'
                            errorMessage={ inputErrors.description.errorMessage }
                            hasError={ inputErrors.description.hasError }
                            margin={ { top: 'lg' } }
                            onChange={ handleChange }
                            value={ serviceConfig.description }
                        />
                        <FormSelect
                            label='Consent Mode'
                            disabled={ inProd }
                            name='consentMode'
                            errorMessage={ inputErrors.consentMode.errorMessage }
                            hasError={ inputErrors.consentMode.hasError }
                            margin={ { top: 'lg' } }
                            onChange={ handleChange }
                            value={ serviceConfig.consentMode }
                            options={ consentModeOptions }
                            placeholder='Select'
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={ 6 }>
                        { !['SNSW', 'SNSW_TEST'].includes(state.agency.agencyCode) &&
                            <>
                                <FormInput
                                    label='Linking Service Id'
                                    name='linkingServiceId'
                                    helpMessage='The service id used by service activation (requires onboarding with them)'
                                    errorMessage={ inputErrors.linkingServiceId.errorMessage }
                                    hasError={ inputErrors.linkingServiceId.hasError }
                                    margin={ { top: 'lg' } }
                                    onChange={ handleChange }
                                    value={ serviceConfig.linkingServiceId }
                                />
                                <FormInput
                                    label='My Account Agency Store'
                                    name='myaccAgencyStore'
                                    helpMessage='Required if DEX is going to opt customer out using /v1/event endpoint'
                                    errorMessage={ inputErrors.myaccAgencyStore.errorMessage }
                                    hasError={ inputErrors.myaccAgencyStore.hasError }
                                    margin={ { top: 'lg' } }
                                    onChange={ handleChange }
                                    value={ serviceConfig.myaccAgencyStore }
                                    isOptional
                                />
                                <FormRadioGroup
                                    name='sendPreferenceChangeEventForAgency'
                                    legend='Send Preference Change Event For Agency'
                                    vertical={ false } // eslint-disable-next-line max-len
                                    options={ inProd ? radioOptions.filter(option => option.value === serviceConfig.sendPreferenceChangeEventForAgency) : radioOptions } // eslint-disable-next-line max-len
                                    onChange={ (v) => setServiceConfig(prevState => ( {...prevState, sendPreferenceChangeEventForAgency: v ==='true' })) }
                                    value={ serviceConfig.sendPreferenceChangeEventForAgency }
                                    isOptional
                                    margin={ { top: 'xxxl' } }
                                />
                                { serviceConfig.sendPreferenceChangeEventForAgency &&
                                    <FormInput
                                        label='Data Exchange Service Code'
                                        name='dataExchangeAgencyServiceCode'
                                        helpMessage='The service code used by data exchange (requires onboarding with them)'
                                        errorMessage={ inputErrors.dataExchangeAgencyServiceCode.errorMessage }
                                        hasError={ inputErrors.dataExchangeAgencyServiceCode.hasError }
                                        margin={ { top: 'lg' } }
                                        onChange={ handleChange }
                                        value={ serviceConfig.dataExchangeAgencyServiceCode }
                                        isOptional={ serviceConfig.sendPreferenceChangeEventForAgency }
                                    />
                                }
                            </>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col span={ 6 }>
                        <FormRadioGroup
                            name='showEmail'
                            legend='Show customer email addresses in reporting?'
                            disabled={ inProd }
                            vertical={ false } // eslint-disable-next-line max-len
                            options={ inProd ? radioOptions.filter(option => option.value === serviceConfig.showEmail) : radioOptions }
                            onChange={ (v) => setServiceConfig(prevState => ( {...prevState, showEmail: v === 'true'} )) }
                            value={ serviceConfig.showEmail }
                            isOptional
                            margin={ { top: 'xxxl' } }
                        />
                    </Col>
                </Row>
                <div style={ { marginTop: '4rem', display: 'flex', gap: '1.25rem', alignItems: 'center' } }>
                    {!inProd && <Button
                        onClick={ updateService }
                        disabled={ !isServiceConfigUpdated }
                        variant='primary'
                        id='updateServiceBtn'
                    >Update service</Button>}
                    { !inProd && userRole.permissions.SERVICE.includes('DELETE') &&
                        <Button variant='link' id= { `removeServiceSettingsBtn-${serviceCode}` }
                            data-testid={ `removeServiceSettingsBtn-${serviceCode}` }
                            as={ Link }
                            onClick={ onRemoveModalOpen }
                            style={ { marginLeft: '1.75rem' } }
                        >
                            Delete service
                        </Button>
                    }
                    {showRemoveModal && (
                        <Modal
                            title='Delete service settings'
                            description= { removalModelDescription }
                            buttons={ removalModelButtons }
                        />
                    )}
                </div>
                {successStatus.visible ? <InPageAlert id='service-status' variant={ successStatus.variant } title={ successStatus.title }>
                    <p data-test='notStatusDesc'>{ successStatus.description }</p>
                </InPageAlert> : null}
            </Form>

        </PortalFormContainer>
    );
};

export default UpdateServiceComponent;
