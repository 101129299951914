import {InPageAlert, Table, TableBody, TableHead, TableHeader, TableRow} from '@snsw/react-component-library';
import {TableContainer} from '../TableContainerComponent/TableContainer';
import * as PropTypes from 'prop-types';
import React from 'react';

const UserListComponent = (props) => {

    return <>
        {props.displayedUsers.length === 0 ?
            <InPageAlert
                data-testid={ 'permissionErrorAlert' }
                variant={ 'error' }
                title={ 'No users found' }
                style={ {width: '100%'} }
            >
                <p/>
            </InPageAlert> :
            <TableContainer title=''>
                <Table striped>
                    <TableHead>
                        <TableRow>
                            <TableHeader width={ '21%' }>Name</TableHeader>
                            <TableHeader width={ '26%' }>Email address</TableHeader>
                            <TableHeader width={ '16%' }>Role</TableHeader>
                            <TableHeader width={ '21%' }>Team</TableHeader>
                            <TableHeader width={ '16%' }>Action</TableHeader>
                        </TableRow>
                    </TableHead>
                    <TableBody style={ {background: '#ffffff'} }>
                        {props.displayedUsers.map(props.callbackfn)}
                    </TableBody>
                </Table>
            </TableContainer>
        }
    </>;
};

UserListComponent.propTypes = {
    displayedUsers: PropTypes.array,
    callbackfn: PropTypes.func
};

export default UserListComponent;