import styled from 'styled-components';
import { Tab, Tabs, TabList} from 'react-tabs';

export const ContentSubHeader = styled.div`
    width: 1170px;
    height: fit-content;
    color: rgb(36, 41, 52);
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 12px;
`;

export const TabsContainer = styled(Tabs)`
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
`;

export const selectedTab = `
    border-width: 4px 1px 0px;
    border-style: solid;
    border-top-color: rgb(198, 12, 48);
    border-right-color: rgb(216, 216, 216);
    border-left-color: rgb(216, 216, 216);
    border-radius: 0px;
    display: flex;
    align-items: center;
    margin-bottom: -1px;
    margin-right: unset;
    justify-content: center;
    height: 56px;
    background: rgb(255, 255, 255);
    padding-top:0px;
`;

export const StyledTabList = styled(TabList)`
    border-radius: 0px;
    border-width: 0px 1px 1px 0px;
    border-style: solid;
    border-color: rgb(216, 216, 216);
    display: flex;
    align-items: center;
    margin-bottom: unset;
    margin-right: unset;
    background: rgba(203, 212, 216, 0.2);
    height: 56px;
    flex-direction: row;
    align-content: space-evenly;
    padding-left: unset;
    max-width: 1000px;
`;

export const StyledTab = styled(Tab)`
    font-family: Gotham A, Gotham B, Gotham, Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: rgb(36, 41, 52);
    text-underline: none;
    text-align: center;
    padding: 0rem 1.875rem 0rem 1.875rem;
    width: 440px;
    height: 56px;
    cursor: pointer;
    &.is-selected {${ selectedTab }};
    border-right-color: rgb(216, 216, 216);
    border-left-color: rgb(216, 216, 216);
    padding-top:20px;
`;

export const ButtonContainer = styled.div`
  margin-top: 54px;
`;