import Heading from '@snsw/react-component-library/build/Components/Headings/Heading';
import React from 'react';
import { SubjectComponent } from './SubjectComponent';
import Textarea from '@snsw/react-component-library/build/Components/Textarea/Textarea';
import FormGroup from '@snsw/react-component-library/build/Components/FormGroup/FormGroup';
import { IconEmail } from '@snsw/react-component-library/build/Icons/system';
import { NotificationComponentSubHeader } from '../SendNotificationComponent/SendNotificationComponent.styled';
import Button from '@snsw/react-component-library/build/Components/Button/Button';
import DOMPurify from 'dompurify';

export const EmailContentComponent = ({
    emailCallback,
    notificationRequest,
    setNotificationRequest,
    inputErrors,
    setInputErrors,
    htmlValidation
}) => {

    const validateHTMLContent = async () => {
        htmlValidation.current = true;
        setInputErrors(prevState => ({
            ...prevState,
            emailContent: {hasError: false, errorMessage: 'Error'},
        }));
        const validContent = DOMPurify.sanitize(notificationRequest.email.content);
        setNotificationRequest(prevState => ({
            ...prevState,
            emailContent: validContent
        }));
    };

    return <div style={ { maxWidth: '75%' } }>
        <NotificationComponentSubHeader>
            <IconEmail/>
            <Heading level={ 4 } style={ {display: 'inline', paddingLeft: '8px'} }>Email</Heading>
        </NotificationComponentSubHeader>
        <SubjectComponent
            id='email-subj'
            dataTest= 'email-subject'
            value={ notificationRequest.email.subject }
            label='Subject'
            helpMessage='The subject shown to customers'
            onChange={ emailCallback }
            name='subject'
            inputError={ inputErrors.emailSubject }
        />
        <FormGroup
            label='Notification content'
            helpMessage='Text or HTML content'
            id='email-content-text'
            margin={ { top: 'lg' } }
            hasError={ inputErrors.emailContent.hasError }
            errorMessage={ inputErrors.emailContent.errorMessage }
        >
            <Textarea
                data-testid='email-content'
                rows='large'
                onChange={ emailCallback }
                value={ notificationRequest.email.content }
                name='content'
            />
        </FormGroup>
        <Button
            onClick={ validateHTMLContent }
            variant='secondary'
            id='sanitizerBtn'
            style={ { margin: '10px 0 0 0'} }>
            Validate HTML content
        </Button>
    </div>;
};
