import {GreenCodeFont, Paragraph} from './APIDocsComponent.styled';
import React from 'react';

const AuthenticationRequestCode = (props) => {
    return (
        <div id={ props.id }>
            {/* eslint-disable-next-line max-len */}
            <Paragraph>curl --location -g --request
                POST <GreenCodeFont>{'\'https://api-psm.g.testservicensw.net/v1/identity/oauth/client-credentials/token\''}</GreenCodeFont> \</Paragraph>
            {/* eslint-disable-next-line max-len */}
            <Paragraph>--header <GreenCodeFont>{'\'Content-Type: application/x-www-form-urlencoded\''}</GreenCodeFont> \</Paragraph>
            {/* eslint-disable-next-line max-len */}
            <Paragraph>--data-urlencode <GreenCodeFont>{'\'grant_type=client_credentials\''}</GreenCodeFont> \</Paragraph>
            {/* eslint-disable-next-line max-len */}
            <Paragraph>--data-urlencode <GreenCodeFont>{'\'client_id=<client-id>\''}</GreenCodeFont> \</Paragraph>
            {/* eslint-disable-next-line max-len */}
            <Paragraph>--data-urlencode <GreenCodeFont>{'\'client_secret=<client-secret>\''}</GreenCodeFont> \</Paragraph>
        </div>
    );
};

export default AuthenticationRequestCode;