import styled from 'styled-components';
import Textarea from '@snsw/react-component-library/build/Components/Textarea/Textarea';

export const TextArea = styled(Textarea)`
    flex: 1;
`;

export const ContentSubHeader = styled.div`width: 1170px;
  height: fit-content;
  color: rgb(36, 41, 52);
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 40px;
  line-height: 24px;
`;