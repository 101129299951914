import {colors} from '../CommonComponents/Typography/Typography.styled';
import {
    BodyBold,
    BodyRegular, BodySemiBold, BodyXS,
    fontSize,
    Heading1,
    Heading2,
    Heading3,
    Heading4, Heading5Bold, Heading5SemiBold, Heading6Bold,
    Heading6Regular, Heading6SemiBold, lineHeight
} from '../CommonComponents/Typography/Typography.styled';
import {PortalFormContainer} from '../CommonComponents/CustomComponents/CustomComponents.styled';
import {Divider} from '@mui/material';
import styled from 'styled-components';

const ExampleSectionContainer = styled.div`
    color: ${colors.brand.snswDarkGrey};
    display: flex;
    flex-direction: column;
    row-gap: 50px;
    margin-top: 50px;
    margin-bottom: 50px;
`;

const ExampleStyleContent = (props) => {
    return (
        <div style={ {display: 'flex', flexDirection: 'column', rowGap: '8px'} }>
            {props.children}
            <Heading6Regular style={ {color: colors.grey.shuttle} }>
                Font size: {fontSize[`${props.level}`]} | Line height: {lineHeight[`${props.level}`]}
            </Heading6Regular>
            <Divider color={ colors.grey.geyser } width={ '67%' } style={ {marginBottom: '32px'} }/>
        </div>
    );
};

const TypographyComponent = () => {
    const iconUrl = `${process.env.PUBLIC_URL}/assets/images/snsw_app_icon.png`;
    return <>
        <PortalFormContainer>
            <div style={ {display: 'flex', marginBottom: '64px'} }>
                <img src={ iconUrl } height={ '76px' } alt=''></img>
                <div style={ {marginLeft: '32px'} }>
                    <h1 style={ {
                        fontWeight: 800,
                        fontSize: '36px',
                        lineHeight: '44px',
                        margin: '0 0 12px 0'
                    } }>Typography</h1>
                    <div style={ {fontSize: '18px', lineHeight: '28px', color: colors.grey.shuttle} }>
                        A set of harmonous typography scale with font weight variations.
                    </div>
                </div>
            </div>
            <Divider color={ colors.grey.geyser }/>
            <ExampleSectionContainer>
                <h2 style={ {fontSize: '32px', lineHeight: '40px'} }>Headings Scale</h2>
                <ExampleStyleContent level={ 'h1' }>
                    <Heading1>Heading H1</Heading1>
                </ExampleStyleContent>
                <ExampleStyleContent level={ 'h2' }>
                    <Heading2>Heading H2</Heading2>
                </ExampleStyleContent>
                <ExampleStyleContent level={ 'h3' }>
                    <Heading3>Heading H3</Heading3>
                </ExampleStyleContent>
                <ExampleStyleContent level={ 'h4' }>
                    <Heading4>Heading H4</Heading4>
                </ExampleStyleContent>
                <ExampleStyleContent level={ 'h5' }>
                    <div style={ {display: 'flex', columnGap: '32px'} }>
                        <Heading5SemiBold>Heading H5 - Semibold</Heading5SemiBold>
                        <Heading5Bold>Heading H5 - Bold</Heading5Bold>
                    </div>
                </ExampleStyleContent>
                <ExampleStyleContent level={ 'h6' }>
                    <div style={ {display: 'flex', columnGap: '32px'} }>
                        <Heading6Regular>Heading 6 - Regular</Heading6Regular>
                        <Heading6SemiBold>Heading H6 - Semibold</Heading6SemiBold>
                        <Heading6Bold>Heading H6 - Bold</Heading6Bold>
                    </div>
                </ExampleStyleContent>
            </ExampleSectionContainer>
            <Divider color={ colors.brand.snswDarkGrey }/>
            <ExampleSectionContainer>
                <h2 style={ {fontSize: '32px', lineHeight: '40px'} }>Body text</h2>
                <ExampleStyleContent level={ 'body' }>
                    <div style={ {display: 'flex', columnGap: '32px'} }>
                        <BodyRegular>Body - Regular</BodyRegular>
                        <BodySemiBold>Body - Semibold</BodySemiBold>
                        <BodyBold>Body - Bold</BodyBold>
                    </div>
                </ExampleStyleContent>
                <ExampleStyleContent level={ 'bodyXS' }>
                    <BodyXS>Body - XS</BodyXS>
                </ExampleStyleContent>
            </ExampleSectionContainer>
        </PortalFormContainer>
    </>
    ;
};

export default TypographyComponent;