import React, {useEffect, useState} from 'react';
import apiServices from '../../services/api-services';
import {
    InPageAlert,
    Form,
    FormSelect,
    FormInput,
    Col,
    Row,
    Button,
    Heading,
    FormRadioGroup,
    Modal, ComponentLoader,
} from '@snsw/react-component-library';
import {useLocation, useNavigate} from 'react-router-dom';
import {useBoolean} from '@snsw/react-component-library/build/utils';
import {scrollToTop, useGlobalState} from '../GlobalStateComponent/GlobalState';
import {PortalFormContainer} from '../CommonComponents/CustomComponents/CustomComponents.styled';

const CreateServiceComponent = () => {
    const { state } = useLocation();
    const radioOptions = [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' },
    ];
    const consentModeOptions = [
        { value: 'EXPLICIT', text: 'EXPLICIT' },
        { value: 'IMPLICIT', text: 'IMPLICIT' },
        { value: 'MARKETING', text: 'MARKETING' },
        { value: 'NOT_REQUIRED', text: 'NOT_REQUIRED' },
    ];
    const navigate = useNavigate();
    const [inputErrors, setInputErrors] = useState({
        serviceCode: {hasError: false, errorMessage: 'Please provide a Service code'},
        description: {hasError: false, errorMessage: 'Please provide a Service description'},
        consentMode: {hasError: false, errorMessage: 'You must select a consent mode'},
        linkingServiceId: {hasError: false, errorMessage: 'Please provide a linking Service Id'},
        dataExchangeAgencyServiceCode: {hasError: false, errorMessage: 'Please provide a DEX Agency Service Code'},
        myaccAgencyStore: {hasError: false, errorMessage: 'Please provide a MyAccount Agency Store name'},
        serviceChannels: {hasError: false, errorMessage: 'You must select at least one channel'},
    });
    const [successStatus, setSuccessStatus] = useState({
        variant: 'success',
        title: 'Done',
        description: 'Your service has been created successfully',
        visible: false
    });
    const [newServiceConfig, setNewServiceConfig] = useState({
        serviceCode: '',
        description: '',
        serviceType: 'DIGITAL',
        consentMode: '',
        linkingServiceId: '',
        showEmail: true,
        sendPreferenceChangeEventForAgency: false,
        dataExchangeAgencyServiceCode: '',
        myaccAgencyStore: '',
        showInPreferencePage: false,
        agency: state.agency.id
    });
    const [showModal, open, close] = useBoolean(false);
    const [inProd] = useGlobalState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        scrollToTop();
        if (inProd) {
            //navigate and show error
            navigate(`/services/${state.agency.agencyCode}`, {
                state: {
                    alert: {
                        variant: 'warning',
                        title: 'Page does not exist in Prod', // eslint-disable-next-line max-len
                        description: 'You can not onboard a service directly to prod. Onboard in Non-prod and then promote to prod.',
                        visible: true
                    },
                }
            });

        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inProd]);

    const handleChange = event => {
        const {name, value} = event.target;
        setNewServiceConfig(prevState => ({
            ...prevState,
            [name]: value
        }));
        setInputErrors(prevState => ({
            ...prevState,
            [name]: {hasError: false, errorMessage: 'Error'},
        }));
    };

    const discardChanges = () => {
        navigate(`/services/${state.agency.agencyCode}`, { state: {
        } });
    };

    const validateServiceCode = async () => {
        const pattern = /^[A-Z_]{3,}$/;
        const code = newServiceConfig.serviceCode;
        let valid;

        if (code.trim().length === 0) {
            setNewServiceConfig(prevState => ({
                ...prevState,
                serviceCode: ''
            }));
            setInputErrors(prevState => ({
                ...prevState,
                serviceCode: {hasError: true, errorMessage: 'Please provide a Service code'}
            }));
            valid = false;
        }else if (code.length > 50) {
            /**
             * customer_notification repo table's have limit of 50 characters for notification_code in notification_batch table
             * Hence applying this max length check for service code as well.
             */
            setInputErrors(prevState => ({
                ...prevState,
                serviceCode: {hasError: true, errorMessage: 'Max 50 characters allowed in a Service code'}
            }));
            valid = false;
        } else {
            valid = pattern.test(code);
            const err = valid ? '' : 'Invalid Service Code';
            setInputErrors(prevState => ({
                ...prevState,
                serviceCode: {hasError: !valid, errorMessage: err}
            }));
            if (valid) {
                await apiServices.searchServiceCode(code).then(() => {
                    setInputErrors(prevState => ({
                        ...prevState,
                        serviceCode: {hasError: true, errorMessage: 'Service code already exists'}
                    }));
                    valid = false;
                })
                    .catch(() => {
                        /**
                         * Search Services returns error if service does not exists with serviceCode
                         * So set the error to empty string and return valid = true
                         */
                        const err = '';
                        setInputErrors(prevState => ({
                            ...prevState,
                            serviceCode: {hasError: !valid, errorMessage: err}
                        }));
                        valid = true;
                    });
            }
        }
        return valid;
    };

    const validateRequest = async () => {
        let valid = await validateServiceCode();
        if (newServiceConfig.description.trim().length === 0) {
            setNewServiceConfig(prevState => ({
                ...prevState,
                description: ''
            }));
            setInputErrors(prevState => ({
                ...prevState,
                description: {hasError: true, errorMessage: 'Please provide a Service description'}
            }));
            valid = false;
        }
        if (newServiceConfig.consentMode === '') {
            setInputErrors(prevState => ({
                ...prevState,
                consentMode: {hasError: true, errorMessage: 'You must select a consent mode'}
            }));
            valid = false;
        }
        if (newServiceConfig.sendPreferenceChangeEventForAgency) {
            if(newServiceConfig.linkingServiceId.trim() === '') {
                setInputErrors(prevState => ({
                    ...prevState,
                    linkingServiceId: {hasError: true, errorMessage: 'Please provide a linking Service Id'}
                }));
                valid = false;
            }
            if(newServiceConfig.dataExchangeAgencyServiceCode.trim() === '') {
                setInputErrors(prevState => ({
                    ...prevState,
                    dataExchangeAgencyServiceCode: {hasError: true, errorMessage: 'Please provide a DEX Agency Service Code'}
                }));
                valid = false;
            }
            if(newServiceConfig.myaccAgencyStore.trim() === '') {
                setInputErrors(prevState => ({
                    ...prevState,
                    myaccAgencyStore: {hasError: true, errorMessage: 'Please provide a MyAccount Agency Store name'}
                }));
                valid = false;
            }
        }
        if(valid && newServiceConfig.sendPreferenceChangeEventForAgency) {
            setNewServiceConfig(prevState => ({
                ...prevState,
                linkingServiceId: null,
                dataExchangeAgencyServiceCode: null,
                myaccAgencyStore: null
            }));
        }
        return valid;
    };

    const createService = async () => {
        setLoading(true);
        validateRequest().then((valid) => {
            if (valid) {
                apiServices.postServiceConfig(newServiceConfig).then((response) => {
                    if(response.status === 200) {
                        navigate(`/services/${state.agency.agencyCode}`, {
                            state: {
                                alert: {
                                    variant: 'success',
                                    title: 'Done',
                                    description: 'Your service has been created successfully',
                                    visible: true
                                },
                            }
                        });
                    }
                })
                    .catch(e => {
                        setLoading(false);
                        setSuccessStatus({
                            variant: 'error',
                            title: 'Network Error',
                            description: e.message,
                            visible: true
                        });
                    });
            } else {
                setLoading(false);
            }
        });
    };

    return (
        <PortalFormContainer>
            { loading && <ComponentLoader fullPage label='Creating the Service...'/> }
            <Heading
                className='page-title'
                style={ { height: 'fit-content', margin: '6px 0 2rem 0'} }
                level={ 1 }
            >Set up a new service</Heading>
            <Form data-test='new-service-form'>
                <Row>
                    <Col span={ 6 }>
                        <FormInput
                            label='Service Code'
                            name='serviceCode'
                            errorMessage={ inputErrors.serviceCode.errorMessage }
                            helpMessage='Uppercase A-Z only, and underscores (_)'
                            hasError={ inputErrors.serviceCode.hasError }
                            onChange={ handleChange }
                            value={ newServiceConfig.serviceCode }
                        />
                        <FormInput
                            label='Description'
                            name='description'
                            errorMessage={ inputErrors.description.errorMessage }
                            hasError={ inputErrors.description.hasError }
                            margin={ { top: 'lg' } }
                            onChange={ handleChange }
                            value={ newServiceConfig.description }
                        />
                        <FormSelect
                            label='Consent Mode'
                            name='consentMode'
                            errorMessage={ inputErrors.consentMode.errorMessage }
                            hasError={ inputErrors.consentMode.hasError }
                            margin={ { top: 'lg' } }
                            onChange={ handleChange }
                            value={ newServiceConfig.consentMode }
                            options={ consentModeOptions }
                            placeholder='Select'
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={ 6 }>
                        { !['SNSW', 'SNSW_TEST'].includes(state.agency.agencyCode) &&
                            <>
                                <FormInput
                                    label='Linking Service Id'
                                    name='linkingServiceId'
                                    helpMessage='The service id used by service activation (requires onboarding with them)'
                                    errorMessage={ inputErrors.linkingServiceId.errorMessage }
                                    hasError={ inputErrors.linkingServiceId.hasError }
                                    margin={ { top: 'lg' } }
                                    onChange={ handleChange }
                                    value={ newServiceConfig.linkingServiceId }
                                />
                                <FormInput
                                    label='My Account Agency Store'
                                    name='myaccAgencyStore'
                                    helpMessage='Required if DEX is going to opt customer out using /v1/event endpoint'
                                    errorMessage={ inputErrors.myaccAgencyStore.errorMessage }
                                    hasError={ inputErrors.myaccAgencyStore.hasError }
                                    margin={ { top: 'lg' } }
                                    onChange={ handleChange }
                                    value={ newServiceConfig.myaccAgencyStore }
                                    isOptional
                                />
                                <FormRadioGroup
                                    name='sendPreferenceChangeEventForAgency'
                                    legend='Send Preference Change Event For Agency'
                                    vertical={ false }
                                    options={ radioOptions } // eslint-disable-next-line max-len
                                    onChange={ (v) => setNewServiceConfig(prevState => ( {...prevState, sendPreferenceChangeEventForAgency: v ==='true' })) }
                                    value={ newServiceConfig.sendPreferenceChangeEventForAgency }
                                    isOptional
                                    margin={ { top: 'xxxl' } }
                                />
                                { newServiceConfig.sendPreferenceChangeEventForAgency &&
                                    <FormInput
                                        label='Data Exchange Service Code'
                                        name='dataExchangeAgencyServiceCode'
                                        helpMessage='The service code used by data exchange (requires onboarding with them)'
                                        errorMessage={ inputErrors.dataExchangeAgencyServiceCode.errorMessage }
                                        hasError={ inputErrors.dataExchangeAgencyServiceCode.hasError }
                                        margin={ { top: 'lg' } }
                                        onChange={ handleChange }
                                        value={ newServiceConfig.dataExchangeAgencyServiceCode }
                                        isOptional={ newServiceConfig.sendPreferenceChangeEventForAgency }
                                    />
                                }
                            </>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col span={ 6 }>
                        <FormRadioGroup
                            name='showEmail'
                            legend='Show customer email addresses in reporting?'
                            vertical={ false }
                            options={ radioOptions }
                            onChange={ (v) => setNewServiceConfig(prevState => ( {...prevState, showEmail: v === 'true'} )) }
                            value={ newServiceConfig.showEmail }
                            isOptional
                            margin={ { top: 'xxxl' } }
                        />
                    </Col>
                </Row>
                <div style={ { marginTop: '4rem', display: 'flex', gap: '2rem' } }>
                    <Button
                        onClick={ createService }
                        variant='primary'
                        id='createServiceBtn'
                    >Create service</Button>
                    <Button variant='secondary' id='backBtn' onClick={ open }>Back</Button>
                    {showModal && (
                        <Modal
                            title='Are you sure you want to discard your unsaved changes?' // eslint-disable-next-line max-len
                            description='By going back you will loose all the progress on this form and no Service will be created.'
                            buttons={ [
                                { text: 'Discard', onClick: discardChanges },
                                { text: 'Cancel', onClick: () => close() }
                            ] }
                        />
                    )}
                </div>
                {successStatus.visible ? <InPageAlert id='service-status' variant={ successStatus.variant } title={ successStatus.title }>
                    <p data-test='notStatusDesc'>{ successStatus.description }</p>
                </InPageAlert> : null}
            </Form>

        </PortalFormContainer>
    );
};

export default CreateServiceComponent;
