import React, {useState, useEffect} from 'react';
import {
    IphonePreview,
    IphoneScreen,
    NotificationContentContainer,
    PushContent,
    PushIcon, PushNotificationContainer,
    PushTextContainer,
    PushTime,
    PushTitle
} from './PreviewPushContentComponent.styled';

const PreviewPushContentComponent = (props) => {
    const [pushSubject, setPushSubject] = useState('');

    useEffect(() => {
        if (props.pushSubject.length > 30) {
            setPushSubject(props.pushSubject.substring(0, 30) + '...');
        } else {
            setPushSubject(props.pushSubject);
        }
    }, [props.pushSubject]);

    return (
        <>
            <IphonePreview>
                <IphoneScreen>
                    <PushNotificationContainer data-testid={ 'notification-content' }>
                        <NotificationContentContainer>
                            <PushIcon src={ '../../assets/images/snsw_app_icon.png' }/>
                            <PushTextContainer>
                                <PushTime>1h ago</PushTime>
                                <PushTitle>{ pushSubject }</PushTitle>
                                <PushContent>{ props.pushContent }</PushContent>
                            </PushTextContainer>
                        </NotificationContentContainer>
                    </PushNotificationContainer>
                </IphoneScreen>
            </IphonePreview>
        </>
    );
};
export default PreviewPushContentComponent;