import styled from 'styled-components';
import {colors} from '../CommonComponents/Typography/Typography.styled';

export const EmailDetailContainerLabelText = styled.div`
  color: ${colors.grey.darkGrey};
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  margin-bottom: 5px;
`;

export const EmailDetailContainerText = styled.div`
  color: ${colors.grey.darkGrey};
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 22.4px */
  letter-spacing: -0.003px;
`;

export const EmailSubjectSenderContainer = styled.div`
  display: flex;
  padding: 20px 22px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  background: ${colors.white};
  border-bottom: 1px solid #64697499;
`;

export const EmailContentContainer = styled.div`
  border: 1px solid ${colors.grey.geyser};
  background: #F4F4F7;
  box-shadow: 0px 10px 30px 0px rgba(149, 157, 165, 0.20);
  margin-top: 1.5rem;
`;