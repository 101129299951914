import styled from 'styled-components';
import {Accordion, AccordionSummary} from '@mui/material';
import {colors, screenSize} from '../CommonComponents/Typography/Typography.styled';

export const ServiceContainer = styled(Accordion)`
    background: rgb(255, 255, 255);
    height: auto;
    width: 100%;
    margin-bottom: 1rem;
`;

export const ServiceHeading = styled(AccordionSummary)`
    padding: 1rem 1.5rem 1rem 1.5rem;
    margin: 0;
    minHeight: 0;
    :hover {
        background: ${colors.brand.snswSecondaryBlue};
        color: white;
    };
    & .MuiAccordionSummary-expandIconWrapper {
        color: ${colors.brand.snswDarkGrey};
        & .Mui-expanded {
            color: white;
        }
    };
`;

export const ServiceTitleContainer = styled.div`
    display: flex;
    width: 100%;
    padding-right: 1rem;
    align-items: center;
    column-gap: 8px; 
    flex-direction: row;
    justify-content: space-between;
    @media only screen and (max-width: ${screenSize.tablet}) {
        flex-direction: column-reverse;
        row-gap: 16px;
    }
`;
