import styled, {css} from 'styled-components';
import {boxShadowStyle, colors} from '../Typography/Typography.styled';

export const defaultSearchInputWidth = '408px';

export const SearchBarContainer = styled.div`
    display: flex;
`;

export const baseSearchInput = css`
    height: 40px;
    text-indent: 13px;
    font-family: Public Sans, sans-serif;
    outline: none;

    &:focus {
        border: 1.5px solid ${colors.grey.neutral200};
        box-shadow: ${boxShadowStyle};
    }

    border: 1.5px solid ${colors.grey.geyser};
    padding-right: 0;
    font-size: 14px;
    font-weight: 400;
    box-sizing: border-box;
`;

export const SearchInput = styled.input`
    ${baseSearchInput};
    border-radius: 4px 0 0 4px;
    &:focus {
        border-right: none;
    };
    margin-top: 0;
    border-right: none;
`;

export const SearchIconStyled = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 0 4px 4px 0;
    ${props => props.isSearchFocused ?`
            border: 1.5px solid ${colors.grey.neutral200};
            box-shadow: 0px -2px 0px 0px #EAEDF4, 2px 0px 0px 0px #EAEDF4, 0px 2px 0px 0px #EAEDF4; 
        ` : `
            border: 1.5px solid ${colors.grey.geyser};
    `};
    border-left: none;
    background-color: #ffffff;
    cursor: ${props => props.isSearchBarActive ?
        'pointer' :
        'auto'};
    background-image: url(${props => props.isSearchBarActive ?
        '/assets/images/clear-search-cross.svg' :
        '/assets/images/search-icon.svg'});
    background-repeat: no-repeat;
    background-position: center;
    //There is a tiny line between the input and icon if margin is not set to a negative number
    //margin-left: -5px;
`;
