import React from 'react';
import {Input, FormGroup, FormRadioGroup} from '@snsw/react-component-library';
import {ContentSubHeader} from './SingleRecipient.styled';
import PropTypes from 'prop-types';

const SingleRecipient = (props) => {
    const radioOptions = [
        { value: true, label: 'MyAccount Customer' },
        { value: false, label: 'Guest Customer' },
    ];
    const { email: { subject: emailSubject, content: emailContent}, sms: { content: smsContent } } = props.notificationRequest;

    return <div style={ { maxWidth: '75%' } }><p/>
        <ContentSubHeader>
                Enter either a MyAccount customerId or guest contact details
        </ContentSubHeader>

        {((emailContent.trim().length > 0 && emailSubject.trim().length > 0) || smsContent.trim().length > 0) &&
            <FormRadioGroup
                name='customerType'
                legend='What type of customer are you sending to?'
                vertical={ false }
                options={ radioOptions }
                onChange={ props.changeCustomerType }
                value={ props.myAccountCustomer }
            />
        }
        { props.myAccountCustomer &&
            <FormGroup
                label='Customer Id'
                helpMessage='Enter the internal customerId or Xref'
                id='customer-id'
                hasError={ props.inputErrors.customerId.hasError }
                errorMessage={ props.inputErrors.customerId.errorMessage }
            >
                <Input
                    name='customerId'
                    data-testid='customer-id-input'
                    value={ props.notificationRequest.customerId }
                    onChange={ props.recipientCallback }
                    inputWidth={ 'xl' }
                />
            </FormGroup>
        }
        {(emailContent.trim().length > 0 && emailSubject.trim().length > 0 && !props.myAccountCustomer) &&
            <FormGroup
                label='Email Address'
                helpMessage='Enter a guest customer email address'
                id='email-address'
                hasError={ props.inputErrors.emailRecipient.hasError }
                errorMessage={ props.inputErrors.emailRecipient.errorMessage }
            >
                <Input
                    name='email'
                    data-testid='email-address-input'
                    value={ props.notificationRequest.email?.to }
                    onChange={ props.guestRecipientCallback }
                    inputWidth={ 'xl' }
                />
            </FormGroup>
        }
        {(smsContent.trim().length > 0 && !props.myAccountCustomer) &&
            <FormGroup
                label='Mobile Number'
                helpMessage='Enter a guest customer Australian mobile number'
                id='mobile-number'
                hasError={ props.inputErrors.smsRecipient.hasError }
                errorMessage={ props.inputErrors.smsRecipient.errorMessage }
            >
                <Input
                    name='sms'
                    data-testid='mobile-number-input'
                    value={ props.notificationRequest.sms?.to }
                    onChange={ props.guestRecipientCallback }
                    inputWidth={ 'xl' }
                />
            </FormGroup>
        }
    </div>;
};
SingleRecipient.propTypes = {
    notificationRequest: PropTypes.object,
    recipientCallback: PropTypes.func,
    guestRecipientCallback: PropTypes.func,
    inputErrors: PropTypes.object,
    myAccountCustomer: PropTypes.bool,
    changeCustomerType: PropTypes.func,
};
export default SingleRecipient;