import React, {useEffect, useRef, useState} from 'react';
import Button from '@snsw/react-component-library/build/Components/Button/Button';
import apiServices from '../../services/api-services';
import Row from '@snsw/react-component-library/build/layout/Grid/Row.styled';
import Col from '@snsw/react-component-library/build/layout/Grid/Col.styled';
import {FormButtonContainer} from '@snsw/react-component-library/build/Components/Form/Form.styled';
import InPageAlert from '@snsw/react-component-library/build/Components/InPageAlert/InPageAlert';
import Form from '@snsw/react-component-library/build/Components/Form/Form';
import {Link, useParams} from 'react-router-dom';
import { useNavigate } from 'react-router';
import {Modal} from '@snsw/react-component-library';
import {useBoolean} from '@snsw/react-component-library/build/utils';
import {scrollToTop, useGlobalState, useUserRoleState} from '../GlobalStateComponent/GlobalState';
import ComponentLoader from '@snsw/react-component-library/build/Loader/ComponentLoader';
import MultiSelectComponent from '../CommonComponents/SelectComponent/MultiSelectComponent';
import {
    BackButtonContainer,
    PortalFormContainer, PortalFormInput,
    PortalH1Heading, PortalFormSelect
} from '../CommonComponents/CustomComponents/CustomComponents.styled';

const UpdateUserComponent = () => {
    const {userRole} = useUserRoleState();

    const { userId } = useParams();

    const [userRoleOptions, setUserRoleOptions] = useState(
        [{value: '', text: '', name: '', description: '', permissions: {}}]);
    const [teamOptions, setTeamOptions] = useState([]);
    const [initialTeamSelection, setInitialTeamSelection] = useState([]);

    const defaultUserConfig = {
        'email': '',
        'userRole': '',
        'teamName': '',
        'fullName': '',
        'userRoleId' :'',
        'userTeams': []
    };
    const dbUserConfig = useRef();
    const navigate = useNavigate();
    const [isUserConfigUpdated, setIsUserConfigUpdated] = useState(false);
    const [initialUserConfig, setInitialUserConfig] = useState({});
    const [showModal, open, close] = useBoolean(false);
    const [userConfig, setUserConfig] = useState( defaultUserConfig);
    const [inputErrors, setInputErrors] = useState({
        email: {hasError: false, errorMessage: 'Enter an email address'},
        userRole: {hasError: false, errorMessage: 'Select user role'},
        teamName: {hasError: false, errorMessage: 'Enter team name'},
        userTeams: {hasError: false, errorMessage: 'Please select one or more teams'}
    });
    const [successStatus, setSuccessStatus] = useState({
        variant: 'success',
        title: 'Done',
        description: 'User details updated successfully',
        visible: false
    });
    const [inProd] = useGlobalState();
    const [loading, setLoading] = useState(false);
    const [showRemoveModal, openRemoveModelOpen, closeRemoveModel] = useBoolean(false);

    useEffect(() => {
        async function fetchData() {
            scrollToTop();

            try {
                const userRoleList = await apiServices.fetchUserRoleList();
                const userRoleData = [{ value: '0', label: 'ALL', agencyCode: 'ALL' }];
                userRoleList.forEach(userRole => {
                    userRoleData.push({
                        value: userRole.id.toString(),
                        text: userRole.description,
                        name: userRole.name,
                        permissions: {}
                    });
                });
                setUserRoleOptions(userRoleData);

                const teamOptions = [];
                await apiServices.fetchTeams().then(data => {
                    data.forEach(team => {
                        teamOptions.push({value: team.id, label: team.name, teamConfig: team});
                    });
                });
                setTeamOptions(teamOptions);

                const userData = await apiServices.fetchUserById(userId);
                setInitialUserConfig({
                    id: userData.id,
                    email: userData.email,
                    userRole: userData.userRoles,
                    fullName: userData.fullName ? userData.fullName : '',
                    teamName: userData.teamName ? userData.teamName : '',
                    userRoleId: userData.userRoles[0].id.toString(),
                    userTeams: userData.userTeams
                });
                setUserConfig({
                    id: userData.id,
                    email: userData.email,
                    userRole: userData.userRoles,
                    fullName: userData.fullName ? userData.fullName : '',
                    teamName: userData.teamName ? userData.teamName : '',
                    userRoleId: userData.userRoles[0].id.toString(),
                    userTeams: userData.userTeams
                });
                dbUserConfig.current = userData;

                // Generate filteredOptions after all API calls are resolved

                // Set initial value for team selection
                if (userData.userTeams.length > 0) {
                    const initialTeamIdList = userData.userTeams.map(team => team.id);
                    const filteredInitialTeams = teamOptions.filter(team => initialTeamIdList.includes(team.value));
                    setInitialTeamSelection(filteredInitialTeams);
                }

            } catch (error) {
                setSuccessStatus({
                    variant: 'error',
                    title: 'Network Error',
                    description: error.message,
                    visible: true
                });
            }
        }

        fetchData();
    }, [userId, inProd]);
    useEffect(() => {
        if (JSON.stringify(initialUserConfig) !== JSON.stringify(userConfig)) {
            setIsUserConfigUpdated(true);
        } else {
            setIsUserConfigUpdated(false);
        } // eslint-disable-next-line
    }, [userConfig]);

    const handleChange = event => {
        setSuccessStatus(prevState => ({
            ...prevState,
            visible: false
        }));
        const {name, value} = event.target;
        setUserConfig(prevState => ({
            ...prevState,
            [name]: value
        }));
        if (name === 'userRoleId') {
            const selectedUserRole = userRoleOptions.find(option => option.value === value);
            setUserConfig(prevState => ({
                ...prevState,
                userRoleId: value,
                userRole: [
                    {
                        id: Number(selectedUserRole.value),
                        name: selectedUserRole.name,
                        description: selectedUserRole.text,
                        permissions: selectedUserRole.permissions
                    }
                ]
            }));
        }
        setInputErrors(prevState => ({
            ...prevState,
            [name]: {hasError: false, errorMessage: 'Error'},
        }));
    };

    const validateRequest = async () => {
        const emailIsValid = await validateEmail(userConfig.email);
        const userTeams = await validateUserTeams(userConfig.userTeams);
        return emailIsValid && userTeams;
    };

    const validateEmail = async (email) =>{
        if (email.length === 0) {
            setInputErrors(prevState => ({
                ...prevState,
                email: {hasError: true, errorMessage: 'Enter an email address'}
            }));
            return false;
        }
        if (email.length > 50) {
            setInputErrors(prevState => ({
                ...prevState,
                email: {hasError: true, errorMessage: 'Max 50 characters allowed for Email Address'}
            }));
            return false;
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const val = emailRegex.test(email);
        if (!val) {
            setInputErrors(prevState => ({
                ...prevState,
                email: {hasError: true, errorMessage: 'Invalid email address'}
            }));
            return false;
        }
        //only if email has changed then check if new email exists
        if (userConfig.email !== initialUserConfig.email) {
            try {
                const user = await apiServices.fetchUserByEmail(email.trim());
                if (user.email) {
                    setInputErrors(prevState => ({
                        ...prevState,
                        email: {hasError: true, errorMessage: 'User with this email address already exists'}
                    }));
                    return false;
                }
            } catch (error) {
            }
        }
        return true;
    };

    const validateUserTeams = async (teams) => {
        if (teams.length === 0) {
            setInputErrors(prevState => ({
                ...prevState,
                userTeams: {hasError: true, errorMessage: 'Please select one or more teams'}
            }));
        }
        return teams.length > 0;
    };

    const handleTeamChange = (selectedOptions) => {
        setInputErrors(prevState => ({
            ...prevState,
            userTeams: {hasError: false, errorMessage: ''},
        }));
        const teams = selectedOptions.map((option) => option.teamConfig);
        setUserConfig(prevState => ({
            ...prevState,
            userTeams: teams
        }));
    };

    const updateUser = async () => {
        setLoading(true);
        validateRequest().then((valid) => {
            if (valid) {
                dbUserConfig.current.email = userConfig.email;
                dbUserConfig.current.userRoles = userConfig.userRole;
                dbUserConfig.current.dataAccessGroups = userConfig.dataAccessGroups;
                dbUserConfig.current.fullName = userConfig.fullName;
                dbUserConfig.current.teamName = userConfig.teamName;
                dbUserConfig.current.userTeams = userConfig.userTeams;
                apiServices.updateUser(dbUserConfig.current).then((response) => {
                    if(response.status === 200){
                        navigate('/admin', {
                            state: {
                                variant: 'success',
                                title: 'Done',
                                description: 'User details updated',
                                visible: true
                            }
                        });
                    }
                })
                    .catch(e => {
                        setLoading(false);
                        setSuccessStatus({
                            variant: 'error',
                            title: 'Network Error',
                            description: e.message,
                            visible: true
                        });
                    });
            } else {
                setLoading(false);
            }
        });
    };

    const discardChanges = () => {
        navigate('/admin');
    };
    const onRemoveModelBackClicked = (event) => {
        event.stopPropagation();
        closeRemoveModel();
    };
    const onRemoveUser = (event) => {
        setLoading({isLoading: true, message: 'Deleting the User...'});
        event.stopPropagation();
        closeRemoveModel();
        apiServices.deleteUserById(userConfig.id).then( () => {
            setLoading({isLoading: false, message: ''});
            navigate('/admin', {
                state: {
                    variant: 'success',
                    title: 'Done',
                    description: 'User deleted',
                    visible: true
                }
            });
        })
            .catch(() => {
                setLoading({isLoading: false, message: ''});
                setSuccessStatus({
                    variant: 'error',
                    title: 'Network Error',
                    description: `Error deleting user ${userConfig.fullName}`,
                    visible: true
                });
            });
    };

    const removalModelDescription = `User '${userConfig.fullName}' will be permanently deleted. `+
        'Do you want to proceed.';
    const removalModelButtons=[
        { text: 'Delete', onClick: onRemoveUser },
        { text: 'Back', onClick: onRemoveModelBackClicked }
    ];

    return (
        <PortalFormContainer>
            <BackButtonContainer>
                <Button variant='back' as={ Link } href='/admin'>Back</Button >
            </BackButtonContainer>
            { loading && <ComponentLoader fullPage label='Updating the User...'/> }
            <PortalH1Heading
                level={ 1 }
                id='UserListHeading'
            >
                Edit User
            </PortalH1Heading>

            <Form data-test='edit-user-form'>

                <Row>
                    <Col span={ 6 }>
                        <PortalFormInput
                            label='Name'
                            disabled='true'
                            name='fullName'
                            helpMessage=''
                            onChange={ handleChange }
                            value={ userConfig.fullName }
                        />
                        <PortalFormInput
                            label='Email address'
                            name='email'
                            errorMessage={ inputErrors.email.errorMessage }
                            helpMessage=''
                            hasError={ inputErrors.email.hasError }
                            onChange={ handleChange }
                            value={ userConfig.email }
                        />
                        <PortalFormSelect
                            label='User role'
                            name='userRoleId'
                            errorMessage={ inputErrors.userRole.errorMessage }
                            hasError={ inputErrors.userRole.hasError }
                            options={ userRoleOptions }
                            onChange={ handleChange }
                            value={ userConfig.userRoleId }
                        />
                        <MultiSelectComponent
                            name='team'
                            placeholder='Select team'
                            errorMessage={ inputErrors.userTeams.errorMessage }
                            hasError={ inputErrors.userTeams.hasError }
                            label='Assigned team(s)'
                            initialValue={ initialTeamSelection }
                            options={ teamOptions }
                            onOptionChange={ handleTeamChange }
                        />
                    </Col>
                </Row>
                <FormButtonContainer style={
                    {
                        display: 'flex',
                        flexWrap: 'nowrap',
                        justifyContent: 'start',
                        rowGap: '32px',
                        marginTop: '32px'

                    }
                }>
                    <>
                        { userRole.permissions.USER.includes('UPDATE') &&
                            <Button
                                onClick={ updateUser }
                                disabled={ !isUserConfigUpdated }
                                variant='primary'
                                id='updateUserBtn'>
                                Update
                            </Button>
                        }
                        <span style={ {width: '1rem'} }/>
                    </>

                    { !isUserConfigUpdated ?
                        <Button variant='secondary' id='backBtn' onClick={ discardChanges }>Cancel</Button> :
                        <>
                            <Button variant='secondary' id='backBtn' onClick={ open }>Cancel</Button>
                            {showModal && (
                                <Modal
                                    title='Discard changes'
                                    description='Are you sure you want to discard your changes?'
                                    buttons={ [
                                        { text: 'Discard changes', onClick: discardChanges },
                                        { text: 'Cancel', onClick: () => close() }
                                    ] }
                                />
                            )}
                        </>
                    }

                    { userRole.permissions.USER.includes('DELETE') &&
                        <Button id= { `removeUserSettingsBtn-${userConfig.id}` }
                            data-testid={ `removeUserSettingsBtn-${userConfig.id}` }
                            variant='link'
                            onClick={ openRemoveModelOpen }
                            style={ { marginLeft: '1.75rem' } }
                        >
                            Delete user
                        </Button>
                    }

                </FormButtonContainer>
                {successStatus.visible ? <InPageAlert id='agency-status' variant={ successStatus.variant } title={ successStatus.title }>
                    <p data-test='notStatusDesc'>{ successStatus.description }</p>
                </InPageAlert> : null}
                {showRemoveModal && (
                    <Modal
                        title='Delete user'
                        description= { removalModelDescription }
                        buttons={ removalModelButtons }
                    />
                )}
            </Form>
        </PortalFormContainer>
    );
};

export default UpdateUserComponent;
