import styled from 'styled-components';
import {
    colors, fontSize, fontWeight,
    globalFont,
    Heading1, lineHeight,
    screenSize
} from '../../CommonComponents/Typography/Typography.styled';

const adjustableSize = screenSize.tablet;

export const ComponentWrapper = styled.div`
    padding: 145px 0px;
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 80px 62px;
    font-family: ${globalFont};

    @media only screen and (max-width: ${adjustableSize}) {
        padding: 56px 0px;
        grid-template-columns: 1fr;
        gap: 24px 0px;
    }
`;

export const HeadingWrapper = styled.div`
    grid-column: 1 / span 4;
    width: 100%;
    
    @media only screen and (max-width: ${adjustableSize}) {
        grid-column: 1 / span 1;
    }
`;
export const Heading = styled(Heading1)`
    font-size: 48px;
    line-height: 60px;
    color: ${colors.grey.darkGrey};

    @media only screen and (max-width: ${adjustableSize}) {
        font-size: 28px;
        line-height: 36px;
    }
`;

export const ElementWrapper = styled.div`
    display: flex;
    flex-direction: column;
    
    > div:nth-child(2) {
        font-weight: ${fontWeight.bold};
        font-size: ${fontSize.h4};
        line-height: ${lineHeight.h4};
    }
`;

export const IconWrapper = styled.div`
    padding-bottom: 30px;
`;

export const CardParagraph = styled.p`
    font-size: ${fontSize.h6};
    line-height: ${lineHeight.h6};
`;