export const alertTimeoutValues = {
    'error': 10000,
    'warning': 20000,
    'success': 30000,
    'info': 10000,
};

export const validEmail = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');

export const validMobile = new RegExp('^\\+61\\d{9}$');

export const validInternalCustomerID = new RegExp('^[0-9a-f]{8}-[0-9a-f]{8}-[0-9a-f]{8}-[0-9a-f]{8}$');

export const environments = Object.freeze({
    SRC: 'SRC',
    DST: 'DST'
});

export const portalConfigStatus = Object.freeze({
    IN_REVIEW: 'IN_REVIEW',
    LIVE: 'LIVE',
    ARCHIVED: 'ARCHIVED',
    SENT: 'SENT'
});

export const preferenceAlertTypes = Object.freeze({
    USER_ACTION: 'USER_ACTION',
    INELIGIBLE: 'INELIGIBLE',
    DUPLICATE_ACCOUNT: 'DUPLICATE_ACCOUNT',
    OPT_IN: 'OPT_IN',
    OPT_OUT: 'OPT_OUT',
});

export const alertTypeToErrorName = (alertType) => {
    switch(alertType) {
    case preferenceAlertTypes.OPT_OUT:
        return 'optOutAlert';
    case preferenceAlertTypes.OPT_IN:
        return 'optInAlert';
    case preferenceAlertTypes.DUPLICATE_ACCOUNT:
        return 'duplicateAccountAlert';
    case preferenceAlertTypes.INELIGIBLE:
        return 'ineligibleAlert';
    case preferenceAlertTypes.USER_ACTION:
        return 'linkActionAlert';
    default:
        return '';
    }
};

export const notificationChannel = Object.freeze({
    INBOX: 'INBOX',
    EMAIL: 'EMAIL',
    SMS: 'SMS',
    PUSH: 'PUSH'
});

export const baseEmailTemplate = '<!doctype html>\n' +
    '<html>\n' +
    '\n' +
    '<head>\n' +
    '    <meta charset="UTF-8">\n' +
    '    <meta name="viewport" content="width=device-width,initial-scale=1">\n' +
    '    <!--    <meta http-equiv="Content-Type" content="text/html charset=UTF-8"/>-->\n' +
    '\n' +
    '    <title>Service NSW</title>\n' +
    '    <style>\n' +
    '        /* Email reset */\n' +
    '        /* Remove space around the email design. */\n' +
    '        html,\n' +
    '        body {\n' +
    '            margin: 0 auto !important;\n' +
    '            padding: 0 !important;\n' +
    '            height: 100% !important;\n' +
    '            width: 100% !important;\n' +
    '\n' +
    '        }\n' +
    '\n' +
    '        /* Stop Outlook resizing small text. */\n' +
    '        * {\n' +
    '            -ms-text-size-adjust: 100%;\n' +
    '        }\n' +
    '\n' +
    '        /* Stop Outlook from adding extra spacing to tables. */\n' +
    '        table,\n' +
    '        td {\n' +
    '            mso-table-lspace: 0pt !important;\n' +
    '            mso-table-rspace: 0pt !important;\n' +
    '        }\n' +
    '\n' +
    '        /* Use a better rendering method when resizing images in Outlook IE. */\n' +
    '        img {\n' +
    '            -ms-interpolation-mode: bicubic;\n' +
    '        }\n' +
    '\n' +
    '        /* Prevent Windows 10 Mail from underlining links. Styles for underlined links should be inline. */\n' +
    '        a {\n' +
    '            text-decoration: none;\n' +
    '        }\n' +
    '\n' +
    '        @font-face {\n' +
    '            font-family: "Gotham A";\n' +
    '            src: url("https://fonts.service.nsw.gov.au/Gotham-Medium_Web.woff2") format("woff2");\n' +
    '            font-weight: bold;\n' +
    '        }\n' +
    '\n' +
    '    </style>\n' +
    '</head>\n' +
    '\n' +
    '<body width=”100%”\n' +
    '      style="margin:0px; padding:0px !important; mso-line-height-rule: exactly; background-color:#f4f4f7; alignment: center">\n' +
    '<table border="0" cellpadding="0" cellspacing="0" width="100%" bgcolor="#F4F4F7" class="wrapper">\n' +
    '    <!-- Top Nav START -->\n' +
    '    <tr height="40px">\n' +
    '        <td></td>\n' +
    '    </tr>\n' +
    '    <tr>\n' +
    '        <td align="center" height="100%" valign="top" width="100%" bgcolor="#242934">\n' +
    '            <!--[if (gte mso 9)|(IE)]>\n' +
    '            <table align="center" border="0" cellspacing="0" cellpadding="0" width="600">\n' +
    '                <tr>\n' +
    '                    <td align="center" valign="top" width="600">\n' +
    '            <![endif]-->\n' +
    '\n' +
    '            <!--[if (gte mso 9)|(IE)]>\n' +
    '            </td>\n' +
    '            </tr>\n' +
    '            </table>\n' +
    '            <![endif]-->\n' +
    '        </td>\n' +
    '    </tr>\n' +
    '    <!-- Top Nav END -->\n' +
    '    <!-- Main Nav START -->\n' +
    '    <tr>\n' +
    '        <td align="center" valign="top" width="100%">\n' +
    '\n' +
    '            <!--[if (gte mso 9)|(IE)]>\n' +
    '            <table align="center" border="0" cellspacing="0" cellpadding="0" width="600">\n' +
    '                <tr>\n' +
    '                    <td align="center" valign="top" width="600">\n' +
    '            <![endif]-->\n' +
    '            <table role="presentation" align="center" border="0" cellpadding="0" cellspacing="0" width="100%"\n' +
    '                   style="max-width:600px;" bgcolor="#FFFFFF">\n' +
    '                <tr>\n' +
    '                    <td align="center" height="100%" valign="top" width="100%">\n' +
    '                        <!--[if (gte mso 9)|(IE)]>\n' +
    '                        <table align="center" border="0" cellspacing="0" cellpadding="0" width="600">\n' +
    '                            <tr>\n' +
    '                                <td align="center" valign="top" width="600">\n' +
    '                        <![endif]-->\n' +
    '                        <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%"\n' +
    '                               style="max-width:600px;" bgcolor="#F4F4F7">\n' +
    '                            <!--START NEW HEADER section-->\n' +
    '                            {{{template.header}}}\n' +
    '                            <!--CLOSE NEW HEADER section-->\n' +
    '\n' +
    '                            <!--START NEW CONTENT section-->\n' +
    '\n' +
    '                            <tr>\n' +
    '                                <td align="left" valign="top" style="font-family: Arial, Helvetica, sans-serif; ' +
    'font-size: 16px; padding: 20px;color: #242934; background-color:#FFFFFF;">\n' +
    '                                    <table>\n' +
    '                                        {{{template.mainContent}}}\n' +
    '                                    </table>\n' +
    '                                </td>\n' +
    '                            </tr>\n' +
    '\n' +
    '                            <!--CLOSE NEW CONTENT section-->\n' +
    '\n' +
    '\n' +
    '                            <!--Start FEEDBACK section-->\n' +
    '                            {{{template.feedback}}}\n' +
    '                            <!--CLOSE FEEDBACK section-->\n' +
    '\n' +
    '                            <!--Start FOOTER section-->\n' +
    '                            {{{template.footer}}}\n' +
    '                            <!--CLOSE FOOTER section-->\n' +
    '                        </table>\n' +
    '                        <!--[if (gte mso 9)|(IE)]>\n' +
    '                        </td>\n' +
    '                        </tr>\n' +
    '                        <![endif]-->\n' +
    '                    </td>\n' +
    '                </tr>\n' +
    '                <!-- ONE END -->\n' +
    '            </table>\n' +
    '</table>\n' +
    '</body>\n' +
    '\n' +
    '</html>\n';

export const inboxBaseContentUpper =
    '<html>\n' +
    '<head>\n' +
    '    <meta name="viewport" content="width=device-width, initial-scale=1.0">\n' +
    '</head>\n' +
    '<body>\n' +
    '<div class="drn-container" style="height: 100%;display: flex;flex-direction: column;">\n' +
    '    <div class="details-section section"\n' +
    '         style="padding-top: 1.5rem;flex-shrink: 0;padding: 0.9375rem 0;display: flex;flex-direction: column;">';
export const inboxBaseContentLower =
    '    </div>\n' +
    '</div>\n' +
    '    <style>\n' +
    '        :root {\n' +
    '            --snsw-primary-blue: #002664;\n' +
    '            --snsw-primary-red: #D7153A;\n' +
    '            --snsw-secondary-blue: #2E5299;\n' +
    '            --snsw-dark-grey: #242934;\n' +
    '            --black: #121212;\n' +
    '            --white: #FFFFFF;\n' +
    '            --shuttle: #646974;\n' +
    '            --geyser: #DEE3E5;\n' +
    '            --mystic: #ECF1F3;\n' +
    '            --ice-blue: #F4F7F9;\n' +
    '            --info-blue: #2E5299;\n' +
    '            --info-blue-light: #EAEDF4;\n' +
    '            --success-green: #00A908;\n' +
    '            --success-green-light: #E5F6E6;\n' +
    '            --error-red: #B81237;\n' +
    '            --error-red-light: #F4DBE1;\n' +
    '            --warning-orange: #DC5800;\n' +
    '            --warning-orange-light: #FCEFE6;\n' +
    '        }\n' +
    '    </style>\n' +
    '</body>\n' +
    '</html>';