import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {
    TableRow,
    InPageAlert,
    Table,
    TableHead,
    TableBody,
    TableHeader,
    TableCell,
    Button,
    Heading,
    SkeletonHeading,
    SkeletonContainer,
    Modal,
} from '@snsw/react-component-library';
import {scrollToTop, useGlobalState} from '../GlobalStateComponent/GlobalState';
import {useBoolean} from '@snsw/react-component-library/build/utils';
import FormInput from '@snsw/react-component-library/build/Components/Form/FormInput';
import apiServices from '../../services/api-services';
import StatusLabel from '@snsw/react-component-library/build/Components/StatusLabel/StatusLabel';
import {preferenceAlertTypes} from '../../constants/applicationConstants';
import {TableContainer} from '../TableContainerComponent/TableContainer';
import { IconAdd } from '@snsw/react-component-library/build/Icons/system';
import {CustomTableContainer} from '../CommonComponents/CustomComponents/CustomComponents.styled';

const PreferenceTileListComponent = () => {
    const { state } = useLocation();
    const [services, setServices] = useState([]);
    const [pendingChanges, setPendingChanges] = useState([]);
    const [showAlert] = useState(true);
    const [inProd] = useGlobalState();
    const [showModal, open, close] = useBoolean(false);
    const [promoteService, setPromoteService] = useState(null);

    useEffect(() => {
        scrollToTop();
        apiServices.getAllPreferencePageServices(inProd)
            .then(response => {
                setServices(response);
                response.forEach(service => {
                    apiServices.getPendingPreferenceConfig(service.serviceCode)
                        .then(response => {
                            setPendingChanges( prevState => [...prevState, response]);
                        })
                        .catch(error => console.log(error.message));
                });
            })
            .catch(error => console.log(error.message));
    }, [inProd]);

    const openPromoteModal = async (serviceCode) => {
        await apiServices.getPreferenceConfig(serviceCode).then(config => {
            setPromoteService({
                ...config,
                learnMoreLinkHref: '',
                preferenceAlerts: [...config.preferenceAlerts.map((alert) =>
                    alert.type === preferenceAlertTypes.USER_ACTION ? { ...alert, actionLinkHref: '' } : { ...alert }
                )]
            });
        })
            .catch(error => console.log(error.message));
        open();
    };

    const promoteToProd = () => {
        const serviceCode = services.find(service => service.id === promoteService.serviceId)?.serviceCode;
        const {learnMoreLinkHref, learnMoreLinkText, ...serviceToPromote} = promoteService;
        apiServices.promotePreferenceConfig(serviceCode, serviceToPromote)
            .then(() => {
                close();
                setPromoteService({});
                services.forEach(service => {
                    apiServices.getPendingPreferenceConfig(service.serviceCode)
                        .then(response => {
                            setPendingChanges(prevState => (
                                prevState.map(service => service.serviceCode === response.serviceCode ? response : service)
                            ));
                        });
                });
            })
            .catch(error => {
                console.log(error);
            });
    };

    return (
        <CustomTableContainer role='main'>
            <Button variant='back' as={ Link } href='/'>Back</Button >
            <Heading
                level={ 1 }
                id='AgencyListHeading'
                style={ { height: 'fit-content', margin: '6px 0 0.5rem 0'} }
            >
                Services Preference Tiles
            </Heading>
            {state && state.visible && showAlert ? <InPageAlert id='preference-tiles-status' variant={ state.variant } title={ state.title }>
                <p id='notStatusDesc'>{ state.description }</p>
            </InPageAlert> : null}
            <>
                {services.length === 0 ?
                    <SkeletonContainer>
                        <TableContainer title='' id='service-preference-table-loading'>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableHeader>Service Code</TableHeader>
                                        <TableHeader>Service Description</TableHeader>
                                        <TableHeader> </TableHeader>
                                        <TableHeader> </TableHeader>
                                    </TableRow>
                                </TableHead>
                                <TableBody style={ {background:'#ffffff'} }>
                                    <TableRow>
                                        <TableCell><SkeletonHeading level={ 5 } width={ 100 } noMargin/></TableCell>
                                        <TableCell><SkeletonHeading level={ 5 } width={ 100 } noMargin/></TableCell>
                                        <TableCell><SkeletonHeading level={ 5 } width={ 100 } noMargin/></TableCell>
                                        <TableCell><SkeletonHeading level={ 5 } width={ 100 } noMargin/></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><SkeletonHeading level={ 5 } width={ 100 } noMargin/></TableCell>
                                        <TableCell><SkeletonHeading level={ 5 } width={ 100 } noMargin/></TableCell>
                                        <TableCell><SkeletonHeading level={ 5 } width={ 100 } noMargin/></TableCell>
                                        <TableCell><SkeletonHeading level={ 5 } width={ 100 } noMargin/></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><SkeletonHeading level={ 5 } width={ 100 } noMargin/></TableCell>
                                        <TableCell><SkeletonHeading level={ 5 } width={ 100 } noMargin/></TableCell>
                                        <TableCell><SkeletonHeading level={ 5 } width={ 100 } noMargin/></TableCell>
                                        <TableCell><SkeletonHeading level={ 5 } width={ 100 } noMargin/></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </SkeletonContainer> :
                    <TableContainer title='' id='service-preference-table'>
                        <Table striped>
                            <TableHead>
                                <TableRow>
                                    <TableHeader>Service Code</TableHeader>
                                    <TableHeader>Service Description</TableHeader>
                                    <TableHeader>Action</TableHeader>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { services.map((service, index) => {
                                    return (
                                        <TableRow key={ index }>
                                            <TableCell
                                                key={ index + '.1' }
                                                style={ { display: 'flex', alignItems: 'center', gap: '1rem', margin: 10 } }
                                            >
                                                { service.serviceCode }
                                                {(!inProd && pendingChanges
                                                    .find(change => change.serviceCode === service.serviceCode)?.pendingChanges) &&
                                                    <StatusLabel text= { 'PENDING' } variant= { 'warning' } />
                                                }
                                            </TableCell>
                                            <TableCell key={ index + '.2' }>{ service.description }</TableCell>
                                            <TableCell key={ index + '.3' }>
                                                <div style={ {display: 'flex', alignItems: 'center',
                                                    justifyContent: 'flex-start', gap: '1rem'} }>
                                                    <Link to={ `/preference-tiles/${service.serviceCode}` }>
                                                        Open
                                                    </Link>
                                                    {(!inProd && pendingChanges
                                                        .find(change => change.serviceCode === service.serviceCode)?.pendingChanges) &&
                                                    <Button onClick={ () => openPromoteModal(service.serviceCode) } variant='primary'>
                                                        Push to Prod
                                                    </Button>
                                                    }
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ); }) }
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
            </>
            { !inProd && <Button as={ Link } href='/preference-tiles/add-service-tile' style={ {textDecoration: 'none'} }
            >Add service tile
                <IconAdd color='white' style={ { marginLeft:'9px', width:'14px', height:'14px' } }/>
            </Button> }
            {showModal && (
                <Modal
                    title='Promote to Production'
                    description='Are you sure you would like to promote the changes to production?
                    Before you can do so you will need to specify the production urls for any links.'
                    buttons={ [
                        { text: 'Promote', id:'promoteChangesToProdBtn', onClick: () => promoteToProd() },
                        { text: 'Back', id:'promoteChangesBackBtn', onClick: () => close() }
                    ] }
                >
                    <div style={ { marginBottom: 20 } }>
                        {promoteService?.learnMoreLinkHref != null &&
                            <FormInput
                                id='learnMoreLinkHref-input'
                                name='learnMoreLinkHref'
                                label='Learn more link href'
                                helpMessage='Enter the Learn more link href that you wish to use in production'
                                onChange={ e => {
                                    const val = e.target.value;
                                    setPromoteService(prevState => ({...prevState, learnMoreLinkHref: val}));
                                } }
                                value={ promoteService.learnMoreLinkHref }
                            />
                        }
                        {promoteService?.preferenceAlerts
                            .find(alert => alert.type === preferenceAlertTypes.USER_ACTION)?.actionLinkHref != null
                            &&
                            <FormInput
                                id='action_link_href-input'
                                name='actionLinkHref'
                                label='Action Link href'
                                helpMessage='Enter the Action link href that you wish to use in production'
                                onChange={ (e) => {
                                    const val = e.target.value;
                                    setPromoteService(prevState => ({
                                        ...prevState,
                                        preferenceAlerts: [...prevState.preferenceAlerts.map((alert) =>
                                            alert.type === preferenceAlertTypes.USER_ACTION
                                                ? { ...alert, actionLinkHref: val }
                                                : { ...alert }
                                        )]
                                    }));
                                } }
                            />
                        }
                    </div>
                </Modal>
            )}
        </CustomTableContainer>
    );
};

export default PreferenceTileListComponent;
