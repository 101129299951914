import React from 'react';
import AnchorMenuComponent from '../CommonComponents/AnchorMenuComponent/AnchorMenuComponent';
import AnchorMenuLayoutComponent from '../CommonComponents/AnchorMenuLayoutComponent/AnchorMenuLayoutComponent';
import ContentGuidanceEmailContentComponent from './ContentGuidanceEmailContentComponent';

const ContentGuidanceEmailComponent = () => {

    const anchorMenuMetaData = {
        title: 'On this page',
        data: [
            {id: 'menu-emails', title: 'Emails', level: 0},
            {id: 'menu-overview', title: 'Overview', level: 1},
            {id: 'menu-elements-and-char-limits', title: 'Elements and character limits', level: 1},
            {id: 'menu-writing-guidance', title: 'Writing guidance', level: 1},
            {id: 'menu-dos-donts', title: 'Dos and don\'ts', level: 1}
        ]
    };

    return (
        <AnchorMenuLayoutComponent
            title='Emails'
            contentComponent={ ContentGuidanceEmailContentComponent }
            anchorMenuComponent={ AnchorMenuComponent }
            anchorMenuMetaData={ anchorMenuMetaData }
        />
    );
};

export default ContentGuidanceEmailComponent;
