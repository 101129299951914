import React from 'react';
import {useLocation} from 'react-router-dom';

import {
    InPageAlert,
    Button, ContentContainer,
} from '@snsw/react-component-library';
import {PortalInfo} from '../LandingPage/LandingPage';

const AccessRequestComponent = () => {
    const { state } = useLocation();

    return (
        <ContentContainer role='main'>
            <PortalInfo/>
            {state && state.visible ?
                <>
                    <div style={ { marginTop: '2rem'} }>
                        <InPageAlert data-testid='access-status-warning'
                            id='access-status' variant={ state.variant } title={ state.title }>
                            <p id='notStatusDesc'>{ state.description }</p>
                        </InPageAlert>
                        {<Button style={ { marginTop: '2rem', textDecoration: 'none'} } data-testid='request-access-btn'
                            href={ process.env.REACT_APP_SERVICENOW_URL }
                            target='_blank'>Request Portal Access</Button>}
                    </div>
                </>
                : null}
        </ContentContainer>
    );
};

export default AccessRequestComponent;