import React, {useEffect} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {
    BrandIconDesktop, BrandIconUseYourDdl, BrandIconVerifyDriversLicence, ContentContainer, InPageAlert, Heading
} from '@snsw/react-component-library';
import '../global.css';
import { Card, CardTitle } from '@snsw/react-component-library/build/Components';
import {scrollToTop, useUserRoleState} from '../GlobalStateComponent/GlobalState';
/* eslint-disable max-len */
export const PortalInfo = () => {
    return (
        <><Heading data-testid='portal-info-heading' level={ 1 } style={ { fontSize: '1.75rem', marginBottom: '1.5rem'} }>
            Digital Notifications Portal</Heading>
        <p style={ {margin: 'auto'} }>Welcome to the Service NSW Digital Notifications Portal
                where you can create, manage and send notifications branded
                and built with Service NSW secure and trusted environment.</p>
        </>
    );};

const LandingPage = () => {
    const { state } = useLocation();
    const {userRole} = useUserRoleState();
    useEffect(() => {
        scrollToTop();
    }, []);

    return (
        <>
            <ContentContainer>
                <PortalInfo/>
                {state && state.visible ?
                    <InPageAlert id='agency-status' variant={ state.variant } title={ state.title }>
                        <p id='notStatusDesc'>{ state.description }</p>
                    </InPageAlert> :
                    <div style={ { height: '50vh', paddingTop: '2.5rem', display: 'flex', gap: '3rem' } }>
                        {(userRole?.permissions?.MANAGE_NOTIFICATION_TILE?.includes('VIEW')) && (
                            <div style={ { maxWidth: '370px', width: '30%' } }>
                                <Card isClickable>
                                    <CardTitle level={ 3 } icon={ <BrandIconUseYourDdl title={ 'Notification Configs' } /> }>
                                        {(userRole.dataAccessGroups.includes('ALL') || userRole.dataAccessGroups.length > 1) ?
                                            <Link data-testid='redirectLink' to='/agencies'>Manage Notifications</Link> :
                                            <Link data-testid='redirectLink' to={ `/services/${userRole.dataAccessGroups[0]}` }
                                                state={ { fromLandingPage: true} }>Manage Notifications</Link>}
                                    </CardTitle>
                                    <p>Create, edit and manage agency notifications</p>
                                </Card>
                            </div>
                        )}
                        {(userRole?.permissions?.MANAGE_PREFERENCE_CONFIG_TILE?.includes('VIEW')) && (
                            <div style={ { maxWidth: '370px', width: '30%' } }>
                                <Card isClickable>
                                    <CardTitle level={ 3 } icon={ <BrandIconDesktop title={ 'Preference tiles' } /> }>
                                        <Link to='/preference-tiles'>Manage Preferences Page</Link>
                                    </CardTitle>
                                    <p>Create, edit and view preference page service tiles</p>
                                </Card>
                            </div>
                        )}
                        {(userRole?.permissions?.TEST_NOTIFICATION_TILE?.includes('VIEW')) && (
                            <div style={ { maxWidth: '370px', width: '30%' } }>
                                <Card isClickable>
                                    <CardTitle level={ 3 } icon={ <BrandIconVerifyDriversLicence title={ 'Send Notifications' } /> }>
                                        <Link to='/send-notifications'>Test Notifications</Link>
                                    </CardTitle>
                                    <p>Send test notifications for notifications ready for testing</p>
                                </Card>
                            </div>
                        )}
                    </div>
                }
            </ContentContainer>
        </>
    );
};
export default LandingPage;
