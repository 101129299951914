import {GreenCodeFont, Paragraph} from './APIDocsComponent.styled';
import React from 'react';

const VariablesResponseCode = (props) => {
    return (
        <div id={ props.id }>
            <Paragraph>{'{'}</Paragraph>
            <Paragraph style={ {textIndent: '1em'} }>"data": {'{'}</Paragraph>
            {/* eslint-disable-next-line max-len */}
            <Paragraph style={ {textIndent: '2em'} }>"notificationId": <GreenCodeFont>"TGO-10e9cf02-40c8-4be2-a1ba-e7a472ca348e"</GreenCodeFont></Paragraph>
            <Paragraph style={ {textIndent: '1em'} }>{'},'}</Paragraph>
            {/* eslint-disable-next-line max-len */}
            <Paragraph style={ {textIndent: '1em'} }>"message": <GreenCodeFont>"Notification request is accepted for
                TGO-10e9cf02-40c8-4be2-a1ba-e7a472ca348e"</GreenCodeFont>,</Paragraph>
            {/* eslint-disable-next-line max-len */}
            <Paragraph
                style={ {textIndent: '1em'} }>"timestamp": <GreenCodeFont>"2023-10-31T10:11:30.870232687"</GreenCodeFont></Paragraph>
            <Paragraph>{'}'}</Paragraph>
        </div>
    );
};

export default VariablesResponseCode;