import {BackButtonContainer, PortalFormContainer, PortalFormInput} from '../CommonComponents/CustomComponents/CustomComponents.styled';
import {Link, useNavigate} from 'react-router-dom';
import Button from '@snsw/react-component-library/build/Components/Button/Button';
import React, {useEffect, useState} from 'react';
import Heading from '@snsw/react-component-library/build/Components/Headings/Heading';
import {Col, Form, Row} from '@snsw/react-component-library';
import {FormButtonContainer} from '@snsw/react-component-library/build/Components/Form/Form.styled';
import {colors, globalFont} from '../CommonComponents/Typography/Typography.styled';
import MultiSelectComponent from '../CommonComponents/SelectComponent/MultiSelectComponent';
import apiServices from '../../services/api-services';
import InPageAlert from '@snsw/react-component-library/build/Components/InPageAlert/InPageAlert';
import {sortBy} from 'lodash';

// \w matches any word character (equivalent to [a-zA-Z0-9_])
// \s matches any whitespace character (equivalent to [\r\n\t\f\v ])
export const nameValidCharPattern = /^[\w\s-&]+$/;
export const invalidCharErrorMessage = 'Valid characters: a-z,A-Z,0-9,spaces,-,_,&';

const CreateTeamComponent = () => {
    const navigate = useNavigate();
    const [teamConfig, setTeamConfig] = useState({
        name: '',
        description: '',
        authorisedServices: []
    });
    const [inputErrors, setInputErrors] = useState({
        name: {hasError: false, errorMessage: ''}
    });
    const [servicesOptions, setServicesOptions] = useState([
        [{value: 'ALL', label: 'ALL'}]
    ]);
    const [alert, setAlert] = useState({
        variant: 'success',
        title: 'Added team',
        description: 'Team has been created successfully',
        visible: false
    });
    const handleNameInputChange = (event) => {
        const {name, value} = event.target;
        setTeamConfig(prevState => ({
            ...prevState,
            [name]: value
        }));
        setInputErrors(prevState => ({
            ...prevState,
            name: { hasError: false, errorMessage: '' },
        }));
    };
    const handleServicesOptionChange = (selectedOptions) => {
        const serviceCodes = selectedOptions.map(option => option.value);
        setTeamConfig(prevState => ({
            ...prevState,
            authorisedServices: serviceCodes
        }));
    };
    const validateTeamName = async () => {
        let isValid = true;
        const pattern = nameValidCharPattern;
        const trimmedName = teamConfig.name.trim();
        if (trimmedName.length === 0) {
            isValid = false;
            setInputErrors({
                name: {hasError: true, errorMessage: 'Please enter a team name'}
            });
        } else {
            const validChars = pattern.test(trimmedName);
            if (!validChars) {
                isValid = false;
                setInputErrors({
                    name: {hasError: true, errorMessage: invalidCharErrorMessage}
                });
            }
        }
        if (isValid) {
            await apiServices.getTeamByName(trimmedName).then(response => {
                if (response.data.length > 0) {
                    isValid = false;
                    setInputErrors({
                        name: {hasError: true, errorMessage: 'Team name already exists'}
                    });
                }
            })
                .catch(error => console.log(error));
        }
        return isValid;
    };
    const handleCreateTeam = () => {
        validateTeamName().then(valid => {
            if (valid) {
                const newTeam = {
                    name: teamConfig.name.trim(),
                    description: teamConfig.description,
                    authorisedServices: teamConfig.authorisedServices
                };
                apiServices.createTeam(newTeam).then(response => {
                    navigate('/teams', {
                        state: {
                            variant: 'success',
                            title: `New Team Added: ${teamConfig.name}`,
                            description: '',
                            visible: true
                        }
                    });
                })
                    .catch(error => {
                        let responseMessage = '';
                        if (error.response !== undefined) {
                            responseMessage = error.response.data?.message;
                        } else {
                            responseMessage = 'Unexpected error while creating team.';
                        }
                        setAlert({
                            variant: 'error',
                            title: 'Network error',
                            description: responseMessage,
                            visible: true
                        });
                    });
            }
        });
    };
    useEffect(() => {
        let options = [{value: 'ALL', label: 'ALL'}];
        apiServices.fetchAgencies(true).then(agencies => {
            agencies.forEach(agency => {
                agency.services.forEach(service => {
                    options.push({value: service.serviceCode, label: service.description ? service.description : service.serviceCode });
                });
            });
            options = sortBy(options,
                function(o) { return o.label.toLowerCase(); });
            setServicesOptions(options);
        })
            .catch(e => console.log(e.message));
    }, []);
    return (
        <PortalFormContainer>
            <BackButtonContainer>
                <Button variant='back' as={ Link } href='/teams'>Back</Button>
            </BackButtonContainer>
            <Heading
                className='page-title'
                style={ {
                    fontFamily: globalFont,
                    fontSize: '24px',
                    lineHeight: '2.25rem',
                    color: colors.brand.snswPrimaryBlue,
                    height: 'fit-content', margin: '10px 0 0 0'} }
                headingElement={ 'h1' }
            >Add new team</Heading>
            <Form>
                <Row>
                    <Col span={ 6 }>
                        <PortalFormInput
                            label='Team name'
                            name='name'
                            placeholder='e.g. Grants and Rebates'
                            errorMessage={ inputErrors.name.errorMessage }
                            helpMessage=''
                            hasError={ inputErrors.name.hasError }
                            onChange={ handleNameInputChange }
                            onBlur={ validateTeamName }
                            value={ teamConfig.name }
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={ 6 }>
                        <MultiSelectComponent
                            name='assigned-service'
                            id='assignedServicesSelect'
                            placeholder='Select services'
                            errorMessage={ '' }
                            hasError={ false }
                            label = 'Assign services to team'
                            options={ servicesOptions }
                            onOptionChange={ handleServicesOptionChange }
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={ 6 }>
                        <FormButtonContainer style={ {marginTop: '36px'} }>
                            <Button
                                onClick={ handleCreateTeam }
                                variant='primary'
                                data-testid='add-team-cta-button'
                                style={ {fontFamily: globalFont} }
                            >Add team</Button>
                        </FormButtonContainer>
                    </Col>
                </Row>
                {alert.visible ? <InPageAlert id='agency-status' variant={ alert.variant } title={ alert.title }>
                    <p data-test='notStatusDesc'>{ alert.description }</p>
                </InPageAlert> : null}
            </Form>
        </PortalFormContainer>
    );
};

export default CreateTeamComponent;