import React from 'react';
import AnchorMenuComponent from '../CommonComponents/AnchorMenuComponent/AnchorMenuComponent';
import AnchorMenuLayoutComponent from '../CommonComponents/AnchorMenuLayoutComponent/AnchorMenuLayoutComponent';
import ContentGuidancePushContentComponent from './ContentGuidancePushContentComponent';

const ContentGuidancePushComponent = () => {

    const anchorMenuMetaData = {
        title: 'On this page',
        data: [
            {id: 'menu-push-notifications', title: 'Push notifications', level: 0},
            {id: 'menu-overview', title: 'Overview', level: 1},
            {id: 'menu-measurement', title: 'Measuring impact', level: 1},
            {id: 'menu-average-open-rates', title: 'Average open rates', level: 1}
        ]
    };

    return (
        <AnchorMenuLayoutComponent
            title='Push notifications'
            contentComponent={ ContentGuidancePushContentComponent }
            anchorMenuComponent={ AnchorMenuComponent }
            anchorMenuMetaData={ anchorMenuMetaData }
        />
    );
};

export default ContentGuidancePushComponent;
