import styled from 'styled-components';
import {screenSize} from '../CommonComponents/Typography/Typography.styled';
export const ComponentLayout = styled.div`
    max-width: 1324px;
    max-inline-size: 1224px;
    margin-inline: auto;
    padding-inline: 20px;
    @media (min-width: ${screenSize.tablet}) {
        padding-inline: 30px;
    }
    align-items: center;
`;