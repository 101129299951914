import React from 'react';
import UserManagementComponent from '../UserManagementComponent/UserManagementComponent';
import {Heading, InPageAlert} from '@snsw/react-component-library';
import {UserSettings} from './AdminComponent.styled';
import {useUserRoleState} from '../GlobalStateComponent/GlobalState';
import {CustomTableContainer} from '../CommonComponents/CustomComponents/CustomComponents.styled';

const AdminComponent = () => {
    const {userRole} = useUserRoleState();
    return (
        <CustomTableContainer role='main'>
            <>
                {
                    (userRole && userRole.userRoles[0].name === 'ADMIN') ?
                        <div>
                            <Heading
                                level={ 1 }
                                id='SettingsHeading'
                                style={ { height: 'fit-content', margin: '0.948rem 0 0.5rem 0'} }
                            >
                                Admin
                            </Heading>
                            <UserSettings>
                                <UserManagementComponent />
                            </UserSettings>
                        </div>
                        :
                        <div>
                            <InPageAlert
                                data-testid={ 'permissionErrorAlert' }
                                variant={ 'error' }
                                title={ 'Unauthorised access' }
                                style={ {margin: '1.5rem'} }
                            >
                                <p>You are not authorised to access this page</p>
                            </InPageAlert>
                        </div>
                }
            </>
        </CustomTableContainer>
    );
};
export default AdminComponent;