import React from 'react';

const imagePath = process.env.PUBLIC_URL + '/assets/images/';

const LinkActionToolTip = () => {

    return (<div>
        <p>The Link action alert will appear under the service with a bold title and action link next to it.</p>
        <span style={ { fontWeight: 500 } }>
            Alert Title
        </span>
        <p>The title of the Link Action alert is the bold text displayed to the left of the Action Link text.
            Note that the colon should not be included in this field.
        </p>
        <p>e.g. "Before you can opt-in you'll need to"</p>
        <span style={ { fontWeight: 500, paddingTop: '1rem', display: 'block' } }>
            Action Link text
        </span>
        <p>The text of the link that the user is as shown and highlight red in the image below.
        </p>
        <img
            src={ `${imagePath}userActionLinkActionText.png` }
            alt='A user action alert with the Action Link text section highlighted'
            style={ { width: 'calc(50vw - 4rem)' } }
        />
        <span style={ { fontWeight: 500, paddingTop: '1rem', display: 'block' } }>
            Action Link href
        </span>
        <p>The href for the action link. You should enter the href you wish to use in non-prod here.
            When the alert is promoted to prod you will be prompted to add the production href.
        </p>
    </div>);
};

export default LinkActionToolTip;
