import React, {useEffect, useState} from 'react';
import {Button, Modal, StatusLabel} from '@snsw/react-component-library';
import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {portalConfigStatus} from '../../constants/applicationConstants';
import {useBoolean} from '@snsw/react-component-library/build/utils';
import apiServices from '../../services/api-services';
import {Card} from '@snsw/react-component-library/build/Components';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {TreeItem} from '@mui/x-tree-view/TreeItem';
import Tooltip, {tooltipClasses} from '@mui/material/Tooltip';
import {SimpleTreeView} from '@mui/x-tree-view';

styled(({className, ...props}) => (
    <Tooltip { ...props } classes={ {popper: className} }/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11
    }
}));

const PushNotificationToProd = props => {
    const [notificationPendingChanges, setNotificationPendingChanges] = useState(undefined);
    const [showPromoteToProdModal, openPromoteToProdModal, closePromoteToProdModal] = useBoolean(false);

    const onPromoteToProdModelOpen = (event) => {
        event.stopPropagation();
        openPromoteToProdModal();
    };

    const onPromoteToProdModelClose = () => {
        setNotificationPendingChanges(undefined);
        closePromoteToProdModal();
    };

    const onPromoteToProdConfirmation = (event) => {
        event.stopPropagation();
        onPromoteToProdModelClose();
        apiServices.promoteNotificationToDestination(props.notification.notificationCode).then(() => {
            const statusAlert = {
                variant: 'success',
                title: 'Done',
                description: `${props.notification.notificationCode} has been pushed to production successfully`,
                visible: true
            };
            props.traverse(statusAlert);
        })
            .catch(e => {
                console.log(e);
                props.setAlert({
                    variant: 'error',
                    title: `Error on pushing ${props.notification.notificationCode} to prod`,
                    description: e.response.data.message,
                    visible: true
                });
            });
    };

    const onPromoteToProdModelBackClicked = (event) => {
        event.stopPropagation();
        onPromoteToProdModelClose();
    };

    useEffect(() => {
        if (showPromoteToProdModal) {
            apiServices.compareNotification(props.notification.notificationCode, false)
                .then(data => {
                    populateNotificationPendingChanges(data);
                })
                .catch(e => {
                    closePromoteToProdModal();
                    props.setAlert({
                        variant: 'error',
                        title: `Error comparing notification ${props.notification.notificationCode} with prod environment`,
                        description: e.message,
                        visible: true
                    });
                });
        } else {
            setNotificationPendingChanges(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showPromoteToProdModal]);

    function populateNotificationPendingChanges(data) {
        const notificationChangesToBePromoted = {
            id: props.notification.notificationCode,
            name: props.notification ? props.notification.description : '',
            pendingChanges: data.pendingChanges,
            children: [],
            defaultExpanded: [props.notification.notificationCode]
        };
        if (props.notification.sendEmailEnabled || data.pendingEmailTemplateChanges) {
            const emailTemplateChangesToBePromoted = {
                id: `${props.notification.notificationCode}_email_template`,
                name: 'Email Template',
                pendingChanges: data.pendingEmailTemplateChanges,
                /**
                 * If notification.sendEmailEnabled != true
                 * and notificationPendingChanges.pendingEmailTemplateChanges == true
                 * This means email channel is removed from non-prod environment.
                 * Hence, show the email Template as deleted
                 */
                isRemoved: props.notification.sendEmailEnabled !== true
                    && data.pendingEmailTemplateChanges === true
            };
            notificationChangesToBePromoted.children.push(emailTemplateChangesToBePromoted);
        }

        if (props.notification.showInInbox || data.pendingInboxTemplateChanges) {
            const inboxTemplateChangesToBePromoted = {
                id: `${props.notification.notificationCode}_inbox_template`,
                name: 'Inbox Template',
                pendingChanges: data.pendingInboxTemplateChanges,
                /**
                 * If notification.sendEmailEnabled != true
                 * and notificationPendingChanges.pendingEmailTemplateChanges == true
                 * This means inbox channel is removed from non-prod environment.
                 * Hence, show the email Template as deleted
                 */
                isRemoved: props.notification.showInInbox !== true
                    && data.pendingInboxTemplateChanges === true
            };
            notificationChangesToBePromoted.children.push(inboxTemplateChangesToBePromoted);
        }
        setNotificationPendingChanges(notificationChangesToBePromoted);

        if (props.notification.sendSmsEnabled || data.pendingSmsTemplateChanges) {
            const smsTemplateChangesToBePromoted = {
                id: `${props.notification.notificationCode}_sms_template`,
                name: 'SMS Template',
                pendingChanges: data.pendingInboxTemplateChanges,
                /**
                 * tracking removal of SMS channel
                 */
                isRemoved: props.notification.sendSmsEnabled !== true
                    && data.pendingSmsTemplateChanges === true
            };
            notificationChangesToBePromoted.children.push(smsTemplateChangesToBePromoted);
        }

        if (props.notification.sendPushEnabled || data.pendingPushTemplateChanges) {
            const pushTemplateChangesToBePromoted = {
                id: `${props.notification.notificationCode}_push_template`,
                name: 'Push Template',
                pendingChanges: data.pendingInboxTemplateChanges,
                /**
                 * tracking removal of push channel
                 */
                isRemoved: props.notification.sendPushEnabled !== true
                    && data.pendingPushTemplateChanges === true
            };
            notificationChangesToBePromoted.children.push(pushTemplateChangesToBePromoted);
        }
    }

    const promoteToProdModalButtons = [
        {text: 'Confirm', id: 'promoteChangesToProdBtn', onClick: onPromoteToProdConfirmation},
        {text: 'Back', id: 'promoteChangesBackBtn', onClick: onPromoteToProdModelBackClicked}
    ];

    const renderNotificationPendingChangesOnModelWithTreeView = () => {
        return notificationPendingChanges !== undefined ? (
            <SimpleTreeView defaultExpanded={ notificationPendingChanges.defaultExpanded }
                disableSelection={ true } disabled={ true } defaultCollapseIcon={ <ExpandMoreIcon/> }
                onNodeFocus={ (event) => {
                    event.stopPropagation();
                } }
                onClick={ (event) => {
                    event.stopPropagation();
                } }
                style={ {pointerEvents: 'none'} }
            >
                <TreeItem
                    key={ notificationPendingChanges.id }
                    nodeId={ notificationPendingChanges.id }
                    label={
                        <div style={ {display: 'flex', alignItems: 'center', gap: '1rem', margin: 10} }>
                            Notification: {notificationPendingChanges.name} {notificationPendingChanges.pendingChanges ?
                                <StatusLabel text={ 'PENDING' }
                                    variant={ 'warning' }/> : null}
                        </div>
                    }
                    itemId={ notificationPendingChanges.id }>
                    {notificationPendingChanges.children.map((templatePendingChanges) => {
                        return <TreeItem
                            key={ templatePendingChanges.id }
                            nodeId={ templatePendingChanges.id }
                            label={
                                <div style={ {display: 'flex', alignItems: 'center', gap: '1rem', margin: 10} }>
                                    {templatePendingChanges.name}
                                    {templatePendingChanges.isRemoved ? <StatusLabel text={ 'REMOVED' }
                                        variant={ 'error' }/> :
                                        templatePendingChanges.pendingChanges ? <StatusLabel text={ 'PENDING' }
                                            variant={ 'warning' }/> : null
                                    }
                                </div>
                            }
                        />;
                    })
                    }
                </TreeItem>
            </SimpleTreeView>) : null;
    };

    return (
        <div>
            {props.notification.status === portalConfigStatus.IN_REVIEW &&
                <Button
                    id={ 'promoteNotificationToProdBtn' }
                    data-testid={ 'promoteNotificationBtn' }
                    variant='primary'
                    disabled={ props.pendingTemplateChanges === true }
                    as={ Link }
                    onClick={ onPromoteToProdModelOpen }>
                    Push to Prod
                </Button>}
            {showPromoteToProdModal && (
                <Modal
                    title='Promote to production'
                    description='Following are the list of pending changes that will be promoted to production.
                                             Please confirm if you want to continue.'
                    buttons={ promoteToProdModalButtons }
                >
                    <Card style={ {marginBottom: 20} }>
                        <div>
                            {renderNotificationPendingChangesOnModelWithTreeView()}
                        </div>
                    </Card>
                </Modal>
            )}
        </div>
    );
};

PushNotificationToProd.propTypes = {
    serviceCode: PropTypes.string,
    notification: PropTypes.object,
    agencyConfig: PropTypes.object,
    setAlert: PropTypes.func,
    pendingTemplateChanges: PropTypes.bool,
    traverse: PropTypes.func
};

export default PushNotificationToProd;
