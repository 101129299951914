import FormGroup from '@snsw/react-component-library/build/Components/FormGroup/FormGroup';
import React from 'react';
import Heading from '@snsw/react-component-library/build/Components/Headings/Heading';
import { NotificationComponentSubHeader } from '../SendNotificationComponent/SendNotificationComponent.styled';
import {Input} from '@snsw/react-component-library';
import PropTypes from 'prop-types';
import {IconMobile} from '@snsw/react-component-library/build/Icons/system';
import { MoreInfoModal } from '@snsw/react-component-library';

export const PushContentComponent = (props) => {
    return <div style={ { maxWidth: '75%' } }>
        <NotificationComponentSubHeader>
            <IconMobile />
            <Heading level={ 4 } style={ {display: 'inline', paddingLeft: '8px', paddingRight: '8px'} }>
                { props.title }
            </Heading>
            <MoreInfoModal
                helpText='Push notifications must accompany a web notification.
                The User must have logged into the SNSW MyAccount App for a push to be sent.
                The Push Notification title is the bold heading of the notification and has a character limit of 29 characters.
                The Notification content sits below the title.'
                linkText='More Info'
                questionIconFlag
                subTitle='What you need to know'
                title='Push Notifications'
            />
        </NotificationComponentSubHeader>
        <FormGroup
            label='Notification title'
            id={ `${props.id}-title` }
            hasError={ props.inputError.hasError }
            errorMessage={ props.inputError.errorMessage }
        >
            <Input
                data-testid='push-title'
                id={ `${props.id}-title-text-input` }
                name={ 'title' }
                inputWidth={ 'xl' }
                value={ props.value.title }
                onChange={ props.contentCallback }
            />
        </FormGroup>
        <FormGroup
            label='Notification content'
            helpMessage='The content of the push notification'
            id={ `${props.id}-content` }
            hasError={ props.inputError.hasError }
            errorMessage={ props.inputError.errorMessage }
        >
            <Input
                data-testid='push-content'
                id={ `${props.id}-content-text-input` }
                name={ 'content' }
                inputWidth={ 'xl' }
                value={ props.value.content }
                onChange={ props.contentCallback }
            />
        </FormGroup>
    </div>;
};

PushContentComponent.propTypes = {
    title: PropTypes.string,
    id: PropTypes.string,
    contentCallback: PropTypes.func,
    value: PropTypes.object,
    inputError: PropTypes.object,
};