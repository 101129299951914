import {
    SmsScreenContainer,
    SmsNotificationPreviewContainer,
    TextBubble,
    IphoneText,
    TextTail,
    SmsTextContainer,
    DateAndTime
} from './PreviewSmsContentComponent.styled.js';

const PreviewSmsContentComponent = (props) => {

    return (
        <>
            <SmsNotificationPreviewContainer>
                <SmsScreenContainer>
                    <SmsTextContainer>
                        <DateAndTime/>
                        <TextBubble>
                            <IphoneText>{ props.smsContent }</IphoneText>
                            <TextTail/>
                        </TextBubble>
                    </SmsTextContainer>
                </SmsScreenContainer>
            </SmsNotificationPreviewContainer>
        </>
    );
};
export default PreviewSmsContentComponent;