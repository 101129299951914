import React, {useEffect, useRef, useState} from 'react';
import Button from '@snsw/react-component-library/build/Components/Button/Button';
import apiServices from '../../services/api-services';
import Row from '@snsw/react-component-library/build/layout/Grid/Row.styled';
import Col from '@snsw/react-component-library/build/layout/Grid/Col.styled';
import FormInput from '@snsw/react-component-library/build/Components/Form/FormInput';
import {FormButtonContainer} from '@snsw/react-component-library/build/Components/Form/Form.styled';
import InPageAlert from '@snsw/react-component-library/build/Components/InPageAlert/InPageAlert';
import Form from '@snsw/react-component-library/build/Components/Form/Form';
import {Link, useParams} from 'react-router-dom';
//import FormHeader from '@snsw/react-component-library/build/Components/FormHeader/FormHeader';
import { useNavigate } from 'react-router';
import {Heading, Modal} from '@snsw/react-component-library';
import {useBoolean} from '@snsw/react-component-library/build/utils';
import {scrollToTop, useGlobalState} from '../GlobalStateComponent/GlobalState';
import ComponentLoader from '@snsw/react-component-library/build/Loader/ComponentLoader';
import {PortalFormContainer} from '../CommonComponents/CustomComponents/CustomComponents.styled';

const UpdateAgencyComponent = () => {
    const { agencyCode } = useParams();
    const defaultAgencyConfig = {
        description: '', dataExchangeAgencyCode: ''
    };
    const dbAgencyConfig = useRef();
    const navigate = useNavigate();
    const [isAgencyConfigUpdated, setIsAgencyConfigUpdated] = useState(false);
    const [initialAgencyConfig, setInitialAgencyConfig] = useState({});
    const [showModal, open, close] = useBoolean(false);
    const [agencyConfig, setAgencyConfig] = useState( defaultAgencyConfig);
    const [inputErrors, setInputErrors] = useState({
        description: {hasError: false, errorMessage: 'Please provide an Agency description'},
        dataExchangeAgencyCode: {hasError: false, errorMessage: 'Invalid Data Exchange Agency Code'}
    });
    const [successStatus, setSuccessStatus] = useState({
        variant: 'success',
        title: 'Done',
        description: 'Agency details updated successfully',
        visible: false
    });
    const [inProd] = useGlobalState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        scrollToTop();
        apiServices.searchAgencyCode(agencyCode, inProd).then(data => {
            setInitialAgencyConfig( {
                description: data.description,
                dataExchangeAgencyCode: data.dataExchangeAgencyCode ? data.dataExchangeAgencyCode : ''
            });
            setAgencyConfig( {
                description: data.description,
                dataExchangeAgencyCode: data.dataExchangeAgencyCode ? data.dataExchangeAgencyCode : ''
            });
            dbAgencyConfig.current = data;
        })
            .catch(error => {
                if (inProd) {
                    navigate('/agencies', {
                        state: {
                            variant: 'warning',
                            title: 'Page does not exist in Prod',
                            description: `Agency ${agencyCode} does not exists in Prod`,
                            visible: true

                        }});
                } else {
                    setSuccessStatus({
                        variant: 'error',
                        title: 'Network Error',
                        description: error.message,
                        visible: true
                    });
                }
            }); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [agencyCode, inProd]);

    useEffect(() => {
        if (JSON.stringify(initialAgencyConfig) !== JSON.stringify(agencyConfig)) {
            setIsAgencyConfigUpdated(true);
        } else {
            setIsAgencyConfigUpdated(false);
        } // eslint-disable-next-line
    }, [agencyConfig]);

    const handleChange = event => {
        setSuccessStatus(prevState => ({
            ...prevState,
            visible: false
        }));
        const {name, value} = event.target;
        setAgencyConfig(prevState => ({
            ...prevState,
            [name]: value
        }));
        setInputErrors(prevState => ({
            ...prevState,
            [name]: {hasError: false, errorMessage: 'Error'},
        }));
    };

    const validateRequest = async () => {
        let valid = true;
        if (agencyConfig.description.length === 0) {
            setInputErrors(prevState => ({
                ...prevState,
                description: {hasError: true, errorMessage: 'Please provide an Agency description'}
            }));
            valid = false;
        }
        return valid;
    };

    const updateAgency = async () => {
        setLoading(true);
        validateRequest().then((valid) => {
            if (valid) {
                dbAgencyConfig.current.description = agencyConfig.description;
                dbAgencyConfig.current.dataExchangeAgencyCode = agencyConfig.dataExchangeAgencyCode;
                apiServices.updateAgencyConfig(dbAgencyConfig.current).then((response) => {
                    if(response.status === 200){
                        navigate('/agencies', {
                            state: {
                                variant: 'success',
                                title: 'Done',
                                description: 'Agency details updated successfully',
                                visible: true
                            }
                        });
                    }
                })
                    .catch(e => {
                        setLoading(false);
                        setSuccessStatus({
                            variant: 'error',
                            title: 'Network Error',
                            description: e.message,
                            visible: true
                        });
                    });
            } else {
                setLoading(false);
            }
        });
    };

    const discardChanges = () => {
        if (inProd) {
            navigate('/agencies');
        } else {
            navigate('/agencies', {
                state: {
                    variant: 'info',
                    title: 'Cancelled',
                    description: 'Cancelled changes',
                    visible: true
                }
            });
        }
    };

    return (
        <PortalFormContainer>
            <Button variant='back' as={ Link } href='/'>Back</Button >
            { loading && <ComponentLoader fullPage label='Updating the Agency...'/> }
            <Heading
                level={ 1 }
                id='AgencyListHeading'
                style={ { height: 'fit-content', margin: '6px 0 0.5rem 0'} }
            >
                Agency details
            </Heading>

            <Form data-test='edit-agency-form'>

                <Row>
                    <Col span={ 6 }>
                        <FormInput
                            label='Agency name'
                            disabled={ inProd }
                            name='description'
                            errorMessage={ inputErrors.description.errorMessage }
                            hasError={ inputErrors.description.hasError }
                            margin={ { top: 'lg' } }
                            onChange={ handleChange }
                            value={ agencyConfig.description }
                        />
                        <FormInput name='agencyCode' label='Agency code' disabled={ true } value={ agencyCode } />
                        <FormInput
                            label='Data Exchange Code'
                            disabled={ inProd }
                            name='dataExchangeAgencyCode'
                            errorMessage={ inputErrors.dataExchangeAgencyCode.errorMessage }
                            hasError={ inputErrors.dataExchangeAgencyCode.hasError }
                            margin={ { top: 'lg' } }
                            onChange={ handleChange }
                            value={ agencyConfig.dataExchangeAgencyCode }
                        />
                    </Col>
                </Row>
                <FormButtonContainer style={
                    {
                        display: 'flex',
                        flexWrap: 'nowrap',
                        justifyContent: 'start'
                    }
                }>
                    {!inProd &&
                        <>
                            <Button
                                onClick={ updateAgency }
                                disabled={ !isAgencyConfigUpdated }
                                variant='primary'
                                id='updateAgencyBtn'>
                                Update details
                            </Button>
                            <span style={ {width: '1rem'} }/>
                        </>
                    }
                    { !isAgencyConfigUpdated ?
                        <Button variant='secondary' id='backBtn' onClick={ discardChanges }>Cancel</Button> :
                        <>
                            <Button variant='secondary' id='backBtn' onClick={ open }>Back</Button>
                            {showModal && (
                                <Modal
                                    title='Discard Edit?'
                                    description='Your changes have not been saved.'
                                    buttons={ [
                                        { text: 'Cancel changes', onClick: discardChanges },
                                        { text: 'Back to edit', onClick: () => close() }
                                    ] }
                                />
                            )}
                        </>
                    }
                </FormButtonContainer>
                {successStatus.visible ? <InPageAlert id='agency-status' variant={ successStatus.variant } title={ successStatus.title }>
                    <p data-test='notStatusDesc'>{ successStatus.description }</p>
                </InPageAlert> : null}
            </Form>
        </PortalFormContainer>
    );
};

export default UpdateAgencyComponent;
