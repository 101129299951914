import MultiSelectComponent from '../CommonComponents/SelectComponent/MultiSelectComponent';
import React, {useEffect, useState} from 'react';
import {dataPath, imagePath} from '../GlobalStateComponent/GlobalState';
import {AddSegmentBox, AddSegmentContainer, RemoveSegmentButton} from './SendCampaignComponent.styled';
import SingleSelectComponent from '../CommonComponents/SelectComponent/SingleSelectComponent';
import {BodySemiBold} from '../CommonComponents/Typography/Typography.styled';
import apiServices from '../../services/api-services';
import {toTitleCase} from '../../utils/utilities';

export const segmentOptions = [
    {value: 'lga', label: 'Local Government Area (LGA)'},
    {value: 'postcode', label: 'Postcode', isDisabled: true},
    {value: 'suburb', label: 'Suburb', isDisabled: true}
];

export const containOptions = [
    {value: 'contain', label: 'Contains'},
    {value: 'notContain', label: 'Does not contain'}
];

const AddSegmentComponent = ({segmentKey, setSegments, refContainerWidth, isFirstCondition}) => {
    const [allLGAs, setAllLGAs] = useState([]);
    const [allSuburbs, setAllSuburbs] = useState([]);
    const [inputErrors, setInputErrors] = useState({
        selectCondition: {hasError: false, errorMessage: 'Select LGAs, suburbs or postcodes'},
        selectContain: {hasError: false, errorMessage: 'Select one condition: contains or does not contain'},
        selectLGAs: {hasError: false, errorMessage: 'Select at least one LGA'},
        selectSuburbs: {hasError: false, errorMessage: 'Select at least one suburb'},
        selectPostcodes: {hasError: false, errorMessage: 'Select at least one postcode'}
    });
    const [selectedSegment, setSelectedSegment] = useState(null);
    const [selectedContain, setSelectedContain] = useState(containOptions[0].value);
    const [selectedAttrs, setSelectedAttrs] = useState({
        lga: [],
        suburb: [],
        postcode: []
    });

    const [showDisabledAttr, setShowDisabledAttr] = useState(true);

    useEffect(() => {
        apiServices.fetchLgaList()
            .then(data => {
                if (data && Array.isArray(data)) {
                    const formattedData = data
                        .sort((a, b) => a.localeCompare(b))
                        .map(lga => ({value: lga, label: toTitleCase(lga.toLocaleLowerCase())}));
                    setAllLGAs(formattedData);
                } else {
                    throw new Error('Invalid data format');
                }
            })
            .catch(error => console.error('Error fetching the JSON file:', error));

        // TODO: replace with actual data
        fetch(`${dataPath}suburbs.json`)
            .then(response => response.json())
            .then(data => {
                if (data && Array.isArray(data.suburbs)) {
                    const formattedData = data.suburbs.map(sub => ({
                        value: sub.value,
                        label: sub.value + ' - ' + sub.label
                    }));
                    setAllSuburbs(formattedData);
                } else {
                    throw new Error('Invalid data format');
                }
            })
            .catch(error => console.error('Error fetching the JSON file:', error));
    }, []);

    useEffect(() => {
        const selectedValues = selectedAttrs[selectedSegment];
        if (selectedSegment) {
            setSegments(prevState => {
                return {
                    ...prevState,
                    [segmentKey]: {[selectedSegment]: {[selectedContain]: selectedValues}}
                };
            });
        }
    }, [selectedSegment, selectedContain, selectedAttrs, segmentKey, setSegments]);

    const handleSegmentChange = (selectedOption) => {
        setShowDisabledAttr(false);
        setInputErrors(prevState => ({
            ...prevState,
            selectCondition: {hasError: false, errorMessage: ''}
        }));
        setSelectedSegment(selectedOption.value);
    };

    const handleContainChange = (selectedOption) => {
        setSelectedContain(selectedOption.value);
    };

    const handleMultiSelectOptionChange = (selectedOptions) => {
        setInputErrors(prevState => ({
            ...prevState,
            selectLGAs: {hasError: false, errorMessage: ''}
        }));

        setSelectedAttrs(prevState => ({
            ...prevState,
            [selectedSegment]: selectedOptions.map(option => option.value)
        }));
    };

    function multiSelectComponent(showDropDown, label, name, options, errorMessage, hasError) {
        return <div style={ {display: showDropDown ? 'block' : 'none', marginTop: '-0.5rem'} }>
            <MultiSelectComponent
                name={ name }
                placeholder='Select attribute'
                errorMessage={ errorMessage }
                hasError={ hasError }
                label={ label }
                options={ options }
                onOptionChange={ handleMultiSelectOptionChange }
                hideLabel
                width={ `${refContainerWidth - 72}px` }
                isClearable
                segmentKey={ segmentKey }
            />
        </div>;
    }

    const handleRemoveSegment = () => {
        setSegments(prevState => {
            const {[segmentKey]: removedSegment, ...restOfSegments} = prevState;
            return restOfSegments;
        });
    };
    const deleteIcon = <
        img src={ `${imagePath}/bin.svg` }
        alt={ `bin-img-${segmentKey}` }
        style={ {marginRight: '6px'} }
    />;

    const removeSegmentButton = <RemoveSegmentButton
        variant='tertiary'
        data-testid={ `remove-segment-${segmentKey}` }
        onClick={ handleRemoveSegment }
        iconStart={ deleteIcon }>
        Remove condition
    </RemoveSegmentButton>;

    return <>
        <AddSegmentBox data-testid={ `add-segment-condition-${segmentKey}` }
            style={ {width: `${refContainerWidth - 40}px`} }>
            <AddSegmentContainer>
                <BodySemiBold>
                    {isFirstCondition ? 'Customers who meet ALL the following conditions:' : 'AND'}
                </BodySemiBold>
                <div style={ {display: 'flex', justifyContent: 'space-between'} }>
                    <div style={ {marginRight: '0.5rem', marginTop: '0.5rem', width: '50%'} }>
                        <SingleSelectComponent
                            label={ `Select segment ${segmentKey}` }
                            name={ `condition-${segmentKey}` }
                            options={ segmentOptions }
                            onChange={ handleSegmentChange }
                            placeholder='Select condition'
                            hideLabel
                            errorMessage={ inputErrors.selectCondition.errorMessage }
                            hasError={ inputErrors.selectCondition.hasError }
                        />
                    </div>
                    <div style={ {marginRight: 0, marginTop: '0.5rem', width: '50%'} }>
                        <SingleSelectComponent
                            label={ `Select contain condition-${segmentKey}` }
                            name={ `contains-${segmentKey}` }
                            options={ containOptions }
                            onChange={ handleContainChange }
                            hideLabel
                            errorMessage={ inputErrors.selectContain.errorMessage }
                            hasError={ inputErrors.selectContain.hasError }
                            defaultValue={ containOptions[0] }
                        />
                    </div>
                </div>
                {showDisabledAttr &&
                    <div data-testid={ `disabled-attribute-multi-select-${segmentKey}` } style={ {marginTop: '-0.5rem'} }>
                        <MultiSelectComponent
                            name='placeholder-disabled-attribute'
                            placeholder='Select attribute'
                            hideLabel
                            width={ `${refContainerWidth - 72}px` }
                            isDisabled
                        />
                    </div>}
                {multiSelectComponent(selectedSegment === segmentOptions[0].value, `Select LGAs-${segmentKey}`, 'lga', allLGAs,
                    inputErrors.selectLGAs.errorMessage, inputErrors.selectLGAs.hasError)}
                {multiSelectComponent(selectedSegment === segmentOptions[2].value, `Select suburbs-${segmentKey}`, 'suburb', allSuburbs,
                    inputErrors.selectSuburbs.errorMessage, inputErrors.selectSuburbs.hasError)}
            </AddSegmentContainer>
            {removeSegmentButton}
        </AddSegmentBox>
    </>;
};

export default AddSegmentComponent;