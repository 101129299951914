import AccessRequestComponent from '../AccessRequestComponent/AccessRequestComponent';
import PropTypes from 'prop-types';
import React from 'react';

function WrapperComponent(props) {

    const {component: Component = false} = props;
    return <>
        {props.restricted ?
            (props.userRole && props.userRole.userRoles[0].name === 'ADMIN') ?
                <div style={ {flexGrow: '1'} }>
                    <Component/>
                </div> : <AccessRequestComponent/>
            :
            <div style={ {flexGrow: '1'} }>
                <Component/>
            </div>}
    </>;
}

WrapperComponent.propTypes = {
    restricted: PropTypes.bool,
    userRole: PropTypes.any,
    component: PropTypes.object
};

export default WrapperComponent;