import React from 'react';
import Button from '@snsw/react-component-library/build/Components/Button/Button';
import {ComponentWrapper, ImgWrapper, Img, Body, Heading} from './IntroductionComponent.styled';

const IntroductionComponent = () => {
    const navigateToWorkWithUs =() => {
        document.getElementById('formSection').scrollIntoView({ behavior: 'smooth' });
    };
    return (
        <ComponentWrapper>
            <div>
                <Heading>Send effective and secure digital notifications</Heading>
            </div>
            <ImgWrapper>
                <Img src={ `${process.env.PUBLIC_URL}/assets/images/media-devices.png` } alt='media devices'></Img>
            </ImgWrapper>
            <Body>
                {/* eslint-disable-next-line max-len */}
                <p>Our self-service portal means you can send emails, text messages and push notifications to your customers in a secure and simple way.</p>
            </Body>
            <div>
                <Button
                    onClick={ navigateToWorkWithUs }
                    variant='primary'
                >Work with us</Button>
            </div>
        </ComponentWrapper>
    );
};

export default IntroductionComponent;
