import {GreenCodeFont, Paragraph} from './APIDocsComponent.styled';
import React from 'react';

const VariablesRequestCode = (props) => {
    return (
        <div id={ props.id }>
            <Paragraph>curl --location -g --request
                POST <GreenCodeFont>{'\'https://api-psm.g.testservicensw.net/notifications/v2\''}</GreenCodeFont> \</Paragraph>
            <Paragraph>--header <GreenCodeFont>{'\'Authorization: Bearer <token>\''}</GreenCodeFont> \</Paragraph>
            <Paragraph>--data-raw <GreenCodeFont>{'\'{'}</GreenCodeFont></Paragraph>
            {/* eslint-disable-next-line max-len */}
            <Paragraph style={ {textIndent: '1em'} }><GreenCodeFont>"notificationCode"</GreenCodeFont>: <GreenCodeFont>"SHOWCASE_V_TWO"</GreenCodeFont>,</Paragraph>
            {/* eslint-disable-next-line max-len */}
            <Paragraph style={ {textIndent: '1em'} }><GreenCodeFont>"customerId"</GreenCodeFont>: <GreenCodeFont>"766d9132-0b2e-4574-0v90ec9342f2"</GreenCodeFont>,</Paragraph>
            {/* eslint-disable-next-line max-len */}
            <Paragraph style={ {textIndent: '1em'} }><GreenCodeFont>"notificationDetails"</GreenCodeFont>: {'{'}
            </Paragraph>
            <Paragraph style={ {textIndent: '2em'} }><GreenCodeFont>"variables"</GreenCodeFont>: [</Paragraph>
            <Paragraph style={ {textIndent: '3em'} }>{'{'}</Paragraph>
            {/* eslint-disable-next-line max-len */}
            <Paragraph style={ {textIndent: '4em'} }><GreenCodeFont>"name"</GreenCodeFont>: <GreenCodeFont>"status"</GreenCodeFont>,</Paragraph>
            {/* eslint-disable-next-line max-len */}
            <Paragraph style={ {textIndent: '4em'} }><GreenCodeFont>"value"</GreenCodeFont>: <GreenCodeFont>"unpaid"</GreenCodeFont></Paragraph>
            <Paragraph style={ {textIndent: '3em'} }>{'},'}</Paragraph>
            <Paragraph style={ {textIndent: '3em'} }>{'{'}</Paragraph>
            {/* eslint-disable-next-line max-len */}
            <Paragraph style={ {textIndent: '4em'} }><GreenCodeFont>"name"</GreenCodeFont>: <GreenCodeFont>"changedData"</GreenCodeFont>,</Paragraph>
            <Paragraph style={ {textIndent: '4em'} }><GreenCodeFont>"value"</GreenCodeFont>: <GreenCodeFont>"test body
                data"</GreenCodeFont></Paragraph>
            <Paragraph style={ {textIndent: '3em'} }>{'},'}</Paragraph>
            <Paragraph style={ {textIndent: '3em'} }>{'{'}</Paragraph>
            {/* eslint-disable-next-line max-len */}
            <Paragraph style={ {textIndent: '4em'} }><GreenCodeFont>"name"</GreenCodeFont>: <GreenCodeFont>"subjectText"</GreenCodeFont>,</Paragraph>
            <Paragraph style={ {textIndent: '4em'} }><GreenCodeFont>"value"</GreenCodeFont>: <GreenCodeFont>"Hello test
                inbox subject"</GreenCodeFont></Paragraph>
            <Paragraph style={ {textIndent: '3em'} }>{'}'}</Paragraph>
            <Paragraph style={ {textIndent: '2em'} }>{']'}</Paragraph>
            <Paragraph style={ {textIndent: '1em'} }>{'}'}</Paragraph>
            <Paragraph>{'}\''}</Paragraph>
        </div>
    );
};

export default VariablesRequestCode;