import Heading from '@snsw/react-component-library/build/Components/Headings/Heading';
import React from 'react';
import { ContentSubHeader } from '../NotificationContent.styled';
import { SubjectComponent } from '../SubjectComponent';

export const ContentVariableComponent = (props) => {

    return <div>
        <Heading
            data-testid='content-variables-header-test'
            level={ 4 }
            style={ { height: 'fit-content' } }>
                Content variables
        </Heading>
        <ContentSubHeader data-testid='content-variables-subheader-test'>
            <p>
                This notification uses content variables. When a customer reads a notification, the variable name will be replaced with the
                corresponding text or HTML content. You must populate all variable fields.
            </p>
        </ContentSubHeader>

        { props.notificationRequest.notificationDetails.data.map((variable,i) => {
            return <div key={ `${variable.name}-${i}` }>
                <SubjectComponent
                    id={ `content-variable-${i}` }
                    dataTest={ `variable-${i}` }
                    value={ variable.value }
                    label={ variable.name }
                    name={ variable.name }
                    onChange={ props.variableContentCallback }
                    inputError={ variable.value.trim().length > 0 ? { ...props.inputError, hasError: false} : props.inputError }
                    isOptional={ props.optionalVariables.includes(variable.name) }
                />
            </div>;
        }) }
    </div>;
};
