import {Link, useNavigate, useParams} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import apiServices from '../../services/api-services';
import {sortBy} from 'lodash';
import Button from '@snsw/react-component-library/build/Components/Button/Button';
import Heading from '@snsw/react-component-library/build/Components/Headings/Heading';
import {colors, globalFont} from '../CommonComponents/Typography/Typography.styled';
import {Col, Form, Modal, Row} from '@snsw/react-component-library';
import MultiSelectComponent from '../CommonComponents/SelectComponent/MultiSelectComponent';
import {FormButtonContainer} from '@snsw/react-component-library/build/Components/Form/Form.styled';
import InPageAlert from '@snsw/react-component-library/build/Components/InPageAlert/InPageAlert';
import {
    BackButtonContainer,
    PortalFormContainer,
    PortalFormInput
} from '../CommonComponents/CustomComponents/CustomComponents.styled';
import {useUserRoleState} from '../GlobalStateComponent/GlobalState';
import {useBoolean} from '@snsw/react-component-library/build/utils';
import {IconDelete} from '@snsw/react-component-library/build/Icons/system';
import styled from 'styled-components';
import {invalidCharErrorMessage, nameValidCharPattern} from '../CreateTeamComponent/CreateTeamComponent';

const DeleteTeamButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 0.25rem;
    margin: 0rem 2.375rem;
    align-items: center;
    > svg {
        fill: ${colors.brand.snswSecondaryBlue};
    }
`;

const UpdateTeamComponent = () => {
    const { teamId } = useParams();
    const { userRole } = useUserRoleState();
    const navigate = useNavigate();
    const [initialTeamConfig, setInitialTeamConfig] = useState({
        id: -1,
        name: '',
        description: '',
        authorisedServices: []
    });
    const [teamConfig, setTeamConfig] = useState({
        id: -1,
        name: '',
        description: '',
        authorisedServices: []
    });
    const [inputErrors, setInputErrors] = useState({
        name: {hasError: false, errorMessage: ''}
    });
    const [servicesOptions, setServicesOptions] = useState([
        [{value: 'ALL', label: 'ALL'}]
    ]);
    const [initialServicesOptionsValue, setInitialServicesOptionsValue] = useState([]);
    const [alert, setAlert] = useState({
        variant: 'success',
        title: 'Added team',
        description: 'Team has been created successfully',
        visible: false
    });
    const [isTeamNotChanged, setIsTeamNotChanged] = useState(true);
    const [showDeleteModal, open, close] = useBoolean(false);
    const [showComponent, setShowComponent] = useBoolean(false);

    useEffect(() => {
        let options = [{value: 'ALL', label: 'ALL'}];
        apiServices.fetchAgencies(true).then(agencies => {
            agencies.forEach(agency => {
                agency.services.forEach(service => {
                    options.push({value: service.serviceCode, label: service.description ? service.description : service.serviceCode });
                });
            });
            options = sortBy(options,
                function(o) { return o.label.toLowerCase(); });
            setServicesOptions(options);
        })
            .catch(e => console.log(e.message));
        apiServices.getTeamById(teamId).then(response => {
            const data = response.data;
            if (data.length > 0) {
                setTeamConfig(data[0]);
                setInitialTeamConfig(data[0]);
                setShowComponent(true);
            } else {
                setAlert({
                    variant: 'error',
                    title: 'Error',
                    description: 'Team not found',
                    visible: true
                });
            }
        })
            .catch(() => {
                setAlert({
                    variant: 'error',
                    title: 'Error',
                    description: 'Team not found',
                    visible: true
                });
            });// eslint-disable-next-line
    }, [teamId]);
    useEffect( () => {
        const filteredOptions = servicesOptions.filter(option =>
            teamConfig.authorisedServices.includes(option.value)
        );
        setInitialServicesOptionsValue(filteredOptions);
    }, [servicesOptions, teamConfig.authorisedServices]);
    const compareToInitialTeamConfig = (name, authorisedServices) => {
        let isSame = true;
        if (name.trim() !== initialTeamConfig.name) {
            isSame = false;
        }
        if (JSON.stringify(authorisedServices.sort()) !==
            JSON.stringify(initialTeamConfig.authorisedServices.sort())) {
            isSame = false;
        }
        return isSame;
    };
    const handleNameInputChange = (event) => {
        const {name, value} = event.target;
        setTeamConfig(prevState => ({
            ...prevState,
            [name]: value
        }));
        setInputErrors(prevState => ({
            ...prevState,
            name: { hasError: false, errorMessage: '' },
        }));
        setIsTeamNotChanged(compareToInitialTeamConfig(value, teamConfig.authorisedServices));
    };
    const handleServicesOptionChange = (selectedOptions) => {
        const serviceCodes = selectedOptions.map(option => option.value);
        setTeamConfig(prevState => ({
            ...prevState,
            authorisedServices: serviceCodes
        }));
        setIsTeamNotChanged(compareToInitialTeamConfig(teamConfig.name, serviceCodes));
    };
    const validateTeamName = async () => {
        let isValid = true;
        const pattern = nameValidCharPattern;
        const trimmedName = teamConfig.name.trim();
        if (trimmedName.length === 0) {
            isValid = false;
            setInputErrors({
                name: {hasError: true, errorMessage: 'Please enter a team name'}
            });
        } else {
            const validChars = pattern.test(trimmedName);
            if (!validChars) {
                isValid = false;
                setInputErrors({
                    name: {hasError: true, errorMessage: invalidCharErrorMessage}
                });
            }
        }
        if (trimmedName !== initialTeamConfig.name) {
            await apiServices.getTeamByName(trimmedName).then(response => {
                if (response.data.length > 0) {
                    isValid = false;
                    setInputErrors({
                        name: {hasError: true, errorMessage: 'Team name already exists'}
                    });
                }
            })
                .catch(error => console.log(error));
        }
        return isValid;
    };
    const handleSaveTeam = () => {
        validateTeamName().then(valid => {
            if (valid) {
                apiServices.updateTeam(teamConfig).then(r => {
                    navigate('/teams', {
                        state: {
                            variant: 'success',
                            title: `Team updated: ${teamConfig.name}`,
                            description: '',
                            visible: true
                        }
                    });
                })
                    .catch(error => {
                        let responseMessage = '';
                        if (error.response !== undefined) {
                            responseMessage = error.response.data.message;
                        } else {
                            responseMessage = 'Unexpected error while updating team.';
                        }
                        setAlert({
                            variant: 'error',
                            title: 'Network error',
                            description: responseMessage,
                            visible: true
                        });
                    });
            }
        });
    };
    const handleDeleteTeam = () => {
        apiServices.deleteTeam(teamId).then(() =>
            navigate('/teams', {
                state: {
                    variant: 'success',
                    title: `Deleted team: ${teamConfig.name}`,
                    description: '',
                    visible: true
                }
            })
        )
            .catch(error => {
                let responseMessage = '';
                if (error.response !== undefined) {
                    responseMessage = error.response.data?.message;
                } else {
                    responseMessage = 'Unexpected error while deleting team.';
                }
                setAlert({
                    variant: 'error',
                    title: 'Network error',
                    description: responseMessage,
                    visible: true
                });
            });
    };
    return (
        <PortalFormContainer>
            <BackButtonContainer>
                <Button classname='backButtonLabel' variant='back' as={ Link } href='/teams'>
                    Back
                </Button >
            </BackButtonContainer>
            { showComponent && <>
                <Heading
                    className='page-title'
                    style={ {
                        fontFamily: globalFont,
                        fontSize: '1.25rem',
                        lineHeight: '2.25rem',
                        color: colors.brand.snswPrimaryBlue,
                        height: 'fit-content', margin: '18px 0 0 0'} }
                    headingElement={ 'h1' }
                >Edit team</Heading>
                <Form>
                    <Row>
                        <Col span={ 6 }>
                            <PortalFormInput
                                label='Team name'
                                name='name'
                                placeholder='e.g. Grants and Rebates'
                                errorMessage={ inputErrors.name.errorMessage }
                                helpMessage=''
                                hasError={ inputErrors.name.hasError }
                                onChange={ handleNameInputChange }
                                onBlur={ validateTeamName }
                                value={ teamConfig.name }
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={ 6 }>
                            <MultiSelectComponent
                                name='assign-service'
                                placeholder='Select services'
                                errorMessage={ '' }
                                hasError={ false }
                                label = 'Assign services to team'
                                options={ servicesOptions }
                                onOptionChange={ handleServicesOptionChange }
                                initialValue={ initialServicesOptionsValue }
                                style={ {fontFamily: globalFont} }
                            />
                        </Col>
                    </Row>
                    <Row style={ {marginLeft: 0, marginRight: 0} }>
                        <FormButtonContainer style={ {marginTop: '1.5rem'} }>
                            <Button
                                onClick={ handleSaveTeam }
                                variant='primary'
                                data-testid='add-team-cta-button'
                                disabled={ isTeamNotChanged }
                                style={ {fontFamily: globalFont} }
                            >Save</Button>
                            { userRole.permissions.TEAM.includes('DELETE') &&
                                <DeleteTeamButtonContainer>
                                    <IconDelete/>
                                    <Button id={ 'removeTeamBtn' }
                                        data-testid={ 'delete-team-button' }
                                        variant='link'
                                        onClick={ open }
                                        style={ {fontFamily: globalFont} }
                                    >
                                        Delete team
                                    </Button>
                                </DeleteTeamButtonContainer>
                            }
                        </FormButtonContainer>
                    </Row>
                </Form>
                {showDeleteModal && (
                    <Modal
                        title='Delete team'
                        description='Are you sure you want to permanently delete this team?'
                        buttons={ [
                            {text: 'Delete', onClick: handleDeleteTeam },
                            {text: 'Cancel', onClick: () => close()}
                        ] }
                    />
                )}
            </> }
            {alert.visible ? <InPageAlert data-testid='pageAlert' variant={ alert.variant } title={ alert.title }>
                <p data-testid='alertDesc'>{ alert.description }</p>
            </InPageAlert> : null}
        </PortalFormContainer>
    );
};

export default UpdateTeamComponent;