import Textarea from '@snsw/react-component-library/build/Components/Textarea/Textarea';
import FormGroup from '@snsw/react-component-library/build/Components/FormGroup/FormGroup';
import React from 'react';
import Heading from '@snsw/react-component-library/build/Components/Headings/Heading';
import { NotificationComponentSubHeader } from '../SendNotificationComponent/SendNotificationComponent.styled';
import PropTypes from 'prop-types';
import {IconFeedback} from '@snsw/react-component-library/build/Icons/system';

export const SmsContentComponent = (props) => {
    return <div style={ { maxWidth: '75%' } }>
        <NotificationComponentSubHeader>
            <IconFeedback/>
            <Heading level={ 4 } style={ {display: 'inline', paddingLeft: '8px'} }>
                { props.title }
            </Heading>
        </NotificationComponentSubHeader>
        <FormGroup
            label={ props.label }
            helpMessage={ props.helpMessage }
            id={ props.id }
            hasError={ props.inputError.hasError }
            errorMessage={ props.inputError.errorMessage }
        >
            <Textarea
                data-testid={ props.dataTest }
                rows='small'
                onChange={ props.contentCallback }
                name={ props.name }
                value={ props.value }
            />
        </FormGroup>
    </div>;
};

SmsContentComponent.propTypes = {
    title: PropTypes.string,
    label: PropTypes.string,
    helpMessage: PropTypes.string,
    id: PropTypes.string,
    dataTest: PropTypes.string,
    contentCallback: PropTypes.func,
    name: PropTypes.string,
    value: PropTypes.string,
    inputError: PropTypes.object,
};