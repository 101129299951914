import styled from 'styled-components';
import {
    globalFont,
    fontWeight,
    colors, fontSize, lineHeight
} from '../CommonComponents/Typography/Typography.styled';

const codeFont = 'Menlo, Monaco, "Courier New", Courier, monospace';

export const CodeSnippetCopyIcon = styled.img`
    width: 12px;
    height: 12px;
`;

export const CodeSnippetContainer = styled.div`
    width: 100%;
`;

export const CodeSnippetHeader = styled.div`
    display: flex;
    border-radius: 5px 5px 0px 0px;
    background: ${colors.grey.shuttle};
    padding: 8px 20px;
    font-family: ${globalFont};
    font-size: ${fontSize.body};
    line-height: ${lineHeight.body};
    font-weight: ${fontWeight.bold};
    color: ${colors.white};
    align-items: center;
    height: 40px;
`;

export const CodeSnippetTitle = styled.div`
    flex-grow: 1;
`;

export const CodeSnippetActionText = styled.div`
    flex-grow: 1;
    text-align: end;
    padding-right: 10px;
`;

export const CodeSnippetCopyIconWrapper = styled.div`
    border-radius: 2px;
    padding: 0px 5px 0px 5px;
    background: rgba(255, 255, 255, 0.20);

    &:hover {
        border: 1px solid ${colors.grey.neutral};
    }
`;

export const CodeSnippetBody = styled.div`
    display: flex;
    background-color: #01193E;
    padding: 20px;
    color: #EE55FF;
    font-family: ${codeFont};
    font-size: ${fontSize.body};
    line-height: ${lineHeight.body};
    font-weight: ${fontWeight.regular};
    border-radius: 0px 0px 5px 5px;
`;