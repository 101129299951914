import styled from 'styled-components';
export const SmsNotificationPreviewContainer = styled.div`
    display: flex;
    height: 630px;
    padding: 0 610px 1px 0;
    align-items: center;
    align-self: stretch;
`;
export const SmsScreenContainer = styled.div`
  width: 320px;
  height: 576px;
  flex-shrink: 0;
  background: url('/assets/images/sms-notification-screen.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  box-shadow: 0px 10px 30px 0px rgba(149, 157, 165, 0.20);
  //border: 1px solid #0000001f
  overflow: auto;
  border: solid 1px #DEE3E5
;
`;
export const SmsTextContainer = styled.div`
  top: 13.7%;
  width: 320px;
  height: 435px;
  overflow: auto;
  position: relative;
`;
export const TextBubble = styled.div`
  position: relative;
  max-width: 72%;
  overflow-wrap: break-word;
  display: inline-block;
  border-radius: 18px;
  background: var(--i-os-color-6, #E9E9EB);
  left: 7%;
  margin-bottom: 3px;
  margin-right: 50px;
  padding: 6px 12px;
  align-items: flex-end;
  flex: 1 0 0;
  align-self: stretch;
`;
export const TextTail = styled.div`
  background: url('/assets/images/sms-notification-tail.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 16px;
  height: 25px;
  position: absolute;
  bottom: -2px;
  left: -6px;
`;

export const DateAndTime = styled.div`
      background: url('/assets/images/sms-notification-date-time.png');
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      width: 39%;
      height: 8%;
      position: relative;
      left: 30%;
      top: 1%;
`
;export const IphoneText = styled.p`
  color: var(--i-os-color-1, #000);
  font-feature-settings: 'case' on;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 17px;
  font-style: normal;
  white-space: pre-line;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.408px;
  margin: 0;
`;