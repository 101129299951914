import React, {useEffect, useState} from 'react';
import Heading from '@snsw/react-component-library/build/Components/Headings/Heading';
import Button from '@snsw/react-component-library/build/Components/Button/Button';
import apiServices from '../../services/api-services';
import Row from '@snsw/react-component-library/build/layout/Grid/Row.styled';
import Col from '@snsw/react-component-library/build/layout/Grid/Col.styled';
import InPageAlert from '@snsw/react-component-library/build/Components/InPageAlert/InPageAlert';
import Form from '@snsw/react-component-library/build/Components/Form/Form';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {scrollToTop, useGlobalState} from '../GlobalStateComponent/GlobalState';
import ComponentLoader from '@snsw/react-component-library/build/Loader/ComponentLoader';
import {Modal} from '@snsw/react-component-library';
import {useBoolean} from '@snsw/react-component-library/build/utils';
import MultiSelectComponent from '../CommonComponents/SelectComponent/MultiSelectComponent';
import {FormButtonContainer} from '@snsw/react-component-library/build/Components/Form/Form.styled';
import {
    BackButtonContainer,
    PortalFormContainer,
    PortalFormInput,
    PortalFormSelect
} from '../CommonComponents/CustomComponents/CustomComponents.styled';
import {colors, globalFont} from '../CommonComponents/Typography/Typography.styled';

const CreateUserComponent = () => {

    const navigate = useNavigate();
    const { state } = useLocation();
    const [inProd] = useGlobalState();
    const [userRoleOptions, setUserRoleOptions] = useState(
        [{value: '', text: '', name: '', description: '', permissions: {}}]);
    const [teamOptions, setTeamOptions] = useState({});
    const [inputErrors, setInputErrors] = useState({
        email: {hasError: false, errorMessage: 'Enter an email address'},
        userRole: {hasError: false, errorMessage: 'Select user role'},
        teamName: {hasError: false, errorMessage: 'Enter team name'},
        userTeams: {hasError: false, errorMessage: 'Please select one or more teams'}
    });
    const [successStatus, setSuccessStatus] = useState({
        variant: 'success',
        title: 'Done',
        description: 'User has been created successfully',
        visible: false
    });
    const [newUserConfig, setNewUserConfig] = useState(
        {
            'email': '',
            'userRole': '',
            'teamName': '',
            'fullName': '',
            'userRoleId' :'',
            'userTeams': []
        }
    );
    const [loading, setLoading] = useState(false);
    const [showModal, open, close] = useBoolean(false);

    useEffect(() => {
        scrollToTop();
        //fetch user role list
        apiServices.fetchUserRoleList().then(res => {
            const data = [];
            res.forEach(userRole => {
                if (userRole.name === 'GENERAL') {
                    setNewUserConfig(prevState => ({
                        ...prevState,
                        userRoleId: userRole.id.toString(),
                        userRole: [{id: userRole.id, name: userRole.name, description: userRole.description, permissions: {}}]
                    }));
                }

                data.push({value: userRole.id.toString(), text: userRole.description, name: userRole.name, permissions:{}});

            });
            setUserRoleOptions(data);
        })
            .catch(e => console.log(e));
        apiServices.fetchTeams().then(data => {
            const teamOptions = [];
            data.forEach(team => {
                teamOptions.push({value: team.id, label: team.name, teamConfig: team});
            });
            setTeamOptions(teamOptions);
        })
            .catch(e => console.log(e));
    }, [inProd, state]);

    const handleChange = event => {
        setSuccessStatus(prevState => ({
            ...prevState,
            visible: false
        }));
        const {name, value} = event.target;
        setNewUserConfig(prevState => ({
            ...prevState,
            [name]: value
        }));
        if (name === 'userRoleId') {
            const selectedUserRole = userRoleOptions.find(option => option.value === value);
            setNewUserConfig(prevState => ({
                ...prevState,
                userRoleId: value,
                userRole: [
                    {
                        id: Number(selectedUserRole.value),
                        name: selectedUserRole.name,
                        description: selectedUserRole.text,
                        permissions: selectedUserRole.permissions
                    }
                ]
            }));

        }
        setInputErrors(prevState => ({
            ...prevState,
            [name]: {hasError: false, errorMessage: 'Error'},
        }));
    };

    const handleTeamChange = (selectedOptions) => {
        setInputErrors(prevState => ({
            ...prevState,
            userTeams: {hasError: false, errorMessage: ''},
        }));
        const teams = selectedOptions.map((option) => option.teamConfig);
        setNewUserConfig(prevState => ({
            ...prevState,
            userTeams: teams
        }));
    };

    const validateRequest = async () => {
        const emailIsValid = await validateEmail(newUserConfig.email);
        const teamIsValid = await validateTeam(newUserConfig.userTeams);
        if (!teamIsValid) {
            setInputErrors(prevState => ({
                ...prevState,
                userTeams: {hasError: true, errorMessage: 'Please select one or more teams'},
            }));
        }
        return emailIsValid && teamIsValid;
    };

    const validateEmail = async (email) =>{
        if (email.length === 0) {
            setInputErrors(prevState => ({
                ...prevState,
                email: {hasError: true, errorMessage: 'Enter an email address'}
            }));
            return false;
        }
        if (email.length > 50) {
            setInputErrors(prevState => ({
                ...prevState,
                email: {hasError: true, errorMessage: 'Max 50 characters allowed for email Address'}
            }));
            return false;
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const val = emailRegex.test(email);
        if (!val) {
            setInputErrors(prevState => ({
                ...prevState,
                email: {hasError: true, errorMessage: 'Invalid email address'}
            }));
            return false;
        }
        //check if email exists
        try {
            const user = await apiServices.fetchUserByEmail(email.trim());
            if (user.email) {
                setInputErrors(prevState => ({
                    ...prevState,
                    email: {hasError: true, errorMessage: 'User with this email address already exists'}
                }));
                return false;
            }
        } catch (error) {
        }
        return true;
    };

    const validateTeam = async (teams) => {
        return teams.length > 0;
    };

    const createUser = async () => {
        setLoading(true);
        validateRequest().then((valid) => {
            if (valid) {
                apiServices.createUser(newUserConfig).then(() => {
                    navigate('/admin', {
                        state: {
                            variant: 'success',
                            title: 'Done',
                            description: 'New user added',
                            visible: true
                        }
                    });
                })
                    .catch(e => {
                        setLoading(false);
                        setSuccessStatus({
                            variant: 'error',
                            title: 'Network Error',
                            description: e.message,
                            visible: true
                        });
                    });
            } else {
                setLoading(false);
            }
        });
    };
    return (
        <PortalFormContainer>
            <BackButtonContainer>
                <Button variant='back' as={ Link } href='/admin'>Back</Button >
            </BackButtonContainer>
            { loading && <ComponentLoader fullPage label='Creating the User...'/> }
            <Heading
                className='page-title'
                style={ {
                    height: 'fit-content',
                    margin: '18px 0 0 0',
                    fontFamily: globalFont,
                    fontSize: '24px',
                    lineHeight: '2.25rem',
                    color: colors.brand.snswPrimaryBlue,
                } }
                level={ 1 }
            >Add new user</Heading>
            <Form data-test='new-user-form'>
                <Row>
                    <Col span={ 6 }>
                        <PortalFormInput
                            label='Email address'
                            name='email'
                            errorMessage={ inputErrors.email.errorMessage }
                            helpMessage=''
                            hasError={ inputErrors.email.hasError }
                            style={ { marginTop: '16px', width: '592px' } }
                            onChange={ handleChange }
                            value={ newUserConfig.email }
                        />
                        <PortalFormSelect
                            label='Assign user role'
                            name='userRoleId'
                            errorMessage={ inputErrors.userRole.errorMessage }
                            hasError={ inputErrors.userRole.hasError }
                            options={ userRoleOptions }
                            onChange={ handleChange }
                            value={ newUserConfig.userRoleId }
                        />
                        <MultiSelectComponent
                            name='team'
                            placeholder='Select team'
                            errorMessage={ inputErrors.userTeams.errorMessage }
                            hasError={ inputErrors.userTeams.hasError }
                            label='Assign team(s)'
                            options={ teamOptions }
                            onOptionChange={ handleTeamChange }
                        />
                    </Col>
                </Row>

                <FormButtonContainer style={
                    {
                        display: 'flex',
                        flexWrap: 'nowrap',
                        justifyContent: 'start',
                        flexDirection: 'column',
                        rowGap: '32px',
                        marginTop: '32px'
                    }
                }>
                    <div style={
                        {
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            columnGap: '25px'
                        }
                    }>
                        <Button
                            onClick={ createUser }
                            variant='primary'
                            id='createUserBtn'
                        >Add user</Button>
                        <Button variant='secondary' id='backBtn' onClick={ open }>Cancel</Button>
                        {showModal && (
                            <Modal
                                title='Are you sure you want to discard your unsaved changes?' // eslint-disable-next-line max-len
                                description='By going back you will loose all the progress on this form and no User will be created.'
                                buttons={ [
                                    { text: 'Discard', onClick: navigate('/admin') },
                                    { text: 'Cancel', onClick: () => close() }
                                ] }
                            />
                        )}
                    </div>
                    {successStatus.visible ? <InPageAlert id='agency-status' variant={ successStatus.variant } title={ successStatus.title }>
                        <p data-test='notStatusDesc'>{ successStatus.description }</p>
                    </InPageAlert> : null}
                </FormButtonContainer>
            </Form>
        </PortalFormContainer>
    );
};

export default CreateUserComponent;
