import React from 'react';
import RadioButton from './RadioButton';
import {RadioButtonError} from '../CustomComponents/CustomComponents.styled';

const RadioButtonGroup = ({
    label,
    options,
    defaultOption,
    selectedValue,
    onChange,
    disabled,
    hasError,
    errorMessage
}) => {
    const initialValue = selectedValue !== undefined && selectedValue !== null ? selectedValue : defaultOption;

    return (
        <><label style={ {fontWeight: '500'} } id={ `radio-button-${label}` }>{label}</label>
            <div style={ {display: 'flex', justifyContent: 'space-between'} }>
                {options.map((option) => (
                    <RadioButton
                        key={ option.value }
                        option={ option }
                        selectedValue={ initialValue }
                        onChange={ onChange }
                        disabled={ disabled }
                        hasError={ hasError }
                        aria-labelledby={ `radio-button-${label}` }
                    />
                ))}
            </div>
            {hasError && <RadioButtonError>
                <img
                    style={ {width: '15px', height: '15px', display: 'flex', flexDirection: 'row', alignItems: 'center'} }
                    src={ `${process.env.PUBLIC_URL}/assets/icons/Notification-Error.svg` } alt={ 'error' }/>
                <span className={ 'FormError' } style={ {paddingLeft: '2px'} }>{errorMessage}</span>
            </RadioButtonError>}
        </>
    )
    ;
};

export default RadioButtonGroup;
