import React from 'react';
import {
    ContentContainer,
    Paragraph,
    Heading,
    UnorderedList,
    ListItem,
    SubHeading
} from './ContentGuidanceEmailContentComponent.styled';
import { TextLink } from '@snsw-gel/react';

const ContentGuidanceEmailContentComponent = (props) => {

    const anchorMenuMetaData = props.menuMetaData;

    return (
        <ContentContainer>
            <div>
                <Paragraph>
                    Use this guidance to write and design emails. These guidelines demonstrate best practice and will
                    help you create simple, clear and understandable email content.
                </Paragraph>
                <Paragraph>
                    They’re for anyone designing emails, particularly those writing the email content.
                </Paragraph>
            </div>
            <div>
                <img width='250' src={ `${process.env.PUBLIC_URL}/assets/images/email-design-template.png` }
                    alt='email-design-template'/>
            </div>
            <div id={ `${anchorMenuMetaData.data[1].id}` }><Heading>Overview</Heading></div>
            <div>
                <Paragraph>
                    Your email should have a clear purpose and goal. Emails are usually sent as a reminder or to
                    encourage next steps in a transaction.
                </Paragraph>
                <Paragraph>
                    People get multiple emails a day from various places. To make an impact, it’s important ours are
                    easy to understand and action.
                </Paragraph>
                <Paragraph>
                    Simple, consistent content also helps build trust. This can create better customer experiences and
                    outcomes.
                </Paragraph>
            </div>
            <div id={ `${anchorMenuMetaData.data[2].id}` }><Heading>Elements and character limits</Heading></div>
            <div>
                <Paragraph>All emails will have:</Paragraph>
                <UnorderedList>
                    <ListItem>subject line</ListItem>
                    <ListItem>logos</ListItem>
                    <ListItem>body content</ListItem>
                    <ListItem>greeting</ListItem>
                    <ListItem>call to action</ListItem>
                    <ListItem>sign off</ListItem>
                    <ListItem>footer</ListItem>
                </UnorderedList>
                <Paragraph>We’ve suggested character-count limits based on best practice and research.
                    To check your character counts or word lengths, you can
                    use <TextLink target='_blank' href='https://www.charactercountonline.com/'>
                        Character Count Online</TextLink>.</Paragraph>
            </div>
            <div><SubHeading>Subject line</SubHeading></div>
            <div>
                <Paragraph>A subject line explains what the email is about. It needs to be clear and relevant to entice
                    the customer to open it. Subject lines should be in sentence case.</Paragraph>
                <Paragraph>Examples include:</Paragraph>
                <UnorderedList>
                    <ListItem>How to apply for your driver licence</ListItem>
                    <ListItem>Your Driver Knowledge Test expires soon</ListItem>
                </UnorderedList>
                <Paragraph>Character limits: 28 to 50 characters.</Paragraph>
            </div>
            <div><SubHeading>Logos</SubHeading></div>
            <div>
                <img width='287' height='69' src={ `${process.env.PUBLIC_URL}/assets/images/dual-logo-white-bg.png` } alt='dual-logo'/>
            </div>
            <div>
                <img width='287' height='68' src={ `${process.env.PUBLIC_URL}/assets/images/snsw-logo-white-bg.png` } alt='snsw-logo'/>
            </div>
            <div>
                <img width='287' height='68' src={ `${process.env.PUBLIC_URL}/assets/images/nswgov-logo-white-bg.png` } alt='nswgov-logo'/>
            </div>
            <div>
                <Paragraph>Our logo options are:</Paragraph>
                <Paragraph>1. Service NSW logo only<br/>
                    2. NSW Government Waratah logo only<br/>
                    3. Both NSW and Service NSW logos
                </Paragraph>
            </div>
            <div><SubHeading>Body content</SubHeading></div>
            <div>
                <Paragraph>Use plain English and keep the content simple and direct. For example, we do not use emotive
                    language such as, ‘we're pleased to inform you’.</Paragraph>
                <Paragraph>Character limits: 25 to 125 words.</Paragraph>
            </div>
            <div><SubHeading>Greeting</SubHeading></div>
            <div>
                <Paragraph>All emails start with ‘Hello’. This reflects our everyday tone.
                    It’s not formal, like ‘Dear’ or casual, like ‘Hi' or 'Hey’.</Paragraph>
                <Paragraph>Address your customer by their first name, followed by a comma. For example:</Paragraph>
                <UnorderedList>
                    <ListItem>Hello Arash,</ListItem>
                    <ListItem>Hello Jim,</ListItem>
                </UnorderedList>
            </div>
            <div><SubHeading>First sentence</SubHeading></div>
            <div>
                <Paragraph>This immediately tells the customer what they need to know. Get straight to the point and use
                    direct language such as:</Paragraph>
                <UnorderedList>
                    <ListItem>Your access to the Driver Knowledge Test (DKT) online has expired.</ListItem>
                    <ListItem>We're reviewing your [Transaction name] claim.</ListItem>
                </UnorderedList>
                <Paragraph>Character limits: 40 to 100 characters.</Paragraph>
            </div>
            <div><SubHeading>Call to action</SubHeading></div>
            <div>
                <Paragraph>Your email should only have 1 call to action.
                    Using more may confuse the customer about what they need to do next.</Paragraph>
                <Paragraph>We try to avoid CTA buttons as they can be used in phishing attempts.</Paragraph>
            </div>
            <div><SubHeading>Sign off</SubHeading></div>
            <div>
                <Paragraph>Always sign off with:</Paragraph>
                <Paragraph>Thank you,<br/>Service NSW</Paragraph>
            </div>
            <div><SubHeading>Footer</SubHeading></div>
            <div>
                <img width='100%' src={ `${process.env.PUBLIC_URL}/assets/images/email-footer-design.png` } alt='email-footer-design'/>
            </div>
            <div>
                <Paragraph>Our footer options are based on different customer journeys.</Paragraph>
                <Paragraph>They are:</Paragraph>
                <UnorderedList>
                    <ListItem>not opted in but consent is not required</ListItem>
                    <ListItem>opted in but cannot opt out via MyServiceNSW Account preferences</ListItem>
                    <ListItem>opted in and can opt out via MyServiceNSW Account preferences.</ListItem>
                </UnorderedList>
            </div>
            <div id={ `${anchorMenuMetaData.data[3].id}` }><Heading>Writing guidance</Heading></div>
            <div>
                <Paragraph>Your email content should focus on the needs of the customer.
                    When writing copy, refer to GEL content guidelines.</Paragraph>
            </div>
            <div><SubHeading>Headings</SubHeading></div>
            <div>
                <Paragraph>Avoid headings in your content. If you do need them, do not use H3 or H2.</Paragraph>
            </div>
            <div><SubHeading>Using links</SubHeading></div>
            <div>
                <Paragraph>Use full URLs, instead of hyperlinked words or shortened hyperlinks.
                    For example, <TextLink target='_blank'
                    href='https://www.service.nsw.gov.au/'>
                        https://www.service.nsw.gov.au/</TextLink> instead of <TextLink target='_blank'
                    href='https://www.service.nsw.gov.au/'>Service
                        NSW</TextLink>.</Paragraph>
                <Paragraph>Shortened links are a feature of phishing attempts.</Paragraph>
            </div>
            <div><SubHeading>Icons</SubHeading></div>
            <div>
                <Paragraph>We recommend you do not use icons in emails. If you do, use system icons instead of brand
                    icons.</Paragraph>
            </div>
            <div><SubHeading>Mobile first</SubHeading></div>
            <div>
                <Paragraph>Many customers read emails on a mobile device.
                    When creating mockups or reviewing content with stakeholders, use mobile designs instead of
                    desktop.</Paragraph>
            </div>
            <div id={ `${anchorMenuMetaData.data[4].id}` }><Heading>Dos and don'ts</Heading></div>
            <div>
                <Paragraph>Do use:</Paragraph>
                <UnorderedList>
                    <ListItem>inclusive language and plain English</ListItem>
                    <ListItem>active voice and first-person terms (you, your)</ListItem>
                    <ListItem>consistent wording in the end-to-end journey</ListItem>
                    <ListItem>full URLs</ListItem>
                    <ListItem>bullet lists to break up text</ListItem>
                </UnorderedList>
                <Paragraph>Do not:</Paragraph>
                <UnorderedList>
                    <ListItem>link customers to a login page</ListItem>
                    <ListItem>use shortened links or hyperlink words</ListItem>
                    <ListItem>over explain or give unnecessary details</ListItem>
                    <ListItem>have more than 1 call to action</ListItem>
                    <ListItem>use H3 or H2 headings</ListItem>
                    <ListItem>use icons unless absolutely necessary</ListItem>
                </UnorderedList>
            </div>
        </ContentContainer>
    );
};

export default ContentGuidanceEmailContentComponent;
