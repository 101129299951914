import styled from 'styled-components';
import {Heading, Modal} from '@snsw/react-component-library';

export const PreviewModal = styled(Modal)`
  max-width: 60%;
  padding: 0px;
  margin: 0px;
  button {
    z-index: 2;
  }
`;

export const ModalButtonContainer = styled.div`
  display: flex; 
  flex-direction: row;
  justify-content: flex-end;
  column-gap: 1.25rem;
  position: sticky; 
  bottom: 0; 
  padding: 2rem;
  padding-bottom: 4px;
  margin: 0 -2rem 0 -2rem;
  background: #FFFFFF;
  flex-wrap: wrap;
  row-gap: 1.25rem;
`;

export const ModalHeading = styled(Heading)`
  position: sticky; 
  top: 0; 
  margin: -2rem -2rem -1.5rem -2rem; 
  padding: 2rem;
  background: #FFFFFF; 
  font-family: Gotham, Arial, sans-serif; 
  font-weight: 700;
  font-size: 26px;
  z-index: 1;
`;