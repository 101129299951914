import React, {useMemo} from 'react';
import {Link} from 'react-router-dom';
import {Button} from '@snsw/react-component-library';
import SearchBar from '../CommonComponents/SearchBar/SearchBar';
import PropTypes from 'prop-types';
import {CSVLink} from 'react-csv';
import styled from 'styled-components';
import {IconAdd} from '@snsw/react-component-library/build/Icons/system';

const DownloadButton = styled(CSVLink)`
  background-image: url("/assets/images/download-icon.png");
  background-repeat: no-repeat;
  background-position: center;
  width: 53px;
  height: 48px;
  padding: 16px 24px 16px 24px;
  border: 2px solid #DEE3E5;
  border-radius: 5px;
  display: flex;
  align-self: center;
`;

const headers = [
    {label: 'Name', key: 'fullName'},
    {label: 'Email', key: 'email'},
    {label: 'Role', key: 'userRoles[0].name'},
    {label: 'Agencies', key: 'dataAccessGroups'},
    {label: 'Team', key: 'userTeams'}
];

const ToolbarComponent = (props) => {
    const searchKeys = useMemo(() => ['fullName', 'email'], []);

    return (
        <>
            <div style={ {display: 'flex', alignItems: 'baseline', width: '100%', justifyContent: 'space-between'} }>
                <div style={ {display: 'flex', alignItems: 'center'} }>
                    <SearchBar setResults={ props.setDisplayedUsers }
                        dataSource={ props.userList }
                        dataSetName={ 'user' }
                        searchKeys={ searchKeys }/>
                    <DownloadButton filename={ 'portal-users.csv' } headers={ headers } data={ props.displayedUsers }>
                        <title>Download user list</title>
                    </DownloadButton>
                </div>
                <Button variant='primary' as={ Link } href='/users/add-user'
                    style={
                        {
                            textDecoration: 'none',
                            margin: '24px 0 24px 0',
                            display: 'flex',
                            justifySelf: 'right'
                        }
                    }
                ><IconAdd style={ {marginRight: '8px'} }/>
                    Add user
                </Button>
            </div>
        </>
    );
};

ToolbarComponent.propTypes = {
    setDisplayedUsers: PropTypes.func,
    userList: PropTypes.array,
    displayedUsers: PropTypes.array
};
export default ToolbarComponent;