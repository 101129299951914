import React, {createContext, useContext, useState} from 'react';

export const GlobalContext = createContext(false);

export const UserContext = createContext(undefined);

export const scrollToTop = () => {
    window.scrollTo({top: 0, left: 0, behavior: 'auto'});
};

export const imagePath = `${process.env.PUBLIC_URL}/assets/icons/`;
export const orderButtons = (altText, columnName, onClick) => {
    return <div style={ {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center', // Align items vertically
        justifyItems: 'left',
        position: 'relative',
        paddingRight: '5px',
        gap: '2px',
        cursor: 'pointer',
        width: 'fit-content' // Adjust width as needed
    } } onClick={ () => onClick(columnName) }>
        <div style={ {height: '20px'} }>{columnName} &nbsp;</div>
        <div style={ {display: 'flex', flexDirection: 'column'} }>
            <img
                src={ `${imagePath}orderUp.svg` }
                alt={ altText }
                style={ {marginBottom: '2px'} } // Add margin to create space between images
            />
            <img
                src={ `${imagePath}orderDown.svg` }
                alt={ altText }
            />
        </div>
    </div>;
};

export const dataPath = `${process.env.PUBLIC_URL}/assets/data/`;

export const GlobalState = props => {
    const [inProd, setInProd] = useState(false);
    const [userRole, setUserRole] = useState(undefined);

    // create a function that'll make it easy to update one state property at a time
    const updateGlobalState = (newValue) => {
        setInProd(newValue);
    };

    return (
        <UserContext.Provider value={ {userRole, setUserRole} }>
            <GlobalContext.Provider value={ [inProd, updateGlobalState] }>
                {props.children}
            </GlobalContext.Provider>
        </UserContext.Provider>
    );
};

export const useGlobalState = () => useContext(GlobalContext);
export const useUserRoleState = () => useContext(UserContext);