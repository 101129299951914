import React from 'react';
import FormGroup from '@snsw/react-component-library/build/Components/FormGroup/FormGroup';
import { ContentSubHeader, TextArea } from './RecipientsComponent.styled';
import PropTypes from 'prop-types';

const RecipientTextArea = (props) => {
    return (
        <FormGroup
            label={ props.label }
            helpMessage={ props.helpMessage }
            id={ props.id }
            hasError={ props.inputError.hasError }
            errorMessage={ props.inputError.errorMessage }
        >
            <TextArea
                data-testid={ props.dataTestId }
                value={ props.value }
                rows='medium'
                onChange={ props.recipientCallback }
                name={ props.name }
            />
        </FormGroup>
    );
};
RecipientTextArea.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    helpMessage: PropTypes.string,
    id: PropTypes.string,
    dataTestId: PropTypes.string,
    recipientCallback: PropTypes.func,
    inputError: PropTypes.object,
};

const RecipientsComponent = ({notificationRequest, recipientCallback, inputErrors}) => {

    return <div style={ { maxWidth: '75%' } }><p/>
        <ContentSubHeader>
            Use one or more of the following fields to define who should receive this notification.
        </ContentSubHeader>

        <RecipientTextArea
            name='customerIds'
            label='Customer ID(s)'
            value={ notificationRequest.customerIds.length ? notificationRequest.customerIds.join(',') : '' }
            helpMessage='Customer IDs (use comma-separated values)'
            id='recipient-customer-id'
            dataTestId='customer-text-area'
            recipientCallback= { recipientCallback }
            inputError={ inputErrors.customerIds }
        />
        <br/>
        {notificationRequest.sms.content.trim().length > 0 &&
            <RecipientTextArea
                name='mobileNumbers'
                label='Phone number(s)'
                value={ notificationRequest.mobileNumbers ? notificationRequest.mobileNumbers.join(',') : '' }
                helpMessage='Phone numbers (use comma-separated values)'
                id='recipient-phone-number'
                dataTestId='phone-number-text-area'
                recipientCallback={ recipientCallback }
                inputError={ inputErrors.mobileNumbers }
            />
        }
        <br/>
        {(notificationRequest.email.content.trim().length > 0 && notificationRequest.email.subject.trim().length > 0) &&
            <RecipientTextArea
                name='emails'
                value={ notificationRequest.emails ? notificationRequest.emails.join(',') : '' }
                label='Email address(es)'
                helpMessage='Email addresses (use comma-separated values)'
                id='recipient-email-address'
                dataTestId='email-text-area'
                recipientCallback={ recipientCallback }
                inputError={ inputErrors.emails }
            />
        }
    </div>;
};
RecipientsComponent.propTypes = {
    notificationRequest: PropTypes.object,
    recipientCallback: PropTypes.func,
    inputErrors: PropTypes.object,
};

export default RecipientsComponent;
