import React from 'react';
import AnchorMenuComponent from '../CommonComponents/AnchorMenuComponent/AnchorMenuComponent';
import AnchorMenuLayoutComponent from '../CommonComponents/AnchorMenuLayoutComponent/AnchorMenuLayoutComponent';
import APIDocsContentComponent from './APIDocsContentComponent';

const APIDocsComponent = () => {

    const anchorMenuMetaData = {
        title: 'On this page',
        data: [
            {id: 'menu-introduction', title: 'Introduction', level: 0},
            {id: 'menu-step1', title: 'Step 1: Create Notification', level: 1},
            {id: 'menu-step1-notification-codes', title: 'Notification codes', level: 2},
            {id: 'menu-step1-variables', title: 'Variables', level: 2},
            {id: 'menu-step1-conditional-text', title: 'Conditional text', level: 2},
            {id: 'menu-step2', title: 'Step 2: Get Authorisation', level: 1},
            {id: 'menu-step2-internal-team', title: 'Service NSW teams', level: 2},
            {id: 'menu-step2-external-team', title: 'Non-Service NSW teams', level: 2},
            {id: 'menu-step3', title: 'Step 3: Make API Request', level: 1},
            {id: 'menu-step3-environment', title: 'Environment', level: 2},
            {id: 'menu-step3-authentication', title: 'Authentication', level: 2},
            {id: 'menu-step4', title: 'Step 4: Send your notification', level: 1},
            {id: 'menu-step4-endpoints', title: 'Endpoints', level: 2},
            {id: 'menu-reporting', title: 'Reporting', level: 1},
            {id: 'menu-preference', title: 'Preferences', level: 1},
            {id: 'menu-troubleshooting', title: 'Troubleshooting', level: 1},
            {id: 'menu-help', title: 'Help', level: 1}
        ]
    };

    return (
        <AnchorMenuLayoutComponent
            title='API Docs'
            contentComponent={ APIDocsContentComponent }
            anchorMenuComponent={ AnchorMenuComponent }
            anchorMenuMetaData={ anchorMenuMetaData }
        />
    );
};

export default APIDocsComponent;