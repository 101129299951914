import styled from 'styled-components';
import {
    globalFont,
    fontWeight,
    colors, fontSize, lineHeight
} from '../Typography/Typography.styled';

export const AnchorMenuContainer = styled.div`
    display: flex;
    flex-direction: column;
    font-family: ${globalFont};
    position: sticky;
    top: 0;
`;

export const AnchorMenuTitle = styled.div`
    > h6 {
        color: ${colors.brand.snswPrimaryBlue};
    }
`;

export const AnchorMenuBody = styled.div`
    padding-top: 12px;
    display: flex;
    flex-direction: column;
`;

export const AnchorMenuItem = styled.div`
    display: flex;
    gap: 16px;
`;

export const AnchorMenuGap = styled.div`
    display: flex;
    height: 10px;
`;

export const AnchorMenuProgressStack = styled.div`
    width: 2px;
    background-color: ${colors.grey.geyser};
`;

export const AnchorMenuDiv = styled.div`
    width: 100%;
    font-weight: ${fontWeight.regular};
    font-size: ${fontSize.body};
    line-height: ${lineHeight.body};
    color: ${colors.grey.shuttle};
`;

export const AnchorMenuLink = styled.a`

    cursor: pointer;
    text-decoration: none !important;
    color: unset;
    
    &:hover {
        color: ${colors.brand.snswPrimaryBlue};
    }
`;