import styled from 'styled-components';
import {
    colors,
    globalFont,
    Heading1,
    screenSize
} from '../../CommonComponents/Typography/Typography.styled';

const adjustableSize = screenSize.tablet;

export const ComponentWrapper = styled.div`
    padding: 145px 0px;
    display: grid;
    grid-template-columns: auto 37%;
    gap: 40px 0px;
    font-family: ${globalFont};
    
    @media only screen and (max-width: ${adjustableSize}) {
        padding: 56px 0px;
        grid-template-columns: auto;
        gap: 24px 0px;
    }
`;

export const HeadingWrapper = styled.div`
    grid-column: 1 / span 2;
`;
export const Heading = styled(Heading1)`
    font-size: 48px;
    line-height: 60px;
    color: ${colors.grey.darkGrey};

    @media only screen and (max-width: ${adjustableSize}) {
        font-size: 28px;
        line-height: 36px;
    }
`;

export const Body = styled.div`
    display: flex;
    flex-direction: column;
    gap: 38px;
    
    > div {
        display: flex;
        gap: 20px;

        > div:nth-child(1) {
            width: 50px;
            height: 50px;
            align-self: center;
        }
        
        > div:nth-child(2) {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            align-self: center;
        }
        
        > div svg {
            width: 50px;
            height: 50px;
        }
    }
`;

export const ImgWrapper = styled.div`
    grid-column: 2;
    grid-row: 2 / span 1;
    @media only screen and (max-width: ${adjustableSize}) {
        display: none;
    }
`;

export const Img = styled.img`
    width: 100%;
    objectFit: cover;
`;
