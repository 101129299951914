import Col from '@snsw/react-component-library/build/layout/Grid/Col.styled';
import Heading from '@snsw/react-component-library/build/Components/Headings/Heading';
import FormInput from '@snsw/react-component-library/build/Components/Form/FormInput';
import Fieldset from '@snsw/react-component-library/build/Components/Form/Fieldset';
import {Textarea} from '@snsw/react-component-library';
import Button from '@snsw/react-component-library/build/Components/Button/Button';
import FormSelect from '@snsw/react-component-library/build/Components/Form/FormSelect';
import Row from '@snsw/react-component-library/build/layout/Grid/Row.styled';
import React from 'react';
import {
    inboxFormField,
    validateTextField
} from '../CreateNotificationComponent/CreateNotificationComponent';
import DOMPurify from 'dompurify';

const InboxContentFormComponent = ({
    inputErrors,
    setInputErrors,
    handleChange,
    notificationConfig,
    setNotificationConfig,
    inboxFooterOptions,
    inboxContentValidation,
    setShowInboxPreviewModal,
    inProd
}) => {
    const isInProd = (inProd != null) ? inProd : false;
    const validateInboxContent = async () => {
        inboxContentValidation.current = true;
        setInputErrors(prevState => ({
            ...prevState,
            inboxContent: {hasError: false, errorMessage: 'Error'},
        }));
        const validContent = DOMPurify.sanitize(notificationConfig.inboxContent);
        setNotificationConfig(prevState => ({
            ...prevState,
            inboxContent: validContent
        }));
    };
    const openPreview = async () => {
        let isValid = true;
        if (!validateTextField(notificationConfig.inboxSubject, 'inboxSubject', setInputErrors)) {
            isValid = false;
        }
        if (notificationConfig.inboxFooterTemplate.length === 0) {
            setInputErrors(prevState => ({
                ...prevState,
                inboxFooterTemplate: {
                    hasError: true,
                    errorMessage: 'Select a footer to preview or create notification'
                }
            }));
            isValid = false;
        }
        if (notificationConfig.inboxContent.length === 0) {
            setInputErrors(prevState => ({
                ...prevState,
                inboxContent: {
                    hasError: true,
                    errorMessage: 'Enter inbox body content in html format'
                }
            }));
            isValid = false;
        }
        if (isValid) {
            setShowInboxPreviewModal(true);
        } else {
            const errorElement = document.querySelectorAll('span[class^=\'FormError\']')[0]?.previousSibling;
            errorElement?.scrollIntoView({behavior: 'smooth'});
        }
    };
    return (
        <>
            <Row style={ {paddingBottom:'4px'} }>
                <Col span={ 9 }>
                    <Heading level={ 4 } style={ { height: 'fit-content', margin: '24px 0 16px 0'} }>
                        {inboxFormField.heading}
                    </Heading>
                    <FormInput
                        label={ inboxFormField.subject.label }
                        name={ inboxFormField.subject.name }
                        placeholder={ inboxFormField.subject.placeholder }
                        errorMessage={ inputErrors.inboxSubject.errorMessage }
                        hasError={ inputErrors.inboxSubject.hasError }
                        helpMessage={ inboxFormField.subject.helpMessage }
                        margin={ { top: 16 } }
                        onBlur={ () => validateTextField(notificationConfig.inboxSubject, 'inboxSubject', setInputErrors) }
                        onChange={ handleChange }
                        disabled={ isInProd }
                        value={ notificationConfig.inboxSubject }
                    />
                    <Fieldset
                        legend= { inboxFormField.content.legend }
                        smallLegend
                        margin={ {top: 16 } }
                        errorMessage={ inputErrors.inboxContent.errorMessage }
                        helpMessage={ inboxFormField.content.helpMessage }
                        hasError={ inputErrors.inboxContent.hasError }
                    >
                        <Textarea
                            label={ inboxFormField.content.label }
                            name={ 'inboxContent' }
                            placeholder={ inboxFormField.content.placeholder }
                            data-testid='inboxTextArea'
                            hasError={ inputErrors.inboxContent.hasError }
                            rows={ 'medium' }
                            onChange={ handleChange }
                            disabled={ isInProd }
                            value={ notificationConfig.inboxContent }
                        />
                    </Fieldset>
                    <Button
                        onClick={ validateInboxContent }
                        name={ 'inboxContent' }
                        data-testid={ 'inboxHTMLValidation' }
                        variant='secondary'
                        id='inboxSanitizerBtn'
                        style={ {margin: '10px 0 0 0'} }>
                        Check HTML
                    </Button>
                    <FormSelect
                        label={ inboxFormField.footer.label }
                        name={ 'inboxFooterTemplate' }
                        placeholder= { inboxFormField.footer.placeholder }
                        errorMessage={ inputErrors.inboxFooterTemplate.errorMessage }
                        hasError={ inputErrors.inboxFooterTemplate.hasError }
                        options={ inboxFooterOptions }
                        margin={ {top: 16} }
                        onChange={ handleChange }
                        disabled={ isInProd }
                        value={ notificationConfig.inboxFooterTemplate }
                    />
                    <div style={
                        {
                            display: 'flex',
                            flexWrap: 'nowrap',
                            justifyContent: 'start',
                            flexDirection: 'column',
                            rowGap: '32px',
                            marginTop: '32px'
                        }
                    }>
                        <div style={
                            {
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                columnGap: '25px'
                            }
                        }>
                            <Button
                                data-testid='previewInboxDesignButton'
                                variant='secondary'
                                onClick={ openPreview }
                            >
                                Preview inbox
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default InboxContentFormComponent;