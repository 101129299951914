import styled from 'styled-components';
import {IconClose} from '@snsw/react-component-library/build/Icons/system';
import {colors, fontSize, fontWeight, globalFont} from '../Typography/Typography.styled';

export const SelectLabel = styled.label`
    font-weight: 500;
    font-size: ${fontSize.body};
    display: block;
    color: ${colors.brand.snswDarkGrey};
    line-height: 18px;
    margin-top: 16px;
    margin-bottom: 4px;
    font-feature-settings: "clig" off, "liga" off;
    font-family: ${globalFont};
`;
export const SelectSpanError = styled.span`
    font-family: ${globalFont};
    font-size: ${fontSize.body};
    display: block;
    color: ${colors.status.errorRed.default};
    margin-top: 0.5rem;
    padding-left: 1.75rem;
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23b81237' d='M8 16A8 8 0 118 0a8 8 0 010 16zm1-8V5a1 1 0 10-2 0v3a1 1 0 102 0zm-.29 3.71a1.004 1.004 0 10-1.42-1.42 1.004 1.004 0 001.42 1.42z'/%3E%3C/svg%3E") 0 4px no-repeat;
`;

export const CustomIconClose = styled(IconClose)`
    width: 9px; 
    height: 9px;
    fill: ${colors.brand.snswPrimaryBlue};
    > svg {
        fill: ${colors.brand.snswPrimaryBlue};
    }
    margin-right: 2px;
`;

export const SelectCountCircle = styled.span`
    margin-left: 8px;
    background-color: ${colors.brand.snswInfoBlueLight};
    border-radius: 50%;
    font-size: ${fontSize.bodyXS};
    font-weight: ${fontWeight.bold};
    display: inline-block;
    width: 20px;
    line-height: 20px;
    color: ${colors.brand.snswPrimaryBlue};
    text-align: center;
`;