import React from 'react';
import {setGlobalNavUri} from '@snsw/react-global-nav';
import SidebarMenu from '../SidebarMenu/SidebarMenu';
import {useOktaAuth} from '@okta/okta-react';
import PropTypes from 'prop-types';
import {useUserRoleState} from '../GlobalStateComponent/GlobalState';
import WrapperComponent from './WrapperComponent';

setGlobalNavUri(
    `${process.env.REACT_APP_GLOBALNAV_DOMAIN}`,
    {'x-api-key': `${process.env.REACT_APP_GLOBALNAV_APIKEY}`}
);

const GlobalNavWrapper = (props) => {
    const {component: Component = false} = props;
    const {authState} = useOktaAuth();
    const {userRole} = useUserRoleState();

    return (
        <div style={ {display: 'flex', flexDirection: 'row'} }>
            {authState && authState.isAuthenticated &&
                <div style={ {flex: '0 0 auto', width: '264px'} }>
                    <SidebarMenu title='Notifications Portal' showInProdSelector={ true }/>
                </div>}
            <WrapperComponent restricted={ props.restricted } userRole={ userRole } component={ Component }/>
        </div>
    );
};

GlobalNavWrapper.propTypes = {
    restricted: PropTypes.bool
};
export default GlobalNavWrapper;
