import React, {useState} from 'react';
import {
    TableCell,
    TableRow,
    Button
} from '@snsw/react-component-library';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import { StatusLabel } from '@snsw/react-component-library';
import { useUserRoleState} from '../GlobalStateComponent/GlobalState';
import {portalConfigStatus} from '../../constants/applicationConstants';
import ComponentLoader from '@snsw/react-component-library/build/Loader/ComponentLoader';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import {CustomisedEditIcon} from '../AgencyListItem/AgencyListItem';

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip { ...props } classes={ { popper: className } } />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

const NotificationListItem = props => {
    const {userRole} = useUserRoleState();

    const [pageLoading] = useState({status: false, label: ''});

    return (
        <TableRow key={ props.index }>
            { pageLoading.status && <ComponentLoader fullPage label={ pageLoading.label }/> }
            <TableCell key={ props.index + '.1' } style={ {wordBreak: 'break-word'} }>
                { props.notification.description }
            </TableCell>
            <TableCell key={ props.index + '.2' } >
                <div style={ { display: 'flex', alignItems: 'center', gap: '1rem' } }>
                    { props.notification.notificationCode }
                </div>
            </TableCell>
            <TableCell key={ props.index + '.3' } >
                { (props.notification.status === portalConfigStatus.IN_REVIEW) && <LightTooltip
                    title={ `Notification is currently being reviewed by the notifications team in
                         ${process.env.REACT_APP_ENV_SRC} environment.` }>
                    <div><StatusLabel text='In review' variant='warning'/></div></LightTooltip>
                }
                { (props.notification.status === portalConfigStatus.LIVE) && <LightTooltip
                    title={ 'Notification is in production and ready to send' }>
                    <div><StatusLabel text='Live' variant='success'/></div></LightTooltip>
                }
                { (props.notification.status === portalConfigStatus.ARCHIVED) && <LightTooltip
                    title={ 'Notification is currently being archived.' }>
                    <div><StatusLabel text='Archived' variant='warning'/></div></LightTooltip>
                }
            </TableCell>
            <TableCell key={ props.index + '.4' } >
                <div style={ { display: 'flex', alignItems: 'center', justifyContent: 'space-between' } }>
                    {userRole.permissions.NOTIFICATION.includes('UPDATE') &&
                        <Button variant='link' id={ `editNotificationSettingsBtn-${props.notification.notificationCode}` }
                            data-testid={ `editNotificationSettingsBtn-${props.notification.notificationCode}` }
                            as={ Link } href={ `/update-notification/${props.notification.notificationCode}?from=service` }
                            style={ { pointerEvents: 'auto'} }>
                        Edit
                            <CustomisedEditIcon/>
                        </Button>
                    }
                </div>
            </TableCell>
        </TableRow>
    );
};

NotificationListItem.propTypes = {
    index:PropTypes.number,
    serviceCode: PropTypes.string,
    notification: PropTypes.object,
    agency:PropTypes.object,
    key: PropTypes.string
};

export default NotificationListItem;
