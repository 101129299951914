import React from 'react';
import PropTypes from 'prop-types';
import PreviewInboxContentComponent from '../PreviewInboxContentComponent/PreviewInboxContentComponent';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import {PreviewModal, ModalButtonContainer, ModalHeading} from './PreviewContentModal.styled';
import {notificationChannel} from '../../constants/applicationConstants';
import PreviewEmailContentComponent from '../PreviewEmailContentComponent/PreviewEmailContentComponent';
import {Button} from '@snsw/react-component-library';
import { IconDownload } from '@snsw/react-component-library/build/Icons/system';
import PreviewPushContentComponent from '../PreviewPushContentComponent/PreviewPushContentComponent';
import PreviewSmsContentComponent from '../PreviewSmsContentComponent/PreviewSmsContentComponent';

const PreviewContentModal = (props) => {

    const closePreview = async () => {
        props.setShowPreviewModal(false);
    };

    const savePDF = async () => {
        const preview = document.getElementById('previewModalContent');

        if (props.channel === notificationChannel.PUSH) {
            changePushBackgroundColor('rgb(207, 190, 190, 90%)');
        }

        html2canvas(preview).then(function(canvas) {
            const pdf = new jsPDF();
            const filename = props.notificationCode ? props.notificationCode : 'content';
            const divImage = canvas.toDataURL('image/png');
            const imgWidth = 150;
            const heightOfPage = 295;
            const imgHeight = canvas.height * imgWidth / canvas.width;
            let heightLeft = imgHeight;
            let position = 0;

            pdf.addImage(divImage, 'PNG', 10, position+10, imgWidth, imgHeight);
            heightLeft -= heightOfPage;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight + 10;
                pdf.addPage();
                pdf.addImage(divImage, 'PNG', 10, position, imgWidth, imgHeight);
                heightLeft -= heightOfPage;
            }
            pdf.save(`${filename}.pdf`);

            if (props.channel === notificationChannel.PUSH) {
                changePushBackgroundColor('rgba(255, 255, 255, 0.4)');
            }
        });
    };

    // The PDF displays color values differently to the browser, to make the PDF look visually similar to the browser,
    // the background color of the push notification content is changed for the PDF creation and then returned to its
    // original color using this function
    const changePushBackgroundColor = (color) => {
        const pushNotificationContent = document.querySelector('[data-testid=notification-content]');
        pushNotificationContent.style.backgroundColor = color;
        return true;
    };

    return (
        <>
            <PreviewModal
                title={ '' }
                onClose={ closePreview }
                buttons={ [] }
                buttonStyle={ { zIndex: 3} }
            >
                <ModalHeading>
                    {props.channel === notificationChannel.INBOX && 'Inbox notification preview'}
                    {props.channel === notificationChannel.EMAIL && 'Email notification preview'}
                    {props.channel === notificationChannel.PUSH && 'Push notification preview'}
                    {props.channel === notificationChannel.SMS && 'SMS notification preview'}
                </ModalHeading>
                <div id={ 'previewModalContent' } style={ { marginTop:'0.25rem' } }>
                    { props.channel === notificationChannel.INBOX &&
                        <PreviewInboxContentComponent
                            inboxSubject={ props.inboxPreviewSubstitutes.inboxSubject }
                            inboxContent={ props.inboxPreviewSubstitutes.inboxContent }
                            inboxFooter={ props.inboxPreviewSubstitutes.inboxFooter }
                            sender={ props.sender }
                            inProd={ props.inProd }
                            serviceName={ props.service }
                        />
                    }
                    { props.channel === notificationChannel.EMAIL &&
                        <PreviewEmailContentComponent
                            emailBodyWrapper={ props.emailPreviewSubstitutes.emailBodyWrapper }
                            emailContent={ props.emailPreviewSubstitutes.emailContent }
                            emailFooter={ props.emailPreviewSubstitutes.emailFooter }
                            emailHeader={ props.emailPreviewSubstitutes.emailHeader }
                            emailFeedback={ props.emailPreviewSubstitutes.emailFeedback }
                            emailSubject={ props.emailPreviewSubstitutes.emailSubject }
                            sender={ props.sender }
                            inProd={ props.inProd }
                            serviceName={ props.service }
                        />
                    }
                    { props.channel === notificationChannel.PUSH &&
                        <PreviewPushContentComponent
                            pushSubject={ props.pushPreviewSubstitutes.pushSubject }
                            pushContent={ props.pushPreviewSubstitutes.pushContent }
                        />
                    }
                    { props.channel === notificationChannel.SMS &&
                        <PreviewSmsContentComponent
                            smsContent={ props.smsContent }
                        />
                    }
                </div>
                <ModalButtonContainer>
                    <Button variant='tertiary' onClick={ closePreview }>Close preview</Button>
                    <Button variant='secondary' onClick={ savePDF }>Save as PDF
                        <IconDownload color='secondaryBlue' style={ { marginLeft: '0.5rem' } }></IconDownload>
                    </Button>
                </ModalButtonContainer>
            </PreviewModal>
        </>
    );
};

PreviewContentModal.propTypes = {
    channel: PropTypes.string,
    inboxPreviewSubstitutes: PropTypes.object,
    emailPreviewSubstitutes: PropTypes.object,
    pushPreviewSubstitutes: PropTypes.object,

    sender: PropTypes.string,
    service: PropTypes.string,
    notificationCode: PropTypes.string,
    setShowPreviewModal: PropTypes.func
};

export default PreviewContentModal;