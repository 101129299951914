import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import apiServices from '../../services/api-services';
import {
    InPageAlert,
    Heading
} from '@snsw/react-component-library';
import {
    scrollToTop,
} from '../GlobalStateComponent/GlobalState';
import {alertTimeoutValues} from '../../constants/applicationConstants';
import UserListItem from '../UserListItem/UserListItem';
import ToolbarComponent from '../ToolbarComponent/ToolbarComponent';
import UserListComponent from '../UserListComponent/UserListComponent';
import styled from 'styled-components';
import ComponentLoader from '@snsw/react-component-library/build/Loader/ComponentLoader';

const UserManagementHeading = styled(Heading)`
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  padding-top: unset;
  margin-bottom: 0;
  margin-top: 24px;
  color: #002664;
`;

const UserManagementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  flex-wrap: wrap;
  width: 100%;
`;
const UserManagementComponent = () => {
    const { state } = useLocation();
    const [userList, setUserList] = useState([]);
    const [displayedUsers, setDisplayedUsers] = useState([]);
    const [pageLoading, setPageLoading] = useState({status: true, label: 'Loading'});
    const [alert, setAlert] = useState( state && state.visible? {
        variant: state.variant,
        title: state.title,
        description: state.description,
        visible: state.visible
    }: { variant: '',
        title: '',
        description: '',
        visible: false
    });
    useEffect(() => {
        scrollToTop();
        apiServices.fetchUserList().then(fetchedUserList => {
            setUserList(fetchedUserList);
            setPageLoading(false);
        });
    }, []);
    useEffect(() => {
        if(alert.visible) {
            const timer = setTimeout(() =>{
                setAlert(prevState => ({
                    ...prevState,
                    visible: false
                }));
            }, alertTimeoutValues[alert.variant]);
            return () => {
                clearTimeout(timer);
            };
        }
    },[alert]);

    return (
        <>
            <UserManagementWrapper>
                <UserManagementHeading
                    level={ 1 }
                    id='UserListHeading'>
                    <strong>User Management</strong>
                </UserManagementHeading>
                { (alert.visible) &&
                    <InPageAlert id='user-status' variant={ alert.variant } title={ alert.title } style={ {width: '100%'} }>
                        <p id='notStatusDesc'>{ alert.description }</p>
                    </InPageAlert> }
                <ToolbarComponent setDisplayedUsers={ setDisplayedUsers }
                    displayedUsers={ displayedUsers } userList={ userList }></ToolbarComponent>

                { pageLoading.status ? <ComponentLoader fullPage label={ pageLoading.label }/> :
                    <UserListComponent displayedUsers={ displayedUsers }
                        callbackfn={ (user, index) => {
                            return (
                                <UserListItem
                                    index={ index }
                                    user={ user }
                                    key={ user.id }
                                />
                            );
                        } }/>
                }
            </UserManagementWrapper>
        </>
    );
};

export default UserManagementComponent;
