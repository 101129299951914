import DOMPurify from 'dompurify';
import {
    baseEmailTemplate
} from '../../constants/applicationConstants';
import React, {useEffect, useState} from 'react';
import {
    EmailContentContainer,
    EmailDetailContainerLabelText,
    EmailDetailContainerText,
    EmailSubjectSenderContainer
} from './PreviewEmailContentComponent.styled';

const PreviewEmailContentComponent = (props) => {
    const [previewEmailContent, setPreviewEmailContent] = useState('');

    /* Why don't we use Handlebars, Mustache, etc. to compile the templates like in the notifications microservice?
    *  The current version of Handlebars uses the eval() function to generate
    *  Javascript on the fly, which presents security issues and hence will be blocked by the Content Security Policy.
    *  Officially, the workaround would be to precompile Handlebars templates, but since we are generating them dynamically
    *  based on user input, we can't do this. Therefore, to minimise the likelihood of a 3rd party library using unsafe
    *  functions, we will use string replacement here.*/
    useEffect(() => {
        const sanitizedBody = DOMPurify.sanitize(props.emailContent);

        // Handle the #ifequals in the footer if it exists
        let emailFooterString = atob(props.emailFooter);
        if (emailFooterString.search('{{#ifequals config.agencyName "Service NSW"}}') !== -1) {
            emailFooterString = emailFooterString
                .replaceAll(new RegExp(/{{#ifequals config\.agencyName "Service NSW"}}[\S\s.]+{{\/ifequals}}/g, 'g'),
                    props.sender === 'Service NSW' ? '.' : ', on behalf of ' + props.sender + '.');
        }
        // The following is to correct for inconsistencies in Base64 encoding. The original encoding for the template
        // contents was done with character set configured for UTF-8.
        emailFooterString = emailFooterString
            .replaceAll('224 Rawson Place', '2–24 Rawson Place')
            .replaceAll('2â24 Rawson Place', '2–24 Rawson Place');

        // Place the email components into the base email template
        const emailFullTemplate = baseEmailTemplate
            .replace('{{{template.header}}}', atob(props.emailHeader))
            .replace('{{{template.feedback}}}', atob(props.emailFeedback))
            .replace('{{{template.footer}}}', emailFooterString);

        /* A note about why the email's images are loaded from static assets and not dynamically loaded from the asset URL.
           When generating the PDF, canvas.toDataURL('image/png') is called and this method will throw a CORS error if
           there is <img> content within the HTMLCanvasElement that is not from the same origin. See more info:
           https://developer.mozilla.org/en-US/docs/Web/HTML/CORS_enabled_image#security_and_tainted_canvases.
           The workaround is complicated. Additionally, dynamically downloaded images take noticeable duration 2-3s to
           load for the first time and preloading didn't help. */
        // Replace all variables in the full email
        const compiledEmail = emailFullTemplate
            .replaceAll('{{config.assetsUri}}', '/assets/images')
            .replaceAll('{{config.agencyName}}', props.sender !== null ? props.sender : 'Test Portal Agency')
            .replaceAll('{{config.serviceName}}', props.service !== null ? props.service : 'Test Portal Service')
            .replaceAll('{{customer.firstName}}', 'Jim')
            .replaceAll('{{customer.email}}', 'jim@example.com')
            .replace('{{{template.mainContent}}}', sanitizedBody);

        setPreviewEmailContent(compiledEmail);
    }, [props]);

    return <>
        <EmailContentContainer data-testid='emailContentPreviewContainer'>
            <EmailSubjectSenderContainer>
                <div id={ 'emailSenderContainer' }>
                    <EmailDetailContainerLabelText>From:</EmailDetailContainerLabelText>
                    <EmailDetailContainerText id={ 'emailSenderText' }>Service NSW</EmailDetailContainerText>
                </div>
                <div id={ 'emailSubjectContainer' }>
                    <EmailDetailContainerLabelText>Email subject:</EmailDetailContainerLabelText>
                    <EmailDetailContainerText id={ 'emailSubjectText' }>{props.emailSubject}</EmailDetailContainerText>
                </div>
            </EmailSubjectSenderContainer>
            <div data-testid='emailContent' dangerouslySetInnerHTML={ {__html: previewEmailContent} }/>
        </EmailContentContainer>
    </>;
};

export default PreviewEmailContentComponent;