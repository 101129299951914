import React from 'react';
import {
    ComponentWrapper,
    ContentWrapper,
    ImgWrapper,
    Img,
    Heading,
    Body,
    SpanBold, SpanNormal
} from './TestimonialComponent.styled';

const TestimonialComponent = () => {

    return (
        <ComponentWrapper>
            <ContentWrapper>
                <div><Heading>Testimonial</Heading></div>
                <Body>
                    {/* eslint-disable-next-line max-len */}
                    <p>“Service NSW’s notification team is great to work with. They provide quality advice on notification strategies and really try to understand your audience and what you are trying to achieve, so the content is tailored to encourage action.</p>
                    {/* eslint-disable-next-line max-len */}
                    <p>We have worked with the team on a number of projects including Digital Vehicle Registration and Driver Knowledge Test online automated notifications. Each project had different challenges but we worked through these in partnership with the team, to ensure the notifications were delivered to the highest quality.</p>
                    {/* eslint-disable-next-line max-len */}
                    <p>We were also given access to a dashboard to track their success so we have assurance that they are being received by customers.”</p>
                    {/* eslint-disable-next-line max-len */}
                    <p><SpanBold>Katie Davis-Hall-Watson</SpanBold><br/><SpanNormal>Licensing and Registration Products Manager at Transport for New South Wales</SpanNormal></p>
                </Body>
            </ContentWrapper>
            <ImgWrapper>
                <Img src={ `${process.env.PUBLIC_URL}/assets/images/testimonial.png` } alt='testimonial'></Img>
            </ImgWrapper>
        </ComponentWrapper>
    );
};

export default TestimonialComponent;
