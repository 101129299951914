import React, {useEffect, useState} from 'react';
import {
    Button,
    Heading,
    InPageAlert,
    Modal,
    SkeletonContainer,
    SkeletonHeading,
    StatusLabel,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
    tokens
} from '@snsw/react-component-library';
import {useBoolean} from '@snsw/react-component-library/build/utils';
import PropTypes from 'prop-types';
import {AccordionDetails} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {ServiceContainer, ServiceHeading, ServiceTitleContainer} from './ServiceListItem.styled';
import {Link, useNavigate} from 'react-router-dom';
import apiServices from '../../services/api-services';
import NotificationListItem from '../NotificationListItem/NotificationListItem';
import {orderButtons, useGlobalState, useUserRoleState} from '../GlobalStateComponent/GlobalState';
import {SimpleTreeView} from '@mui/x-tree-view/SimpleTreeView';
import {TreeItem} from '@mui/x-tree-view/TreeItem';
import {Card} from '@snsw/react-component-library/build/Components';
import ComponentLoader from '@snsw/react-component-library/build/Loader/ComponentLoader';
import {alertTimeoutValues, portalConfigStatus} from '../../constants/applicationConstants';
import {IconAdd} from '@snsw/react-component-library/build/Icons/system';
import {TableContainer} from '../TableContainerComponent/TableContainer';
import {CustomisedEditIcon} from '../AgencyListItem/AgencyListItem';
import {sort} from '../DashboardComponent/DashboardComponent';

const AccordionSummaryStyleOverrides = {
    padding: '1rem 1.5rem',
    maxHeight: '3.5rem',
    justifyContent: 'left',
    '.MuiAccordionSummary-root': {
        padding: '1rem 1.5rem',
        margin: 0
    },
    '.MuiAccordionSummary-content': {
        margin: 0,
        justifyContent: 'start'
    },
    '.MuiAccordionSummary-root .Mui-expanded': {
        margin: 0
    },
    '.MuiAccordionSummary-expandIconWrapper .MuiSvgIcon-root': {
        fontSize: '1.875rem'
    }
};

const ServiceListItem = props => {
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pageLoading, setPageLoading] = useState({status: false, label: ''});
    const [showPromoteToProdModal, openPromoteToProdModal, closePromoteToProdModal] = useBoolean(false);
    const [servicePendingChanges, setServicePendingChanges] = useState(undefined);
    const navigate = useNavigate();
    const [inProd] = useGlobalState();
    const {userRole} = useUserRoleState();
    const [serviceAlert, setServiceAlert] = useState({});
    const [notificationsOrder, setNotificationsOrder] = useState('ASC');

    const orderNotificationsAlphabetically = (notifications) => {
        notifications.sort(function (a, b) {
            if (a.notificationCode.toLowerCase() < b.notificationCode.toLowerCase()) {
                return -1;
            }
            if (a.notificationCode.toLowerCase() > b.notificationCode.toLowerCase()) {
                return 1;
            }
            return 0;
        });
        return notifications;
    };
    useEffect(() => {
        if (props.isExpanded) {
            apiServices.fetchNotifications(props.serviceCode, inProd)
                .then(notifications => {
                    setNotifications(orderNotificationsAlphabetically(notifications));
                    setLoading(false);
                })
                .catch(error => console.log(error.message));
        }
    }, [props.isExpanded, props.serviceCode, inProd]);

    const refreshNotifications = () => {
        apiServices.fetchNotifications(props.serviceCode, inProd)
            .then(notifications => {
                setNotifications(notifications);
                setLoading(false);
                props.refreshAllServices(true);
            })
            .catch(error => console.log(error.message));
    };

    function populateServicePendingChanges(data) {
        const serviceChangesToBePromoted = {
            id: props.serviceCode,
            name: props.serviceTitle,
            pendingChanges: data.pendingChanges,
            defaultExpanded: [props.serviceCode]
        };
        setServicePendingChanges(serviceChangesToBePromoted);
    }

    useEffect(() => {
        if (showPromoteToProdModal) {
            apiServices.compareService(props.serviceCode, false).then(data => {
                populateServicePendingChanges(data);
            })
                .catch(e => {
                    closePromoteToProdModal();
                    navigate(`/services/${props.agency.agencyCode}`, {
                        state: {
                            alert: {
                                variant: 'error',
                                title: 'Promote service to production error',
                                description: e.message,
                                visible: true
                            },
                            isExpanded: true,
                            serviceCode: props.serviceCode,
                            agency: Object.assign(props.agency)
                        }
                    });
                });
        } else {
            setServicePendingChanges(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showPromoteToProdModal]);

    useEffect(() => {
        if (serviceAlert.visible) {
            const timer = setTimeout(() => {
                setServiceAlert(prevState => ({
                    ...prevState,
                    visible: false
                }));
            }, alertTimeoutValues[serviceAlert.variant]);
            return () => {
                clearTimeout(timer);
            };
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceAlert]);

    const onPromoteToProdModelOpen = (event) => {
        event.stopPropagation();
        openPromoteToProdModal();
    };

    const onPromoteToProdModelClose = () => {
        setServicePendingChanges(undefined);
        closePromoteToProdModal();
    };

    const onPromoteToProdConfirmation = (event) => {
        event.stopPropagation();
        onPromoteToProdModelClose();
        setPageLoading({status: true, label: 'Promote to production in progress...'});
        apiServices.promoteServiceToDestination(props.serviceCode).then(data => {
            setPageLoading({status: false, label: ''});
            setServiceAlert({
                variant: 'success',
                title: 'Done',
                description: 'Your service has been promoted to production successfully',
                visible: true
            });
            props.refreshAllServices(true);
        })
            .catch(e => {
                setPageLoading({status: false, label: ''});
                setServiceAlert({
                    variant: 'error',
                    title: 'Promote service to production error',
                    description: e.response.data.message.message,
                    visible: true
                });
                props.refreshAllServices(true);
            });
    };

    const onPromoteToProdModelBackClicked = (event) => {
        event.stopPropagation();
        onPromoteToProdModelClose();
    };

    const promoteToProdModalButtons = [
        {text: 'Confirm', id: 'promoteChangesToProdBtn', onClick: onPromoteToProdConfirmation},
        {text: 'Back', id: 'promoteChangesBackBtn', onClick: onPromoteToProdModelBackClicked}
    ];

    const renderServicePendingChangesOnModelWithTreeView = () => {
        return servicePendingChanges !== undefined ? (
            <SimpleTreeView defaultExpanded={ servicePendingChanges.defaultExpanded }
                disableSelection={ true } disabled={ true } defaultCollapseIcon={ <ExpandMoreIcon/> }
                onNodeFocus={ (event) => {
                    event.stopPropagation();
                } }
                onClick={ (event) => {
                    event.stopPropagation();
                } }
                style={ {pointerEvents: 'none'} }
            >
                <TreeItem
                    key={ servicePendingChanges.id }
                    nodeId={ servicePendingChanges.id }
                    label={ <div style={ {display: 'flex', alignItems: 'center', gap: '1rem', margin: 10} }>
                        Service: {servicePendingChanges.name} {servicePendingChanges.pendingChanges ?
                            <StatusLabel text={ 'PENDING' }
                                variant={ 'warning' }/> : null}
                    </div> }
                />
            </SimpleTreeView>) : null;
    };

    const handleOrderButtonClicked = (columnName) => {
        sort(columnName, notifications, setNotifications, notificationsOrder, setNotificationsOrder);
    };

    const displayPromoteButton = () => {
        if (!userRole.permissions.SERVICE.includes('PROMOTE')) {
            return false;
        }
        return notifications.some(notification => notification.status && notification.status === portalConfigStatus.IN_REVIEW);
    };

    return (
        <>
            <ServiceContainer
                data-testid={ `${props.serviceCode}-service-container` }
                expanded={ props.isExpanded }
                onChange={ () => props.updateExpandedAccordionState(props.serviceCode, !props.isExpanded) }
                sx={ {
                    border: 'none', boxShadow: 'none', background: 'white',
                    '&::before': {height: 0},
                    '& .Mui-expanded': {
                        background: tokens.colors.brand.snswSecondaryBlue,
                        color: 'white'
                    }
                } }
            >
                {pageLoading.status && <ComponentLoader fullPage label={ pageLoading.label }/>}
                <ServiceHeading
                    expandIcon={ <ExpandMoreIcon id={ `${props.serviceCode}-accordion-arrow` }
                        aria-hidden={ false }/> }
                    aria-controls={ `${props.serviceCode}-container-content` }
                    id={ `${props.serviceCode}-container-header` }
                    sx={ AccordionSummaryStyleOverrides }
                >
                    <ServiceTitleContainer>
                        <div style={ {display: 'flex', alignItems: 'center'} }>
                            <Heading level={ 5 }
                                style={ {width: 'fit-content', margin: '0 1.5rem 0 0', lineHeight: '1.5rem'} }>
                                Service: {props.serviceTitle}
                            </Heading>
                            {props.status === portalConfigStatus.IN_REVIEW
                            && userRole.permissions.SERVICE.includes('PROMOTE') ?
                                <StatusLabel text='In review' variant='warning'/> : null
                            }
                        </div>
                    </ServiceTitleContainer>
                </ServiceHeading>
                <AccordionDetails style={ {padding: '0px', margin: '-0.5rem 0 0 0'} }>
                    {!loading && notifications.length === 0 &&
                        <InPageAlert variant='warning' title='No notifications have been onboarded'>
                            <p>Click 'Add new notification' to create a new notification.</p>
                        </InPageAlert>}
                    {!loading && serviceAlert.visible &&
                        <InPageAlert id={ `${props.serviceCode}-status` }
                            variant={ serviceAlert.variant }
                            title={ serviceAlert.title }
                        >
                            <p>{serviceAlert.description}</p>
                        </InPageAlert>}
                    {loading ?
                        <SkeletonContainer>
                            <TableContainer title='' id='notification-table-loading'>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableHeader width={ '40%' }>Notification Name</TableHeader>
                                            <TableHeader width={ '20%' }>Code</TableHeader>
                                            <TableHeader width={ '20%' }>Status</TableHeader>
                                            <TableHeader width={ '20%' }>Action</TableHeader>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell><SkeletonHeading level={ 5 } width={ 100 } noMargin/></TableCell>
                                            <TableCell><SkeletonHeading level={ 5 } width={ 100 } noMargin/></TableCell>
                                            <TableCell><SkeletonHeading level={ 5 } width={ 100 } noMargin/></TableCell>
                                            <TableCell><SkeletonHeading level={ 5 } width={ 100 } noMargin/></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><SkeletonHeading level={ 5 } width={ 100 } noMargin/></TableCell>
                                            <TableCell><SkeletonHeading level={ 5 } width={ 100 } noMargin/></TableCell>
                                            <TableCell><SkeletonHeading level={ 5 } width={ 100 } noMargin/></TableCell>
                                            <TableCell><SkeletonHeading level={ 5 } width={ 100 } noMargin/></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><SkeletonHeading level={ 5 } width={ 100 } noMargin/></TableCell>
                                            <TableCell><SkeletonHeading level={ 5 } width={ 100 } noMargin/></TableCell>
                                            <TableCell><SkeletonHeading level={ 5 } width={ 100 } noMargin/></TableCell>
                                            <TableCell><SkeletonHeading level={ 5 } width={ 100 } noMargin/></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </SkeletonContainer> : notifications.length > 0 &&
                        <TableContainer title='' id='notification-table'>
                            <Table striped>
                                <TableHead>
                                    <TableRow>
                                        <TableHeader width={ displayPromoteButton() ? '30%' : '40%' }>
                                            {orderButtons('Order Notification names in an Ascending/Descending Order',
                                                'Notification', handleOrderButtonClicked)}
                                        </TableHeader>
                                        <TableHeader width={ displayPromoteButton() ? '30%' : '40%' }>
                                            {orderButtons('Order Notification codes in an Ascending/Descending Order',
                                                'Code', handleOrderButtonClicked)}
                                        </TableHeader>
                                        <TableHeader width={ '10%' }>
                                            {orderButtons('Order Notification statuses in an Ascending/Descending Order',
                                                'Status', handleOrderButtonClicked)}
                                        </TableHeader>
                                        <TableHeader width={ displayPromoteButton() ? '30%' : '10%' }>Action</TableHeader>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {notifications.map((notification, index) => {
                                        return (
                                            <NotificationListItem
                                                index={ index }
                                                serviceCode={ props.serviceCode }
                                                notification={ notification }
                                                agency={ props.agency }
                                                key={ notification.notificationCode }
                                                setNotificationAlert={ setServiceAlert }
                                                refreshNotifications={ refreshNotifications }
                                            />
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }

                    <div style={ {
                        display: 'flex', flexFlow: 'row wrap', justifyContent: 'left', gap: '24px',
                        marginTop: '-0.75rem', marginLeft: '1.5rem', marginRight: '1.5rem', marginBottom: '1.25rem'
                    } }>
                        {inProd &&
                            <Button variant='secondary' id={ `viewServiceSettingsBtn-${props.serviceCode}` }
                                data-testid={ `viewServiceSettingsBtn-${props.serviceCode}` }
                                as={ Link } href={ `/update-service/${props.serviceCode}` }
                                state={ {agency: props.agency} }
                                style={ {
                                    pointerEvents: 'auto', display: 'flex', flexDirection: 'column',
                                    alignItems: 'center', textDecoration: 'none'
                                } }
                            >
                                View service
                            </Button>}
                        {!inProd && userRole.permissions.NOTIFICATION.includes('CREATE') &&
                            <Button variant='primary' as={ Link }
                                style={ {textDecoration: 'none', alignItems: 'center', display: 'flex'} }
                                href={ `/add-notification/service/${props.serviceCode}?from=service` }>
                                Create new notification
                                <IconAdd color='white' style={ {marginLeft: '9px', width: '14px', height: '14px'} }/>
                            </Button>}
                        {!inProd && userRole.permissions.SERVICE.includes('UPDATE') &&
                            <Button variant='secondary' id={ `editServiceSettingsBtn-${props.serviceCode}` }
                                data-testid={ `editServiceSettingsBtn-${props.serviceCode}` }
                                as={ Link } href={ `/update-service/${props.serviceCode}` }
                                state={ {agency: props.agency} }
                                style={ {pointerEvents: 'auto', textDecoration: 'none'} }
                            >
                                Edit service
                                <CustomisedEditIcon/>
                            </Button>}
                        {!inProd && (props.status === portalConfigStatus.IN_REVIEW && userRole.permissions.SERVICE.includes('PROMOTE')) ?
                            <Button
                                id={ 'promoteServiceToProdBtn' }
                                data-testid={ `promoteServiceBtn-${props.serviceCode}` }
                                variant='secondary'
                                as={ Link }
                                onClick={ onPromoteToProdModelOpen }>
                                Push service to Prod
                            </Button> : null}
                        {showPromoteToProdModal && (
                            <Modal
                                title='Promote to production'
                                description='Following are the list of pending changes that will be promoted to production.
                                             Please confirm if you want to continue.'
                                buttons={ promoteToProdModalButtons }
                            >
                                <Card style={ {marginBottom: 20} }>
                                    <div>
                                        {renderServicePendingChangesOnModelWithTreeView()}
                                    </div>
                                </Card>
                            </Modal>
                        )}
                    </div>
                </AccordionDetails>
            </ServiceContainer>
        </>
    );
};

ServiceListItem.propTypes = {
    serviceCode: PropTypes.string,
    serviceTitle: PropTypes.string,
    isExpanded: PropTypes.bool,
    updateExpandedAccordionState: PropTypes.func
};

export default ServiceListItem;
