import styled from 'styled-components';
import {boxShadowStyle, colors as color, colors, globalFont} from '../Typography/Typography.styled';
import {FormControlLabel} from '@mui/material';

export const StyledFormControlLabel = styled(FormControlLabel)`
    margin-top: 0.5rem;
    font-family: ${globalFont};
    padding: 12px;
    display: inline-block;
    border-radius: 5px;
    transition: border-color 0.25s ease 0s, background-color 0.25s ease 0s;

    &:hover {
        background-color: #f0f0f0;
    }

    input[type="radio"]:checked + & {
        border: 1px solid ${color.brand.snswSecondaryBlue};
        background-color: ${colors.grey.iceBlue};
    }

    &:focus,
    &:focus-within {
        border-color: ${colors.brand.snswSecondaryBlue};
        background-color: ${colors.grey.iceBlue};
        box-shadow: ${boxShadowStyle};
    }

    &:active {
        outline: solid 2px transparent;
        border: 2px solid ${color.brand.snswSecondaryBlue};
    }
`;

export const CustomRadioLabel = styled.div`
    font-size: 14px;
    font-weight: 600;
    color: ${colors.brand.snswDarkGrey};
    padding-top: 0;
    font-family: ${globalFont};
    margin-left: 0;
    ${({isSelected}) => isSelected && `color: ${colors.brand.snswPrimaryBlue}`}
`;

export const CustomRadioClarify = styled.div`
    font-family: ${globalFont};
    font-size: 12px;
    margin-left: 0;
    color: ${color.grey.shuttle};
    ${({isSelected}) => isSelected && `color: ${colors.brand.snswPrimaryBlue};`};
`;