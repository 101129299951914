import React from 'react';
import {BodyWrapper} from './HomePageComponent.styled';

const PageBodyWrapper = (props) => {
    const {component: Component = false} = props;
    return (
        <div>
            <BodyWrapper>
                <Component/>
            </BodyWrapper>
        </div>
    );
};

export default PageBodyWrapper;
