import {
    CodeSnippetActionText, CodeSnippetBody, CodeSnippetContainer, CodeSnippetCopyIcon,
    CodeSnippetCopyIconWrapper,
    CodeSnippetHeader,
    CodeSnippetTitle
} from './CodeSnippetComponent.styled';
import React, {useState} from 'react';

const CodeSnippetComponent = (props) => {
    const [copyStatus, setCopyStatus] = useState('');
    const title = props.name?.includes('response') ? 'SAMPLE RESPONSE' : 'SAMPLE REQUEST';

    const handleMouseEnterForCopyIcon = () => {
        setCopyStatus('Copy');
    };

    const handleMouseLeaveForCopyIcon = () => {
        setCopyStatus('');
    };

    const copyCode = () => {
        setCopyStatus('Copied!');
        const code = document.getElementById(`${props.name}`).textContent;
        navigator.clipboard.writeText(code);
    };

    const {component: Component = false} = props;
    return (
        <CodeSnippetContainer key={ `${props.name}-container` }>
            <CodeSnippetHeader>
                <CodeSnippetTitle>{title}</CodeSnippetTitle>
                <CodeSnippetActionText key={ `${props.name}-copy-status` }>{copyStatus}</CodeSnippetActionText>
                <CodeSnippetCopyIconWrapper key={ `${props.name}-copy-icon` } data-testid={ `${props.name}-test` }
                    onMouseEnter={ handleMouseEnterForCopyIcon }
                    onMouseLeave={ handleMouseLeaveForCopyIcon }
                    onClick={ copyCode }
                >
                    <CodeSnippetCopyIcon
                        src={ `${process.env.PUBLIC_URL}/assets/icons/copy_icon.svg` }
                        alt={ `${props.name}-icon` }></CodeSnippetCopyIcon></CodeSnippetCopyIconWrapper>
            </CodeSnippetHeader>
            <CodeSnippetBody>
                <Component key={ props.name } id={ props.name }/>
            </CodeSnippetBody>
        </CodeSnippetContainer>
    );
};

export default CodeSnippetComponent;