import React, {useState} from 'react';
import {CustomRadioClarify, CustomRadioLabel, StyledFormControlLabel} from './RadioButton.styled';
import {Radio} from '@mui/material';
import {colors as $colors} from '../Typography/Typography.styled';
import {imagePath} from '../../GlobalStateComponent/GlobalState';

const RadioButton = ({option, selectedValue, onChange, disabled, hasError}) => {
    const [isHovered, setIsHovered] = useState(false);
    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    const handleRadioChange = () => {
        onChange(option.value);
    };

    const selected = selectedValue === option.value;
    const getBorderColor = (option) => {
        if (hasError) {return $colors.status.errorRed.default;}
        if (selected) {return $colors.brand.snswSecondaryBlue;}
        return isHovered ? '#b3b3b3' : $colors.grey.geyser;
    };

    const getBackgroundColor = (option) => {
        return selectedValue === option.value ? $colors.grey.iceBlue : $colors.white;
    };

    const label =
        <div style={ {display: 'flex'} } data-testid={ option.value }>
            {option.icon && <div style={ {marginRight: '8px'} }>
                <img src={ selected ? `${imagePath}${option.icon}-selected.svg` : `${imagePath}${option.icon}.svg` }
                    alt={ option.value }/>
            </div>}
            <div>
                <CustomRadioLabel id={ `${option.value}-label` } isSelected={ selected }>
                    {option.label}
                </CustomRadioLabel>
                {option.clarify != null && (
                    <CustomRadioClarify id={ `${option.value}-clarifyText` } data-testid={ `${option.value}-clarifyText` }
                        isSelected={ selected }>
                        {option.clarify}
                    </CustomRadioClarify>
                )}</div>
        </div>;

    return (
        <StyledFormControlLabel
            isSelected={ selected }
            style={ {
                marginLeft: '0', marginRight: '0',
                border: `1.5px solid ${getBorderColor(option)}`, display: 'flex',
                width: '49%', justifyContent: 'space-between', boxSizing: 'border-box',
                backgroundColor: getBackgroundColor(option)
            } }
            value={ option.value }
            control={ <Radio disableRipple disableTouchRipple disableFocusRipple
                data-testid={ `${option.value}-radio` }
                size={ 'small' }
                sx={ {
                    textAlign: 'right',
                    marginTop: '-1.25rem',
                    marginRight: '-0.5rem',
                    color: hasError ? $colors.brand.snswPrimaryRed : $colors.grey.shuttle,
                    '&.Mui-checked': {
                        color: $colors.brand.snswSecondaryBlue
                    },
                    '&:hover': {
                        bgcolor: 'transparent'
                    },
                    '&:focus': {
                        bgcolor: 'transparent',
                        outline: 'solid 2px #2e5299',
                        outlineOffset: '2px',
                        boxShadow: 'none'
                    },
                    '& .MuiIconButton-root': {
                        '&:hover': {
                            bgcolor: 'transparent'
                        }
                    }
                } }
            /> }
            label={ label }
            checked={ selected }
            onChange={ handleRadioChange }
            labelPlacement={ 'start' }
            disabled={ disabled }
            onMouseEnter={ handleMouseEnter }
            onMouseLeave={ handleMouseLeave }
        />);
};

export default RadioButton;