import React, {useEffect, useState} from 'react';
import {Container} from '@mui/material';
import {Breadcrumb} from './Breadcrumb';
import {InboxDetailHeading, Sender, Timestamp} from './PreviewInboxContentComponent.styled';
import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';
import './templateContentStyles.css';
import {inboxBaseContentLower, inboxBaseContentUpper} from '../../constants/applicationConstants';

const PreviewInboxContentComponent = (props) => {
    // const placeholderTitle = 'Notification title';
    const currentDate = new Date(Date.now());
    const [previewInboxContent, setPreviewInboxContent] = useState('');

    useEffect(() => {
        let inboxFooterString = atob(props.inboxFooter);
        if (inboxFooterString.search('{{#ifequals config.agencyName "Service NSW"}}') !== -1) {
            inboxFooterString = inboxFooterString
                .replaceAll(new RegExp(/{{#ifequals config\.agencyName "Service NSW"}}[\S\s.]+{{\/ifequals}}/g, 'g'),
                    props.sender === 'Service NSW' ? '.' : ', on behalf of ' + props.sender + '.');
        }

        let fullContent = inboxBaseContentUpper + '\n\n\n' + DOMPurify.sanitize(props.inboxContent,
            {FORCE_BODY: true, ADD_TAGS: ['style']}) + inboxFooterString + inboxBaseContentLower;
        fullContent = fullContent
            .replaceAll('{{config.agencyName}}', props.sender)
            .replaceAll('{{config.agencyDescription}}', props.sender)
            .replaceAll('{{config.serviceName}}', props.serviceName)
            .replaceAll('{{config.serviceDescription}}', props.serviceName)
            .replaceAll('{{config.assetsUri}}', '/assets/images')
            .replaceAll('{{customer.firstName}}', 'Jim')
            .replaceAll('{{customer.email}}', 'jim@example.com');
        //console.log('******* sanitized content: ' + fullContent);
        setPreviewInboxContent(fullContent);
    }, [props.inboxContent, props.inboxFooter, props.sender, props.serviceName]);

    return (
        <>
            <Container
                style = { {border: '1px solid #DEE3E5', margin: '1.5rem 0 0 0', padding: '36px 43px 35px'} }
                data-testid='inboxContentPreviewContainer'
            >
                <Breadcrumb notificationTitle={ props.inboxSubject }/>
                <div id='inboxContentPreview'>
                    <InboxDetailHeading level={ 2 } style={ {marginTop: '0px'} } data-testid='preview-inbox-heading'>
                        { props.inboxSubject }
                    </InboxDetailHeading>
                    <Sender data-testid='preview-inbox--sender'>{ props.sender }</Sender>
                    <Timestamp data-testid='preview-inbox--timestamp'>
                        { new Intl.DateTimeFormat('en-GB',
                            { day: '2-digit', month: 'short', year: 'numeric', timeZone: 'Australia/Sydney'
                            }).format(currentDate) }
                    </Timestamp>
                    <div data-testid='inbox-detail-page-message' dangerouslySetInnerHTML={ {__html: previewInboxContent} }/>
                </div>
            </Container>
        </>
    );
};

PreviewInboxContentComponent.propTypes = {
    inboxFooterId: PropTypes.string
};

export default PreviewInboxContentComponent;