import styled from 'styled-components';
import {colors} from '../CommonComponents/Typography/Typography.styled';

export const TeamsHeading = styled.h1`
  color: ${colors.brand.snswPrimaryBlue};
  leading-trim: both;
  text-edge: cap;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Public Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 166.667% */
`;