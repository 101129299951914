import axios from 'axios';

const userLoginDetails = {
    isUserLoggedIn: false,
    username: ''
};
let authRequestInterceptorId = -1;
let authResponseInterceptorId = -1;
const authenticate = async (username,password) => {
    const url = process.env.REACT_APP_PORTAL_BFF + '/auth/login';
    const config = {
        headers: {
            Authorization: createBasicAuthToken(username, password),
            'Access-Control-Allow-Origin': `${process.env.REACT_APP_HOME_URL}`,
            'Content-Type': 'application/json',
        }
    };

    return axios.get(url, config);
};

const createBasicAuthToken = (username, password) => {
    return 'Basic ' + window.btoa(username + ':' + password);
};

const registerSuccessfulLogin = (username, password) => {
    userLoginDetails['isUserLoggedIn'] = true;
    userLoginDetails['username'] = username;
    setupAxiosInterceptors(createBasicAuthToken(username, password),{});
};

const isUserLoggedIn = () => {
    return userLoginDetails.isUserLoggedIn !== false;
};

const setupAxiosInterceptors = (token, {navigate}) => {
    if(authRequestInterceptorId >=0){
        axios.interceptors.request.eject(authRequestInterceptorId);
    }
    authRequestInterceptorId = axios.interceptors.request.use(
        (config) => {
            config.headers['Authorization'] = 'Bearer '+ token;
            return config;
        }
    );

    if(authResponseInterceptorId >=0){
        axios.interceptors.response.eject(authResponseInterceptorId);
    }
    authResponseInterceptorId = axios.interceptors.response.use(response => {
        return response;
    }, error => {
        if (error.response.status === 401) {
            navigate('/', {
                state: {
                    variant: 'error',
                    title: 'Session expired',
                    description: 'Your session is expired. Login again',
                    visible: true
                }
            });
        } else if (error.response.status === 503) {
            const message = error.response.data.message.message ? error.response.data.message.message : error.response.data.message;
            navigate('/maintenance', {
                state: {
                    variant: 'error',
                    title: message.title,
                    description: message.description,
                    visible: true
                }
            });
        } else if (error.response.status === 429) {
            const { config } = error;
            if (config && config.retries > 0) {
                console.log('Found retry config for axios request');
                const delayRetryRequest = new Promise((resolve) => {
                    setTimeout(() => {
                        console.log('retry the request', config.url);
                        resolve();
                    }, (1/config.retries)*1000);
                });
                config.retries -= 1;
                return delayRetryRequest.then(() => axios(config));
            } else {
                return Promise.reject(error);
            }
        } else {
            return Promise.reject(error);
        }
    });

};

const logUserOut = () => {
    userLoginDetails['isUserLoggedIn'] = false;
};

const authenticateServices = {
    authenticate,
    setupAxiosInterceptors,
    registerSuccessfulLogin,
    isUserLoggedIn,
    logUserOut
};

export default authenticateServices;