import React, {useEffect} from 'react';
import {
    BodyContainer,
    ContentSection,
    GapSection,
    AnchorMenuSection,
} from './AnchorMenuLayoutComponent.styled';
import {CustomTableContainer} from '../CustomComponents/CustomComponents.styled';
import {DashboardHeading} from '../../DashboardComponent/DashboardComponent.styled';
import {scrollToTop} from '../../GlobalStateComponent/GlobalState';

const AnchorMenuLayoutComponent = (props) => {

    const anchorMenuMetaData = props.anchorMenuMetaData;
    const title = props.title;
    const titleId = anchorMenuMetaData == null ? 'titleId' : anchorMenuMetaData.data[0].id;
    const ContentComponent = props.contentComponent;
    const AnchorMenuComponent = props.anchorMenuComponent;

    useEffect(() => {
        scrollToTop();
    }, []);

    return (
        <CustomTableContainer>
            <DashboardHeading id={ `${titleId}` }>{title}</DashboardHeading>
            <BodyContainer>
                <ContentSection>
                    <ContentComponent menuMetaData={ anchorMenuMetaData }/>
                </ContentSection>
                <GapSection>&nbsp;</GapSection>
                <AnchorMenuSection>
                    { (anchorMenuMetaData != null) ? <AnchorMenuComponent menuMetaData={ anchorMenuMetaData }/> : <></> }
                </AnchorMenuSection>
            </BodyContainer>
        </CustomTableContainer>
    );
};

export default AnchorMenuLayoutComponent;