import React, {useState, useCallback, useEffect} from 'react';
import { TabPanel } from 'react-tabs';
import RecipientsComponent from '../Recipients/RecipientsComponent';
import Heading from '@snsw/react-component-library/build/Components/Headings/Heading';
import {ContentSubHeader, TabsContainer, StyledTabList, StyledTab} from './SendToComponent.styled';
import SingleRecipient from '../SingleRecipient/SingleRecipient';

const SendToComponent = ({setNotificationRequest, notificationRequest, setBatchNotification, inputErrors,
    setInputErrors, apiVersion, environment}) => {
    const [tabIndex, setTabIndex] = useState(0);
    const [myAccountCustomer, setMyAccountCustomer] = useState(true);

    useEffect(() => setBatchNotification(tabIndex === 1),[tabIndex, setBatchNotification]);

    const resetErrors = () => {
        if (inputErrors.customerId.hasError || inputErrors.smsRecipient.hasError || inputErrors.emailRecipient.hasError ||
            inputErrors.customerIds.hasError || inputErrors.emails.hasError || inputErrors.mobileNumbers.hasError
        ) {
            setInputErrors(prevState => ({
                ...prevState,
                emailRecipient: {hasError: false, errorMessage: 'You must provide at least one recipient'},
                smsRecipient: {hasError: false, errorMessage: 'Invalid Mobile number'},
                customerId: {hasError: false, errorMessage: 'Invalid customerId'},
                customerIds: {hasError: false, errorMessage: 'Invalid customerIds'},
                emails: {hasError: false, errorMessage: 'Invalid emails'},
                mobileNumbers: {hasError: false, errorMessage: 'Invalid mobile numbers'},
            }));
        }
    };

    const changeCustomerType = (value) => {
        setMyAccountCustomer(value === 'true');
        if (value === 'true') {
            setNotificationRequest(prevState => ({
                ...prevState,
                email: { ...prevState.email, to: '' },
                sms: { ...prevState.sms, to: '' }
            }));
        } else {
            setNotificationRequest(prevState => ({
                ...prevState,
                customerId: '',
            }));
        }
    };

    useEffect(() => {
        setMyAccountCustomer(true);
    }, [apiVersion, environment]);

    const handleRecipientCallback = useCallback((event) => {
        resetErrors();
        const {name, value} = event.target;
        setNotificationRequest(prevState => ({
            ...prevState,
            [name]: value
        })); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setNotificationRequest, inputErrors]);

    const handleBatchRecipientCallback = useCallback((event) => {
        resetErrors();
        const {name, value} = event.target;
        const newRecipients = [...notificationRequest[name]];
        newRecipients.push(...value.split(',').map(recipient => recipient.trim()));
        setNotificationRequest(prevState => ({
            ...prevState,
            [name]: newRecipients
        })); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setNotificationRequest, inputErrors]);

    const handleGuestRecipientCallback = useCallback((event) => {
        resetErrors();
        const {name, value} = event.target;
        setNotificationRequest(prevState => ({
            ...prevState,
            [name]: { ...prevState[name], to: value }
        })); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setNotificationRequest, inputErrors]);

    return <div>
        <Heading data-testid='content-header' level={ 3 } style={ {height: 'fit-content'} }>
            2. Send to
        </Heading>
        <ContentSubHeader>Select either Single or Batch Notifications to define who should receive this notification.</ContentSubHeader>
        <TabsContainer selectedTabClassName='is-selected' selectedIndex={ tabIndex } onSelect={ index => setTabIndex(index) }>
            <StyledTabList>
                <StyledTab>Single Recipient</StyledTab>
                <StyledTab>Multiple Recipients</StyledTab>
            </StyledTabList>
            <TabPanel>
                <SingleRecipient
                    notificationRequest={ notificationRequest }
                    recipientCallback={ handleRecipientCallback }
                    guestRecipientCallback={ handleGuestRecipientCallback }
                    inputErrors={ inputErrors }
                    myAccountCustomer={ myAccountCustomer }
                    changeCustomerType={ changeCustomerType }
                    apiVersion={ apiVersion }
                />
            </TabPanel>
            <TabPanel>
                <RecipientsComponent
                    notificationRequest={ notificationRequest }
                    recipientCallback={ handleBatchRecipientCallback }
                    inputErrors={ inputErrors }
                />
            </TabPanel>
        </TabsContainer>
    </div>;
};

export default SendToComponent;