import {GreenCodeFont, Paragraph} from './APIDocsComponent.styled';
import React from 'react';

const AuthenticationResponseCode = (props) => {
    return (
        <div id={ props.id }>
            <Paragraph>{'{'}</Paragraph>
            {/* eslint-disable-next-line max-len */}
            <Paragraph style={ {textIndent: '1em'} }>"access_token": <GreenCodeFont>"{'<access-token>'}"</GreenCodeFont>,</Paragraph>
            {/* eslint-disable-next-line max-len */}
            <Paragraph style={ {textIndent: '1em'} }>"expires_in": <GreenCodeFont>7099</GreenCodeFont>,</Paragraph>
            {/* eslint-disable-next-line max-len */}
            <Paragraph style={ {textIndent: '1em'} }>"token_type": <GreenCodeFont>"Bearer"</GreenCodeFont></Paragraph>
            <Paragraph>{'}'}</Paragraph>
        </div>
    );
};

export default AuthenticationResponseCode;