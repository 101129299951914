import React from 'react';
import {Link} from 'react-router-dom';
import {
    TableRow,
    TableCell,
    Button
} from '@snsw/react-component-library';
import PropTypes from 'prop-types';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {colors as $colors} from '../CommonComponents/Typography/Typography.styled';

export const CustomisedEditIcon = () => {
    return <span style={ { marginLeft:'0.25rem', verticalAlign: 'middle'} }>
        <EditOutlinedIcon style={ { width: '1.125rem', height: '1.125rem' } }
            aria-hidden='true' color={ $colors.brand.snswSecondaryBlue } /></span>;
};

const UserListItem = props => {

    const editButton = () => {
        return <Button variant='link' data-testid={ `edit-btn-${props.user.id}` } as={ Link }
            href={ `/users/update-user/${props.user.id}` }>
            Edit
            <CustomisedEditIcon/>
        </Button>;
    };
    return (
        <TableRow key={ props.index } role={ 'userListItem' }>
            <TableCell key={ props.index + '.1' } style={ {wordBreak: 'break-word'} }>{props.user.fullName}</TableCell>
            <TableCell key={ props.index + '.2' }>{props.user.email}</TableCell>
            <TableCell key={ props.index + '.3' }>{props.user.userRoles[0]?.name}</TableCell>
            <TableCell key={ props.index + '.4' }>{props.user.userTeams.map(team => team.name).join(', ')}</TableCell>
            <TableCell key={ props.index + '.5' }>
                <div style={ {display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '1rem'} }>
                    {editButton()}
                </div>
            </TableCell>
        </TableRow>
    );
};

UserListItem.propTypes = {
    index:PropTypes.number,
    user:PropTypes.object
};

export default UserListItem;
