import {GreenCodeFont, Paragraph} from './APIDocsComponent.styled';
import React from 'react';

const AttachmentRequestCode = (props) => {
    return (
        <div id={ props.id }>
            <Paragraph>curl --location -g --request
                POST <GreenCodeFont>{'\'https://api-psm.g.testservicensw.net/notifications/v2\''}</GreenCodeFont> \</Paragraph>
            <Paragraph>--header <GreenCodeFont>{'\'Authorization: Bearer <token>\''}</GreenCodeFont> \</Paragraph>
            <Paragraph>--data-raw <GreenCodeFont>{'\'{'}</GreenCodeFont></Paragraph>
            {/* eslint-disable-next-line max-len */}
            <Paragraph style={ {textIndent: '1em'} }><GreenCodeFont>"notificationCode"</GreenCodeFont>: <GreenCodeFont>"SHOWCASE_V_TWO"</GreenCodeFont>,</Paragraph>
            {/* eslint-disable-next-line max-len */}
            <Paragraph style={ {textIndent: '1em'} }><GreenCodeFont>"customerId"</GreenCodeFont>: <GreenCodeFont>"766d9132-0b2e-4574-0v90ec9342f2"</GreenCodeFont>,</Paragraph>
            {/* eslint-disable-next-line max-len */}
            <Paragraph style={ {textIndent: '1em'} }><GreenCodeFont>"notificationDetails"</GreenCodeFont>: {'{'}
            </Paragraph>
            <Paragraph style={ {textIndent: '2em'} }><GreenCodeFont>"variables"</GreenCodeFont>: [</Paragraph>
            <Paragraph style={ {textIndent: '3em'} }>{'{'}</Paragraph>
            {/* eslint-disable-next-line max-len */}
            <Paragraph style={ {textIndent: '4em'} }><GreenCodeFont>"filename"</GreenCodeFont>: <GreenCodeFont>"example.pdf"</GreenCodeFont>,</Paragraph>
            {/* eslint-disable-next-line max-len */}
            <Paragraph style={ {textIndent: '4em', wordBreak: 'break-all', whiteSpace: 'normal'} }><GreenCodeFont>"fileContent"</GreenCodeFont>: <GreenCodeFont>"JVBERi0xLjIgCjkgMCBvYmoKPDwKPj4Kc3RyZWFtCkJULyA5IFRmKFRlc3QpJyBFVAplbmRzdHJlYW0KZW5kb2JqCjQgMCBvYmoKPDwKL1R5cGUgL1BhZ2UKL1BhcmVudCA1IDAgUgovQ29udGVudHMgOSAwIFIKPj4KZW5kb2JqCjUgMCBvYmoKPDwKL0tpZHMgWzQgMCBSIF0KL0NvdW50IDEKL1R5cGUgL1BhZ2VzCi9NZWRpYUJveCBbIDAgMCA5OSA5IF0KPj4KZW5kb2JqCjMgMCBvYmoKPDwKL1BhZ2VzIDUgMCBSCi9UeXBlIC9DYXRhbG9nCj4+CmVuZG9iagp0cmFpbGVyCjw8Ci9Sb290IDMgMCBSCj4+CiUlRU9G"</GreenCodeFont></Paragraph>
            <Paragraph style={ {textIndent: '3em'} }>{'}'}</Paragraph>
            <Paragraph style={ {textIndent: '2em'} }>{']'}</Paragraph>
            <Paragraph style={ {textIndent: '1em'} }>{'}'}</Paragraph>
            <Paragraph>{'}\''}</Paragraph>
        </div>
    );
};

export default AttachmentRequestCode;