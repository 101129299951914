import {GreenCodeFont, Paragraph} from './APIDocsComponent.styled';
import React from 'react';

const ConditionalTextCode = (props) => {
    return (
        <div id={ props.id }>
            <Paragraph><GreenCodeFont>"variables"</GreenCodeFont>: {'{'}</Paragraph>
            {/* eslint-disable-next-line max-len */}
            <Paragraph style={ {textIndent: '1em'} }><GreenCodeFont>"name"</GreenCodeFont>: <GreenCodeFont>"customerType"</GreenCodeFont>,</Paragraph>
            {/* eslint-disable-next-line max-len */}
            <Paragraph
                style={ {textIndent: '1em'} }><GreenCodeFont>"value"</GreenCodeFont>: <GreenCodeFont>"Business"</GreenCodeFont></Paragraph>
            <Paragraph>{'}'}</Paragraph>
        </div>
    );
};

export default ConditionalTextCode;