import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {scrollToTop, useGlobalState} from '../GlobalStateComponent/GlobalState';
import {useBoolean} from '@snsw/react-component-library/build/utils';
import PreferenceAlertComponent from '../PreferenceAlertComponent/PreferenceAlertComponent';
import {alertTypeToErrorName, preferenceAlertTypes} from '../../constants/applicationConstants';
import {
    FormCheckboxGroup,
    Fieldset,
    FormGroup,
    Modal,
    Form,
    FormInput,
    Col,
    Row,
    Button,
    Heading,
    ComponentLoader, Textarea
} from '@snsw/react-component-library';
import apiServices from '../../services/api-services';
import {Buffer} from 'buffer';
import {validateFields} from '../CreatePreferenceTileComponent/CreatePreferenceTileComponent';
import {PortalFormContainer} from '../CommonComponents/CustomComponents/CustomComponents.styled';

const UpdatePreferenceTileComponent = () => {
    const { serviceCode } = useParams();
    const navigate = useNavigate();
    const [inputErrors, setInputErrors] = useState({
        serviceDescription: {hasError: false, errorMessage: 'Please provide a description'},
        learnMoreLinkText: {hasError: false, errorMessage: 'Please provide the text for the learn more link or remove the href'},
        learnMoreLinkHref: {hasError: false, errorMessage: 'Please provide the href for the learn more link or remove the text'},
        linkActionAlert: {
            title: {hasError: false, errorMessage: 'Please provide a link Action Alert Title'},
            actionLinkText: {hasError: false, errorMessage: 'Please provide a link Action Alert Link Text'},
            actionLinkHref: {hasError: false, errorMessage: 'Please provide a link Action Alert Link Href'}
        },
        duplicateAccountAlert: {
            title: {hasError: false, errorMessage: 'Please provide a Duplicate Account Alert Title'},
            description: {hasError: false, errorMessage: 'Please provide the Duplicate Account Alert description'},
        },
        ineligibleAlert: {
            title: {hasError: false, errorMessage: 'Please provide a Ineligible Alert Title'},
            description: {hasError: false, errorMessage: 'Please provide the Ineligible Alert description'},
        },
        optInAlert: {
            title: {hasError: false, errorMessage: 'Please provide the Opt In Alert Title'},
            description: {hasError: false, errorMessage: 'Please provide the Opt In Alert description'},
        },
        optOutAlert: {
            title: {hasError: false, errorMessage: 'Please provide the Opt Out Alert Title'},
            description: {hasError: false, errorMessage: 'Please provide the Opt Out Alert description'},
        },
    });
    const [preferenceConfig, setPreferenceConfig] = useState({
        serviceId: '',
        serviceDescription: '',
        learnMoreLinkText: '',
        learnMoreLinkHref: '',
        preReqCheckRequired: false,
        linkCheckRequired: false,
        duplicateCheckRequired: false,
        eligibilityCheckRequired: false,
        preferenceAlerts: []
    });
    const [inProd] = useGlobalState();
    const [loading, setLoading] = useState(false);
    const [showModal, open, close] = useBoolean(false);
    const defaultOptionsChecked = [{
        isChecked: false, disabled: false,
        label: 'Linked Check Required', value: 'linkCheckRequired',
        clarify: 'Is linking required to opt in to this service (liking via service activation)'}, {
        isChecked: false, disabled: false,
        label: 'Duplicate Check Required', value: 'duplicateCheckRequired',
        clarify: 'Check if the customer has linked the service to two MyAccounts' }, {
        isChecked: false, disabled: false,
        label: 'Eligibility Check Required', value: 'eligibilityCheckRequired',
        clarify: 'Check the users eligibility via a bespoke endpoint'},
    ];
    const [linkingCheckCheckboxes, setLinkingCheckCheckboxes] = useState(defaultOptionsChecked);
    const [optInOutCheckboxes, setOptInOutCheckboxes] = useState([
        { isChecked: false, label: 'Display a "By Opting in" Alert', value: preferenceAlertTypes.OPT_IN },
        { isChecked: false, label: 'Display a "By Opting out" Alert', value: preferenceAlertTypes.OPT_OUT },
    ]);

    useEffect(() => {
        scrollToTop();
        apiServices.getPreferenceConfig(serviceCode, inProd)
            .then((data) => {
                setPreferenceConfig({
                    ...data,
                    preferenceAlerts: data.preferenceAlerts.map(alert => {
                        if (alert.description) {
                            return { ...alert, description: Buffer.from(alert.description, 'base64').toString('utf8') };
                        } else { return alert; }
                    })
                });
                setLinkingCheckCheckboxes(prevState => (
                    prevState.map(checkBox => {
                        return { ...checkBox, disabled: inProd, isChecked: data[checkBox.value] };
                    })
                ));
                setOptInOutCheckboxes(prevState => (
                    prevState.map(checkBox => {
                        return {
                            ...checkBox,
                            disabled: inProd, isChecked: data.preferenceAlerts.find(alert => alert.type === checkBox.value)
                        };
                    })
                ));
            })
            .catch(error => {
                if (error.response !== undefined) {
                    if (error.response.status === 404) {
                        navigate('/preference-tiles');
                    }
                } else {
                    console.log(`Caught error: ${error}`);
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inProd, serviceCode]);

    const handleChange = event => {
        const {name, value} = event.target;
        setPreferenceConfig(prevState => ({
            ...prevState,
            [name]: value
        }));
        setInputErrors(prevState => ({
            ...prevState,
            [name]: {hasError: false, errorMessage: 'Error'},
        }));
    };

    const validateRequest = async () => {
        let valid = true;
        valid = validateFields(preferenceConfig, valid, setInputErrors, optInOutCheckboxes);
        return valid;
    };

    const updateTile = async () => {
        setLoading(true);
        validateRequest().then((valid) => {
            if (valid) {
                const preferenceConfigToUpdate = {
                    ...preferenceConfig,
                    learnMoreLinkHref: preferenceConfig.learnMoreLinkHref !== null ? preferenceConfig.learnMoreLinkHref : '',
                    learnMoreLinkText: preferenceConfig.learnMoreLinkText !== null ? preferenceConfig.learnMoreLinkText : '',
                };
                apiServices.updatePreferenceConfig(serviceCode, preferenceConfigToUpdate).then(() => {navigate('/preference-tiles'); });
                setLoading(false);
            } else {
                window.scrollTo(0, 0);
                setLoading(false);
            }
        });
    };

    const handleCheckboxChange = (event) => {
        const val = event.target.value;
        const linkingChecks = [...linkingCheckCheckboxes];
        linkingChecks.forEach(item => {
            if (item.value === val) {
                item.isChecked = !item.isChecked;
                setPreferenceConfig(prevState => ({
                    ...prevState,
                    [val]: item.isChecked
                }));
            }
        });
        setLinkingCheckCheckboxes(linkingChecks);
    };

    const handleOptInOutCheckboxesChange = (event) => {
        const val = event.target.value;
        const checkedOptions = [...optInOutCheckboxes];
        checkedOptions.forEach(item => {
            if (item.value === val) {
                item.isChecked = !item.isChecked;
            }
        });
        setOptInOutCheckboxes(checkedOptions);
    };

    const handleContentCallback = useCallback((event, alertType) => {
        const {name, value} = event.target;
        if (preferenceConfig.preferenceAlerts.find(alert => alert.type === alertType) === undefined) {
            const newAlert = { type: alertType, title: '', description: '', actionLinkText: '', actionLinkHref: ''};
            newAlert[name] = value;
            setPreferenceConfig(prevState => ({
                ...prevState,
                preferenceAlerts: [...prevState.preferenceAlerts, newAlert]
            }));
        } else {
            setPreferenceConfig(prevState => ({
                ...prevState,
                preferenceAlerts: [...prevState.preferenceAlerts.map((alert) =>
                    alert.type === alertType
                        ? { ...alert, [name]: value }
                        : { ...alert }
                )]
            }));
            const alertErrorName = alertTypeToErrorName(alertType);
            setInputErrors(prevState => ({
                ...prevState,
                [alertErrorName]: {
                    ...prevState[alertErrorName],
                    [name]: {hasError: false, errorMessage: 'Error'}
                },
            }));
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setPreferenceConfig, preferenceConfig]);

    return (
        <PortalFormContainer>
            { loading && <ComponentLoader fullPage label='Creating the Agency...'/> }
            <Heading
                className='page-title'
                style={ { height: 'fit-content', margin: '6px 0 2rem 0'} }
                level={ 1 }
            >{ inProd ? `${serviceCode} Preference tile`: `Update the ${serviceCode} Preference tile` }</Heading>
            <Form data-test='new-preference-tile-form'>
                <Row>
                    <Col span={ 6 }>
                        <FormInput
                            id='serviceCodeInput'
                            name='service'
                            value={ serviceCode }
                            disabled
                            label='Service'
                        />
                        <FormGroup
                            id='preference-description-textArea'
                            label='Description'
                            errorMessage={ inputErrors.serviceDescription.errorMessage }
                            hasError={ inputErrors.serviceDescription.hasError }
                            margin={ { top: 'lg' } }
                        >
                            <Textarea
                                name='serviceDescription'
                                onChange={ handleChange }
                                value={ preferenceConfig.serviceDescription }
                                disabled={ inProd }
                            />
                        </FormGroup>
                        <Fieldset legend='Learn More Link' margin={ { top: 'xxl' } } >
                            <FormInput
                                id='learnMoreLinkText-input'
                                name='learnMoreLinkText'
                                label='Learn more link text'
                                onChange={ handleChange }
                                value={ preferenceConfig.learnMoreLinkText || '' }
                                errorMessage={ inputErrors.learnMoreLinkText.errorMessage }
                                hasError={ inputErrors.learnMoreLinkText.hasError }
                                isOptional
                                disabled={ inProd }
                            />
                            <FormInput
                                id='learnMoreLinkHref-input'
                                name='learnMoreLinkHref'
                                label='Learn more link href'
                                onChange={ handleChange }
                                value={ preferenceConfig.learnMoreLinkHref || '' }
                                errorMessage={ inputErrors.learnMoreLinkHref.errorMessage }
                                hasError={ inputErrors.learnMoreLinkHref.hasError }
                                isOptional
                                disabled={ inProd }
                            />
                        </Fieldset>
                        <Fieldset
                            legend='Prerequisite Checks'
                            helpMessage='Select any Prerequisite checks that are required for this service'
                            margin={ { top: 'xxl' } }
                        >
                            <FormCheckboxGroup
                                legend=''
                                id='required-linking-checks-group'
                                name='required-linking-checks'
                                options={ linkingCheckCheckboxes }
                                onChange={ handleCheckboxChange }
                                isOptional
                                disabled={ inProd }
                            />
                        </Fieldset>
                        <Fieldset legend='Preference Alerts' margin={ { top: 'xxl' } }>
                            <FormCheckboxGroup
                                id='opt-in-out-alerts-checkboxes'
                                name='opt-in-out-alerts'
                                legend=''
                                options={ optInOutCheckboxes }
                                onChange={ handleOptInOutCheckboxesChange }
                                isOptional
                                disabled={ inProd }
                            />
                            {optInOutCheckboxes.find(checkbox => checkbox.value === preferenceAlertTypes.OPT_IN).isChecked &&
                                <PreferenceAlertComponent
                                    callback={ handleContentCallback }
                                    legend={ 'By Opting in Alert' }
                                    helpMessage={ 'The alert to be displayed to the user when they opt-in to the service' }
                                    type={ preferenceAlertTypes.OPT_IN }
                                    inputErrors={ inputErrors.optInAlert }
                                    disabled={ inProd }
                                    alertValues={ preferenceConfig.preferenceAlerts
                                        .find(alert => alert.type === preferenceAlertTypes.OPT_IN) }
                                />
                            }
                            {optInOutCheckboxes.find(checkbox => checkbox.value === preferenceAlertTypes.OPT_OUT).isChecked &&
                                <PreferenceAlertComponent
                                    callback={ handleContentCallback }
                                    legend={ 'By Opting out Alert' }
                                    helpMessage={ 'The alert to be displayed to the user when they opt-out to the service' }
                                    type={ preferenceAlertTypes.OPT_OUT }
                                    inputErrors={ inputErrors.optOutAlert }
                                    disabled={ inProd }
                                    alertValues={ preferenceConfig.preferenceAlerts
                                        .find(alert => alert.type === preferenceAlertTypes.OPT_OUT) }
                                />
                            }
                            {preferenceConfig.duplicateCheckRequired &&
                                <PreferenceAlertComponent
                                    callback={ handleContentCallback }
                                    legend={ 'Duplicate Account Alert' }
                                    helpMessage={ 'The alert to be displayed to the user when they have a duplicate account' }
                                    type={ preferenceAlertTypes.DUPLICATE_ACCOUNT }
                                    inputErrors={ inputErrors.duplicateAccountAlert }
                                    disabled={ inProd }
                                    alertValues={ preferenceConfig.preferenceAlerts
                                        .find(alert => alert.type === preferenceAlertTypes.DUPLICATE_ACCOUNT) }
                                />
                            }
                            {preferenceConfig.eligibilityCheckRequired &&
                                <PreferenceAlertComponent
                                    callback={ handleContentCallback }
                                    legend={ 'Ineligible Account Alert' }
                                    helpMessage={ 'The alert to be displayed to the user when they have an ineligible account' }
                                    type={ preferenceAlertTypes.INELIGIBLE }
                                    inputErrors={ inputErrors.ineligibleAlert }
                                    disabled={ inProd }
                                    alertValues={ preferenceConfig.preferenceAlerts
                                        .find(alert => alert.type === preferenceAlertTypes.INELIGIBLE) }
                                />
                            }
                            {preferenceConfig.linkCheckRequired &&
                                <PreferenceAlertComponent
                                    callback={ handleContentCallback }
                                    legend={ 'Link Action Alert' }
                                    helpMessage={ 'The Linking action to display' }
                                    type={ preferenceAlertTypes.USER_ACTION }
                                    inputErrors={ inputErrors.linkActionAlert }
                                    disabled={ inProd }
                                    alertValues={ preferenceConfig.preferenceAlerts
                                        .find(alert => alert.type === preferenceAlertTypes.USER_ACTION) }
                                />
                            }
                        </Fieldset>
                    </Col>
                </Row>
                {!inProd ?
                    <div style={ {marginTop: '4rem', display: 'flex', gap: '2rem'} }>
                        <Button
                            onClick={ updateTile }
                            variant='primary'
                            id='updatePreferenceTileBtn'
                        >Update Preference Tile</Button>
                        <Button variant='secondary' id='backBtn' onClick={ open }>Back</Button>
                        {showModal && (
                            <Modal
                                title='Are you sure you want to discard your unsaved changes?' // eslint-disable-next-line max-len
                                description='By going back you will loose all the progress on this form and the Preference Tile will not be updated.'
                                buttons={ [
                                    { text: 'Discard', onClick: () => navigate('/preference-tiles') },
                                    { text: 'Cancel', onClick: () => close() }
                                ] }
                            />
                        )}
                    </div> :
                    <div style={ {marginTop: '4rem'} }>
                        <Button variant='secondary' id='backBtn' onClick={ () => navigate('/preference-tiles') }>Back</Button>
                    </div>
                }
            </Form>
        </PortalFormContainer>
    );
};

export default UpdatePreferenceTileComponent;
