export function toTitleCase(str) {
    const smallWords = new Set(['and', 'or', 'the', 'in', 'of', 'to', 'a', 'for', 'but', 'is', 'on', 'by']);
    return str
        .toLowerCase()
        .split(/(\s+|-+\s*)/)
        .map((word, index) => {
            if (word.trim() === '-' || word.trim() === '') {
                return word;
            }
            if (index === 0 || !smallWords.has(word)) {
                return word.charAt(0).toUpperCase() + word.slice(1);
            }
            return word;
        })
        .join('');
}