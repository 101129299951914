import {
    BreadcrumbContainer,
    BreadcrumbList, BreadcrumbListItem
} from '@snsw/react-component-library/build/Components/Breadcrumb/Breadcrumb.styled';
import {IconChevronLeft, IconChevronRight} from '@snsw/react-component-library/build/Icons/system';
import TextLink from '@snsw/react-component-library/build/Components/TextLink/TextLink';
import React from 'react';
import PropTypes from 'prop-types';

export const Breadcrumb = (props) => {

    return (
        <BreadcrumbContainer data-testid='inbox-detail-page-breadcrumb'
            style={ {marginTop: '0rem', marginBottom:'2rem'} }
            aria-label='Breadcrumb'>
            <IconChevronLeft />
            <BreadcrumbList>
                <BreadcrumbListItem>
                    <TextLink data-testid='inbox-link' href='' style={ {textDecoration: 'underline' } }>Inbox</TextLink>
                    <IconChevronRight/>
                </BreadcrumbListItem>
                <BreadcrumbListItem>
                    <p style={ {fontSize: '0.875rem', fontWeight: 'bold'} } aria-current={ 'page' }>{props.notificationTitle}</p>
                </BreadcrumbListItem>
            </BreadcrumbList>
        </BreadcrumbContainer>
    );
};

Breadcrumb.propTypes = {
    notificationTitle: PropTypes.string
};
