import React, {useState} from 'react';
import {Col, ContentContainer, SectionPage} from '@snsw/react-component-library';
import PropTypes from 'prop-types';
import Row from '@snsw/react-component-library/build/layout/Grid/Row.styled';
import Button from '@snsw/react-component-library/build/Components/Button/Button';
import {useOktaAuth} from '@okta/okta-react';
import InPageAlert from '@snsw/react-component-library/build/Components/InPageAlert/InPageAlert';
import {toRelativeUrl} from '@okta/okta-auth-js';
import {FormButtonContainer} from '@snsw/react-component-library/build/Components/Form/Form.styled';
import {useLocation} from 'react-router-dom';
import {PortalInfo} from '../LandingPage/LandingPage';

const LoginComponent = (props) => {
    const { state } = useLocation();
    const { oktaAuth } = useOktaAuth();
    const [errorStatus, setErrorStatus] = useState({
        variant: 'error',
        title: 'Error',
        description: props.error?props.error.message:(state && state.visible?state.description:null),
        visible: props.error? true:(state && state.visible?true:false)
    });

    const doLogin = async () => {
        const originalUri = toRelativeUrl('/', window.location.origin);
        oktaAuth.setOriginalUri(originalUri);
        oktaAuth.signInWithRedirect().then(() => {
            console.log('Successfully redirected to OKTA');
        })
            .catch(() => {
                setErrorStatus(prevState => ({
                    ...prevState,
                    variant: 'error',
                    title: 'Login failed',
                    description: 'Login failed',
                    visible: true
                }));
            });
    };

    return (
        <ContentContainer>
            <SectionPage>
                <Row justifyContent={ 'center' }>
                    <Col span={ 12 }>
                        <><PortalInfo/>
                            <p>To access the notifications portal you’ll need to complete the
                                Service NSW form and verify your agency identity.
                                If you have already been granted access, log in using Okta below.</p>
                        </>
                        <FormButtonContainer style={
                            {
                                display: 'flex',
                                flexWrap: 'nowrap',
                                justifyContent: 'start',
                                flexDirection: 'column',
                                margin: '0'
                            }
                        }>

                            {errorStatus.visible ?
                                <InPageAlert id='notification-status' variant={ errorStatus.variant }
                                    title={ errorStatus.title }>
                                    <p data-test='notStatusDesc'>{errorStatus.description}</p>
                                </InPageAlert> : null}
                            <FormButtonContainer style={
                                {
                                    display: 'flex',
                                    flexWrap: 'nowrap',
                                    justifyContent: 'start',
                                    flexDirection: 'row',
                                    marginTop: '1rem'
                                }
                            }>
                                <Button
                                    onClick={ doLogin }
                                    variant='primary'
                                    id='doLoginBtn'
                                >Log in</Button>
                                <span style={ {width: '1rem'} }/>
                                <Button
                                    variant='secondary'
                                    id='requestPortalAccess'
                                    target='_blank'
                                    style={ { maxHeight:'3rem', textDecoration: 'none', alignItems: 'center', display:'flex' } }
                                    href={ process.env.REACT_APP_SERVICENOW_URL }
                                >Request portal access</Button>
                            </FormButtonContainer>
                        </FormButtonContainer>
                    </Col>
                </Row>
            </SectionPage>
        </ContentContainer>
    );
};

LoginComponent.propTypes = {
    onChange: PropTypes.func
};
export default LoginComponent;
