import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import apiServices from '../../services/api-services';
import {
    Button,
    Heading,
    InPageAlert,
    SkeletonContainer,
    SkeletonHeading,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow
} from '@snsw/react-component-library';
import {orderButtons, scrollToTop, useGlobalState, useUserRoleState} from '../GlobalStateComponent/GlobalState';
import {alertTimeoutValues, portalConfigStatus} from '../../constants/applicationConstants';
import AgencyListItem from '../AgencyListItem/AgencyListItem';
import {TableContainer} from '../TableContainerComponent/TableContainer';
import {CustomTableContainer} from '../CommonComponents/CustomComponents/CustomComponents.styled';

const AgencyListComponent = () => {
    const {state} = useLocation();
    const [agencies, setAgencies] = useState([]);
    const [alert, setAlert] = useState(state && state.visible ? {
        variant: state.variant,
        title: state.title,
        description: state.description,
        visible: state.visible
    } : {
        variant: '',
        title: '',
        description: '',
        visible: false
    });
    const [refreshAllAgencies, setRefreshAllAgencies] = useState(state ? state.refreshAllServices : undefined);

    const [inProd] = useGlobalState();
    const {userRole} = useUserRoleState();
    const [order, setOrder] = useState('ASC');
    const orderAgenciesAlphabetically = (agencies) => {
        agencies.sort(function (a, b) {
            if (a.description.toLowerCase() < b.description.toLowerCase()) {
                return -1;
            }
            if (a.description.toLowerCase() > b.description.toLowerCase()) {
                return 1;
            }
            return 0;
        });
        return agencies;
    };
    const setAgencyManually = (col) => {
        if (order === 'ASC') {
            const sorted = agencies.sort((a, b) => a.col?.toLowerCase() > b.col?.toLowerCase() ? 1 : -1);
            setAgencies(sorted);
            setOrder('DSC');
        }
        if (order === 'DSC') {
            const sorted = agencies.sort((a, b) => a.col?.toLowerCase() > b.col?.toLowerCase() ? 1 : -1);
            setAgencies(sorted);
            setOrder('ASC');
        }
    };

    const displayAgencyStatus = () => {
        if (inProd) {
            return false;
        }
        return userRole?.permissions?.AGENCY?.includes('PROMOTE');
    };

    const displayEditButton = () => {
        return (userRole?.permissions?.AGENCY?.includes('UPDATE'));
    };

    const displayPromoteButton = () => {
        return agencies.some(agency => agency.status && agency.status === portalConfigStatus.IN_REVIEW);
    };

    useEffect(() => {
        scrollToTop();
        apiServices.fetchAgencies(inProd).then(agencies => setAgencies(orderAgenciesAlphabetically(agencies)))
            .catch(e => {
                console.log(e.message);
            });
    }, [inProd, state, refreshAllAgencies]);
    useEffect(() => {
        if (alert.visible) {
            const timer = setTimeout(() => {
                setAlert(prevState => ({
                    ...prevState,
                    visible: false
                }));
            }, alertTimeoutValues[alert.variant]);
            return () => {
                clearTimeout(timer);
            };
        }
    }, [alert]);

    const handleOrderButtonClicked = (columnName) => {
        setAgencyManually(columnName);
    };

    return (
        <CustomTableContainer role='main'>
            <Button variant='back' as={ Link } href='/'>Back</Button>
            <Heading
                level={ 1 }
                id='AgencyListHeading'
                style={ {height: 'fit-content', margin: '0.948rem 0 0.5rem 0'} }
            >
                Agencies
            </Heading>
            {(alert.visible) &&
                <InPageAlert id='agency-status' variant={ alert.variant } title={ alert.title }>
                    <p id='notStatusDesc'>{alert.description}</p>
                </InPageAlert>}
            {!inProd && userRole?.permissions?.AGENCY?.includes('CREATE') &&
                <Button variant='primary' as={ Link } href='/agencies/add-agency'
                    style={ {textDecoration: 'none', margin: '0.948rem 0 0.948rem 0'} }>
                    Add new agency +</Button>}
            <>
                {agencies.length === 0 ?
                    <SkeletonContainer>
                        <TableContainer title='' id='agency-table-loading'>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableHeader width={ '40%' }>Agency Name</TableHeader>
                                        <TableHeader width={ '20%' }>Agency Code</TableHeader>
                                        <TableHeader width={ '40%' }>Action</TableHeader>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell><SkeletonHeading level={ 5 } width={ 100 } noMargin/></TableCell>
                                        <TableCell><SkeletonHeading level={ 5 } width={ 100 } noMargin/></TableCell>
                                        <TableCell><SkeletonHeading level={ 5 } width={ 100 } noMargin/></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><SkeletonHeading level={ 5 } width={ 100 } noMargin/></TableCell>
                                        <TableCell><SkeletonHeading level={ 5 } width={ 100 } noMargin/></TableCell>
                                        <TableCell><SkeletonHeading level={ 5 } width={ 100 } noMargin/></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><SkeletonHeading level={ 5 } width={ 100 } noMargin/></TableCell>
                                        <TableCell><SkeletonHeading level={ 5 } width={ 100 } noMargin/></TableCell>
                                        <TableCell><SkeletonHeading level={ 5 } width={ 100 } noMargin/></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </SkeletonContainer> :
                    <TableContainer title='' id='agency-table'>
                        <Table striped>
                            <TableHead>
                                <TableRow>
                                    {displayAgencyStatus() ?
                                        <><TableHeader width={ displayPromoteButton() ? '20%' : '35%' }>
                                            {orderButtons('Order Agencies in Ascending/Descending Order',
                                                'Agency', handleOrderButtonClicked)}
                                        </TableHeader>
                                        <TableHeader width={ displayPromoteButton() ? '15%' : '20%' }>Agency
                                                Code </TableHeader>
                                        <TableHeader width={ '10%' }>Status</TableHeader>
                                        <TableHeader
                                            width={ displayPromoteButton() ? '55%' : '35%' }>Action</TableHeader></>
                                        :
                                        <><TableHeader width={ displayEditButton() ? '35%' : '40%' }>
                                            {orderButtons('Order Agencies in Ascending/Descending Order',
                                                'Agency', handleOrderButtonClicked)}
                                        </TableHeader>
                                        <TableHeader width={ displayEditButton() ? '30%' : '35%' }>Agency
                                                Code </TableHeader>
                                        <TableHeader
                                            width={ displayEditButton() ? '35%' : '25%' }>Action</TableHeader></>}
                                </TableRow>
                            </TableHead>
                            <TableBody style={ {background: '#ffffff'} }>
                                {agencies.map((agency, index) => {
                                    return (
                                        <AgencyListItem
                                            index={ index }
                                            agency={ agency }
                                            key={ agency.agencyCode }
                                            setAgencyAlert={ setAlert }
                                            refreshAllAgencies={ setRefreshAllAgencies }
                                            displayAgencyStatus={ displayAgencyStatus() }
                                            displayEditButton={ displayEditButton() }
                                        />
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
            </>
        </CustomTableContainer>
    );
};

export default AgencyListComponent;
