import styled from 'styled-components';
import {Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import {colors} from '../CommonComponents/Typography/Typography.styled';
import {ContentContainer} from '@snsw/react-component-library';

export const TabsContainer = styled(Tabs)`
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: column;
    margin-top: -1.5rem;
`;

export const TabHeadingsContainer = styled(TabList)`
    display: flex;
    align-items: center;
    margin: 0;
    height: 56px;
    flex-direction: row;
    align-content: space-evenly;
    padding-left: unset;
    max-width: 522px;
    list-style-type: none;
`;

export const selectedTab = `
    margin-right: unset;
    height: 56px;
    font-weight: bold;
    color: ${colors.brand.snswSecondaryBlue};
    > svg {
        fill: ${colors.brand.snswSecondaryBlue};
    }
    border-width: 0 0 2px 0;
    border-style: solid;
    border-top-color: #FFFFFF;
    border-right-color: #FFFFFF;
    border-bottom-color: ${colors.brand.snswSecondaryBlue};
    border-left-color: #FFFFFF;
`;

export const TabHeading = styled(Tab)`
    font-family: Gotham A, Gotham B, Gotham, Helvetica, Arial, sans-serif;
    font-size: 1rem;
    display: flex;
    flex-direction: row;
    column-gap: 0.5rem;
    align-items: center;
    justify-content: center;
    color: ${colors.grey.darkGrey};
    padding: 1rem 1.875rem;
    width: 130px;
    height: 56px;
    cursor: pointer;
    &.is-selected {
        ${selectedTab}
    }
    background: ${colors.grey.iceBlue};
    margin-bottom: 0;
`;

export const TabPanelContainer = styled.div`
    max-width: 76.5rem;
    position: relative;
    //left: calc(-50vw + 50%);
    background: ${colors.white};
`;

export const StyledTabPanel = styled(TabPanel)`
    background: ${colors.white};
`;

export const FormFooterContainer = styled.div`
    max-width: 76.5rem;
    position: relative;
    //left: calc(-50vw + 50%);
    background: ${colors.white};
    padding-bottom: 56px;
`;

export const CustomContentContainer = styled(ContentContainer)`
    padding-top: 0;
    padding-bottom: 0; 
    > div {
       margin-left: 0;
    }
`;

export const CustomContentContainerForTabHeadings = styled(ContentContainer)`
    padding-top: 0;
    padding-bottom: 0;
    > div {
        margin-left: 0;
        padding-left: 30px;
    }
`;