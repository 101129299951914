import styled from 'styled-components';
import {ComponentLayout} from '../PageSharedCss.styled';
import {screenSize} from '../../CommonComponents/Typography/Typography.styled';

export const CustomHeaderTop = styled.div`
    background: #242934;
    color: #ffffff;
    padding-top: 1px;
    height: 48px;
`;

export const CustomHeaderContainer = styled(ComponentLayout)`
    block-size: 48px;
    display: flex;
    justify-content: flex-start;
`;

export const CustomHeaderContainerTitle = styled.div`
    block-size: 48px;
    line-height: 16px;
    padding-top: 18px;
    font-family: Gotham, Public Sans, Arial, sans-serif;
    font-size: 15px;
    font-weight: 600;
`;

export const CustomHeaderMain = styled.div`
    background: #fff;
    border-block-end: 1px solid #ecf1f3;
`;

export const CustomHeaderMainContainer = styled(ComponentLayout)`
    block-size: 78px;
    display: grid;
    gap: 2rem;
    /*grid-template-columns: max-content auto max-content;*/
    grid-template-columns: max-content auto;

    @media only screen and (max-width: ${screenSize.mobile}) {
        block-size: 64px;
        gap: 1rem;
        grid-template-columns: auto auto;
    }
`;

export const CustomHeaderMainContainerLogo = styled.a`
    align-items: flex-end;
    background: none !important;
    display: flex;
    margin-inline: auto;
    padding: 0 !important;
    text-decoration: none;
`;

export const LogoDivider = styled.span`
    background: #dee3e5;
    block-size: 46px;
    display: block;
    flex-shrink: 0;
    inline-size: 1px;
    margin-block: 0;
    margin-inline: 13px;

    @media only screen and (max-width: ${screenSize.mobile}) {
        block-size: 29px;
    }
`;

export const LogoNSWGovernment = styled.svg`
    width: 46px;
    height: 49px;
    flex-shrink: 0;
    overflow: visible;

    @media only screen and (max-width: ${screenSize.mobile}) {
        block-size: 32px;
        inline-size: 31px;
    }
`;

export const LogoServiceNSW = styled.svg`
    width: 120px;
    height: 42px;
    flex-shrink: 0;
    overflow: visible;

    @media only screen and (max-width: ${screenSize.mobile}) {
        block-size: 26px;
        inline-size: 74px;
    }
`;

export const CustomHeaderMainContainerButtonWrapper = styled.div`
    justify-self: flex-end;

    @media only screen and (max-width: ${screenSize.mobile}) {
        width: max-content;
    }
`;

