import React from 'react';
import {ComponentWrapper, ImgWrapper, Img, Heading, HeadingWrapper, Body} from './BenefitsComponent.styled';
import {BrandIconFast, BrandIconSupportTechnical, BrandIconDesktop, BrandIconFines} from '@snsw/react-component-library';

const BenefitsComponent = () => {
    return (
        <ComponentWrapper>
            <HeadingWrapper>
                <Heading>Benefits for you and your customers</Heading>
            </HeadingWrapper>
            <Body>
                <div>
                    <div><BrandIconFines/></div>
                    <div>Reduce costs by saving on postage and paper</div>
                </div>
                <div>
                    <div><BrandIconSupportTechnical/></div>
                    <div>Get content advice, including tips around privacy and security</div>
                </div>
                <div>
                    <div><BrandIconFast/></div>
                    <div>Choose a product package tailored to your needs</div>
                </div>
                <div>
                    <div><BrandIconDesktop/></div>
                    <div>Learn how to create, edit and send your  notifications with our guidance</div>
                </div>
            </Body>
            <ImgWrapper><Img src={ `${process.env.PUBLIC_URL}/assets/images/device-mac.png` } alt='macbook'></Img></ImgWrapper>
        </ComponentWrapper>
    );
};

export default BenefitsComponent;
