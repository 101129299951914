import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {scrollToTop, useGlobalState} from '../GlobalStateComponent/GlobalState';
import {useBoolean} from '@snsw/react-component-library/build/utils';
import {AutoSuggest} from '@snsw/react-component-library/build/Components';
import apiServices from '../../services/api-services';
import PreferenceAlertComponent from '../PreferenceAlertComponent/PreferenceAlertComponent';
import {alertTypeToErrorName, preferenceAlertTypes} from '../../constants/applicationConstants';
import {
    FormCheckboxGroup,
    Fieldset,
    FormGroup,
    Modal,
    Form,
    FormInput,
    Col,
    Row,
    Button,
    Heading,
    ComponentLoader, Textarea
} from '@snsw/react-component-library';
import {PortalFormContainer} from '../CommonComponents/CustomComponents/CustomComponents.styled';

export function validateFields(preferenceConfig, valid, setInputErrors, optInOutCheckboxes) {
    if (!preferenceConfig.serviceDescription?.trim()) {
        valid = false;
        setInputErrors(prevState => ({
            ...prevState,
            serviceDescription: {hasError: true, errorMessage: 'Please provide a description'},
        }));
    }

    if (!preferenceConfig.learnMoreLinkText?.trim() && preferenceConfig.learnMoreLinkHref?.trim()) {
        valid = false;
        setInputErrors(prevState => ({
            ...prevState,
            learnMoreLinkText: {
                hasError: true,
                errorMessage: 'Please provide the text for the learn more link or remove the href'
            },
        }));
    }

    if (!preferenceConfig.learnMoreLinkHref?.trim() && preferenceConfig.learnMoreLinkText?.trim()) {
        valid = false;
        setInputErrors(prevState => ({
            ...prevState,
            learnMoreLinkHref: {
                hasError: true,
                errorMessage: 'Please provide the href for the learn more link or remove the text'
            },
        }));
    }
    if (preferenceConfig.linkCheckRequired) {
        const linkAlert = preferenceConfig.preferenceAlerts.find(alert => alert.type === preferenceAlertTypes.USER_ACTION);
        if (linkAlert === undefined) {
            valid = false;
            setInputErrors(prevState => ({
                ...prevState,
                linkActionAlert: {
                    title: {hasError: true, errorMessage: 'Please provide a link Action Alert Title'},
                    actionLinkText: {hasError: true, errorMessage: 'Please provide a link Action Alert Link Text'},
                    actionLinkHref: {hasError: true, errorMessage: 'Please provide a link Action Alert Link Href'},
                }
            }));
        } else {
            if (!linkAlert.title?.trim()) {
                valid = false;
                setInputErrors(prevState => ({
                    ...prevState,
                    linkActionAlert: {
                        ...prevState.linkActionAlert,
                        title: {hasError: true, errorMessage: 'Please provide a link Action Alert Title'},
                    }
                }));
            }
            if (!linkAlert.actionLinkText?.trim()) {
                valid = false;
                setInputErrors(prevState => ({
                    ...prevState,
                    linkActionAlert: {
                        ...prevState.linkActionAlert,
                        actionLinkText: {hasError: true, errorMessage: 'Please provide a link Action Alert Link Text'},
                    }
                }));
            }
            if (!linkAlert.actionLinkHref?.trim()) {
                valid = false;
                setInputErrors(prevState => ({
                    ...prevState,
                    linkActionAlert: {
                        ...prevState.linkActionAlert,
                        actionLinkHref: {hasError: true, errorMessage: 'Please provide a link Action Alert Link Href'},
                    }
                }));
            }
        }
    }
    if (preferenceConfig.duplicateCheckRequired) {
        const duplicateAlert = preferenceConfig.preferenceAlerts
            .find(alert => alert.type === preferenceAlertTypes.DUPLICATE_ACCOUNT);
        if (duplicateAlert === undefined) {
            valid = false;
            setInputErrors(prevState => ({
                ...prevState,
                duplicateAccountAlert: {
                    title: {hasError: true, errorMessage: 'Please provide a Duplicate Account Alert Title'},
                    description: {
                        hasError: true,
                        errorMessage: 'Please provide the Duplicate Account Alert description'
                    },
                }
            }));
        } else {
            if (!duplicateAlert.title?.trim()) {
                valid = false;
                setInputErrors(prevState => ({
                    ...prevState,
                    duplicateAccountAlert: {
                        ...prevState.duplicateAccountAlert,
                        title: {hasError: true, errorMessage: 'Please provide a Duplicate Account Alert Title'},
                    }
                }));
            }
            if (!duplicateAlert.description?.trim()) {
                valid = false;
                setInputErrors(prevState => ({
                    ...prevState,
                    duplicateAccountAlert: {
                        ...prevState.duplicateAccountAlert,
                        description: {
                            hasError: true,
                            errorMessage: 'Please provide the Duplicate Account Alert description'
                        },
                    }
                }));
            }
        }
    }
    if (preferenceConfig.eligibilityCheckRequired) {
        const ineligibleAlert = preferenceConfig.preferenceAlerts.find(alert => alert.type === preferenceAlertTypes.INELIGIBLE);
        if (ineligibleAlert === undefined) {
            valid = false;
            setInputErrors(prevState => ({
                ...prevState,
                ineligibleAlert: {
                    title: {hasError: true, errorMessage: 'Please provide a Ineligible Alert Title'},
                    description: {hasError: true, errorMessage: 'Please provide the Ineligible Alert description'},
                }
            }));
        } else {
            if (!ineligibleAlert.title?.trim()) {
                valid = false;
                setInputErrors(prevState => ({
                    ...prevState,
                    ineligibleAlert: {
                        ...prevState.ineligibleAlert,
                        title: {hasError: true, errorMessage: 'Please provide a Ineligible Alert Title'},
                    }
                }));
            }
            if (!ineligibleAlert.description?.trim()) {
                valid = false;
                setInputErrors(prevState => ({
                    ...prevState,
                    ineligibleAlert: {
                        ...prevState.ineligibleAlert,
                        description: {hasError: true, errorMessage: 'Please provide the Ineligible Alert description'},
                    }
                }));
            }
        }
    }
    if (optInOutCheckboxes.find(checkbox => checkbox.value === preferenceAlertTypes.OPT_OUT).isChecked) {
        const optOutAlert = preferenceConfig.preferenceAlerts.find(alert => alert.type === preferenceAlertTypes.OPT_OUT);
        if (optOutAlert === undefined) {
            valid = false;
            setInputErrors(prevState => ({
                ...prevState,
                optOutAlert: {
                    title: {hasError: true, errorMessage: 'Please provide the Opt Out Alert Title'},
                    description: {hasError: true, errorMessage: 'Please provide the Opt Out Alert description'},
                }
            }));
        } else {
            if (!optOutAlert.title?.trim()) {
                valid = false;
                setInputErrors(prevState => ({
                    ...prevState,
                    optOutAlert: {
                        ...prevState.optOutAlert,
                        title: {hasError: true, errorMessage: 'Please provide the Opt Out Alert Title'},
                    }
                }));
            }
            if (!optOutAlert.description?.trim()) {
                valid = false;
                setInputErrors(prevState => ({
                    ...prevState,
                    optOutAlert: {
                        ...prevState.optOutAlert,
                        description: {hasError: true, errorMessage: 'Please provide the Opt Out Alert description'},
                    }
                }));
            }
        }
    }
    if (optInOutCheckboxes.find(checkbox => checkbox.value === preferenceAlertTypes.OPT_IN).isChecked) {
        const optInAlert = preferenceConfig.preferenceAlerts.find(alert => alert.type === preferenceAlertTypes.OPT_IN);
        if (optInAlert === undefined) {
            valid = false;
            setInputErrors(prevState => ({
                ...prevState,
                optInAlert: {
                    title: {hasError: true, errorMessage: 'Please provide the Opt In Alert Title'},
                    description: {hasError: true, errorMessage: 'Please provide the Opt In Alert description'},
                }
            }));
        } else {
            if (!optInAlert.title?.trim()) {
                valid = false;
                setInputErrors(prevState => ({
                    ...prevState,
                    optInAlert: {
                        ...prevState.optInAlert,
                        title: {hasError: true, errorMessage: 'Please provide the Opt In Alert Title'},
                    }
                }));
            }
            if (!optInAlert.description?.trim()) {
                valid = false;
                setInputErrors(prevState => ({
                    ...prevState,
                    optInAlert: {
                        ...prevState.optInAlert,
                        description: {hasError: true, errorMessage: 'Please provide the Opt In Alert description'},
                    }
                }));
            }
        }
    }
    return valid;
}

const CreatePreferenceTileComponent = () => {
    const navigate = useNavigate();
    const [inputErrors, setInputErrors] = useState({
        serviceId: {hasError: false, errorMessage: 'Please choose a Service'},
        serviceDescription: {hasError: false, errorMessage: 'Please provide a description'},
        learnMoreLinkText: {hasError: false, errorMessage: 'Please provide the text for the learn more link or remove the href'},
        learnMoreLinkHref: {hasError: false, errorMessage: 'Please provide the href for the learn more link or remove the text'},
        linkActionAlert: {
            title: {hasError: false, errorMessage: 'Please provide a link Action Alert Title'},
            actionLinkText: {hasError: false, errorMessage: 'Please provide a link Action Alert Link Text'},
            actionLinkHref: {hasError: false, errorMessage: 'Please provide a link Action Alert Link Href'}
        },
        duplicateAccountAlert: {
            title: {hasError: false, errorMessage: 'Please provide a Duplicate Account Alert Title'},
            description: {hasError: false, errorMessage: 'Please provide the Duplicate Account Alert description'},
        },
        ineligibleAlert: {
            title: {hasError: false, errorMessage: 'Please provide a Ineligible Alert Title'},
            description: {hasError: false, errorMessage: 'Please provide the Ineligible Alert description'},
        },
        optInAlert: {
            title: {hasError: false, errorMessage: 'Please provide the Opt In Alert Title'},
            description: {hasError: false, errorMessage: 'Please provide the Opt In Alert description'},
        },
        optOutAlert: {
            title: {hasError: false, errorMessage: 'Please provide the Opt Out Alert Title'},
            description: {hasError: false, errorMessage: 'Please provide the Opt Out Alert description'},
        },
    });
    const [newPreferenceConfig, setNewPreferenceConfig] = useState({
        serviceId: '',
        serviceDescription: '',
        learnMoreLinkText: '',
        learnMoreLinkHref: '',
        preReqCheckRequired: false,
        linkCheckRequired: false,
        duplicateCheckRequired: false,
        eligibilityCheckRequired: false,
        preferenceAlerts: []
    });
    const [serviceCodeIdMap, setServiceCodeIdMap] = useState({});
    const [inProd] = useGlobalState();
    const [loading, setLoading] = useState(false);
    const [fetchServices, setFetchServices] = useState(true);
    const [showModal, open, close] = useBoolean(false);
    const defaultOptionsChecked = [{ isChecked: false, label: 'Linked Check Required', value: 'linkCheckRequired',
        clarify: 'Is linking required to opt in to this service (liking via service activation)' },
    { isChecked: false, label: 'Duplicate Check Required', value: 'duplicateCheckRequired',
        clarify: 'Check if the customer has linked the service to two MyAccounts' },
    { isChecked: false, label: 'Eligibility Check Required', value: 'eligibilityCheckRequired',
        clarify: 'Check the users eligibility via a bespoke endpoint'},
    ];
    const [linkingCheckCheckboxes, setLinkingCheckCheckboxes] = useState(defaultOptionsChecked);
    const [optInOutCheckboxes, setOptInOutCheckboxes] = useState([
        { isChecked: false, label: 'Display a "By Opting in" Alert', value: preferenceAlertTypes.OPT_IN },
        { isChecked: false, label: 'Display a "By Opting out" Alert', value: preferenceAlertTypes.OPT_OUT },
    ]);

    useEffect(() => {
        scrollToTop();
        if (inProd) {
            //navigate and show error
            navigate('/preference-tiles', {
                state: {
                    variant: 'warning',
                    title: 'Page does not exist in Prod',
                    description: 'You can not add a preference tile directly to prod. Onboard in Non-prod and then promote to prod.',
                    visible: true
                }});
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inProd]);

    const handleChange = event => {
        const {name, value} = event.target;
        setNewPreferenceConfig(prevState => ({
            ...prevState,
            [name]: value
        }));
        setInputErrors(prevState => ({
            ...prevState,
            [name]: {hasError: false, errorMessage: 'Error'},
        }));
    };

    const validateServiceCode = async (serviceCode) => {
        return await apiServices.searchServiceCode(serviceCode)
            .then(serviceConfig => {
                const valid = !serviceConfig.showInPreferencePage;
                if (!valid) {
                    setInputErrors(prevState => ({
                        ...prevState,
                        serviceId: {hasError: true, errorMessage: 'This Service already has a preference tile'},
                    }));
                }
                return valid;
            })
            .catch(() => {
                setInputErrors(prevState => ({
                    ...prevState,
                    serviceId: {hasError: true, errorMessage: 'This Service is invalid'},
                }));
                return false;
            });
    };

    const validateRequest = async () => {
        let valid;
        if (newPreferenceConfig.serviceId === '') {
            setInputErrors(prevState => ({
                ...prevState,
                serviceId: {hasError: true, errorMessage: 'Please choose a Service'},
            }));
            valid = false;
        } else {
            valid = await validateServiceCode(Object.keys(serviceCodeIdMap)
                .find(key => serviceCodeIdMap[key] === newPreferenceConfig.serviceId));
        }
        valid = validateFields(newPreferenceConfig, valid, setInputErrors, optInOutCheckboxes);
        return valid;
    };

    const createTile = async () => {
        setLoading(true);
        validateRequest().then((valid) => {
            if (valid) {
                const serviceCode = Object.keys(serviceCodeIdMap).find(key => serviceCodeIdMap[key] === newPreferenceConfig.serviceId);
                const {learnMoreLinkHref, learnMoreLinkText, ...newPreferenceConfigToCreate} = newPreferenceConfig;
                apiServices.createPreferenceConfig(serviceCode, newPreferenceConfigToCreate).then(() => { navigate('/preference-tiles'); });
                setLoading(false);
            } else {
                window.scrollTo(0, 0);
                setLoading(false);
            }
        });
    };

    const onFilter = (suggestion, value) => {
        if (value !== '' && suggestion) {
            validateServiceCode(suggestion).then(valid => {
                if (valid){
                    setNewPreferenceConfig(prevState => ({
                        ...prevState,
                        serviceId: serviceCodeIdMap[suggestion]
                    }));
                    setInputErrors(prevState => ({
                        ...prevState,
                        serviceId: {hasError: false, errorMessage: 'Please choose a Service'},
                    }));
                } else {
                    setInputErrors(prevState => ({
                        ...prevState,
                        serviceId: {hasError: true, errorMessage: 'This Service already has a preference tile'},
                    }));
                }
            });
        } else {
            setNewPreferenceConfig(prevState => ({
                ...prevState,
                serviceId: null
            }));
        }
    };

    const filterCustomSuggestions = (value) => {
        return Object.keys(serviceCodeIdMap).filter(service => (
            service
                .toLowerCase()
                .trim()
                .includes(value.trim().toLowerCase())
        ));
    };

    const onSearchBarChange = () => {
        if (fetchServices) {
            apiServices.getServiceCodeIdMap().then(map => {
                setServiceCodeIdMap(map);
            });
            setFetchServices(false);
        }
    };

    const handleCheckboxChange = (event) => {
        const val = event.target.value;
        const linkingChecks = [...linkingCheckCheckboxes];
        linkingChecks.forEach(item => {
            if (item.value === val) {
                item.isChecked = !item.isChecked;
                setNewPreferenceConfig(prevState => ({
                    ...prevState,
                    [val]: item.isChecked
                }));
            }
        });
        setLinkingCheckCheckboxes(linkingChecks);
    };

    const handleOptInOutCheckboxesChange = (event) => {
        const val = event.target.value;
        const checkedOptions = [...optInOutCheckboxes];
        checkedOptions.forEach(item => {
            if (item.value === val) {
                item.isChecked = !item.isChecked;
            }
        });
        setOptInOutCheckboxes(checkedOptions);
    };

    const handleContentCallback = useCallback((event, alertType) => {
        const {name, value} = event.target;
        if (newPreferenceConfig.preferenceAlerts.find(alert => alert.type === alertType) === undefined) {
            const newAlert = { type: alertType, title: '', description: '', actionLinkText: '', actionLinkHref: ''};
            newAlert[name] = value;
            setNewPreferenceConfig(prevState => ({
                ...prevState,
                preferenceAlerts: [...prevState.preferenceAlerts, newAlert]
            }));
        } else {
            setNewPreferenceConfig(prevState => ({
                ...prevState,
                preferenceAlerts: [...prevState.preferenceAlerts.map((alert) =>
                    alert.type === alertType
                        ? { ...alert, [name]: value }
                        : { ...alert }
                )]
            }));
            const alertErrorName = alertTypeToErrorName(alertType);
            setInputErrors(prevState => ({
                ...prevState,
                [alertErrorName]: {
                    ...prevState[alertErrorName],
                    [name]: {hasError: false, errorMessage: 'Error'}
                },
            }));
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setNewPreferenceConfig, newPreferenceConfig]);

    return (
        <PortalFormContainer>
            { loading && <ComponentLoader fullPage label='Creating the Preference Config...'/> }
            <Heading
                className='page-title'
                style={ { height: 'fit-content', margin: '6px 0 2rem 0'} }
                level={ 1 }
            >Set up a new Preference tile</Heading>
            <Form data-test='new-preference-tile-form'>
                <Row>
                    <Col span={ 6 }>
                        <FormGroup
                            id='service-code-input'
                            label='Service'
                            hasError={ inputErrors.serviceId.hasError }
                            errorMessage={ inputErrors.serviceId.errorMessage }
                        >
                            <AutoSuggest
                                id='serviceCodeSearch'
                                name='service'
                                suggestions={ Object.keys(serviceCodeIdMap) }
                                onSelect={ onFilter }
                                onChange={ onSearchBarChange }
                                onBlur={ onFilter }
                                placeholder={ 'Search' }
                                filterCustomSuggestions={ filterCustomSuggestions }
                            />
                        </FormGroup>
                        <FormGroup
                            id='preference-description-textArea'
                            label='Description'
                            errorMessage={ inputErrors.serviceDescription.errorMessage }
                            hasError={ inputErrors.serviceDescription.hasError }
                            margin={ { top: 'lg' } }
                        >
                            <Textarea
                                name='serviceDescription'
                                onChange={ handleChange }
                                value={ newPreferenceConfig.serviceDescription }
                            />
                        </FormGroup>
                        <Fieldset legend='Learn More Link' margin={ { top: 'xxl' } } >
                            <FormInput
                                id='learn_more_link_text-input'
                                name='learnMoreLinkText'
                                label='Learn more link text'
                                onChange={ handleChange }
                                value={ newPreferenceConfig.learnMoreLinkText }
                                errorMessage={ inputErrors.learnMoreLinkText.errorMessage }
                                hasError={ inputErrors.learnMoreLinkText.hasError }
                                isOptional
                            />
                            <FormInput
                                id='learn_more_link_href-input'
                                name='learnMoreLinkHref'
                                label='Learn more link href'
                                onChange={ handleChange }
                                value={ newPreferenceConfig.learnMoreLinkHref }
                                errorMessage={ inputErrors.learnMoreLinkHref.errorMessage }
                                hasError={ inputErrors.learnMoreLinkHref.hasError }
                                isOptional
                            />
                        </Fieldset>
                        <Fieldset
                            legend='Prerequisite Checks'
                            helpMessage='Select any Prerequisite checks that are required for this service'
                            margin={ { top: 'xxl' } }
                        >
                            <FormCheckboxGroup
                                legend=''
                                id='required-linking-checks-group'
                                name='required-linking-checks'
                                options={ linkingCheckCheckboxes }
                                onChange={ handleCheckboxChange }
                                isOptional
                            />
                        </Fieldset>
                        <Fieldset legend='Preference Alerts' margin={ { top: 'xxl' } }>
                            <FormCheckboxGroup
                                id='opt-in-out-alerts-checkboxes'
                                name='opt-in-out-alerts'
                                legend=''
                                options={ optInOutCheckboxes }
                                onChange={ handleOptInOutCheckboxesChange }
                                isOptional
                            />
                            {optInOutCheckboxes.find(checkbox => checkbox.value === preferenceAlertTypes.OPT_IN).isChecked &&
                                <PreferenceAlertComponent
                                    callback={ handleContentCallback }
                                    legend={ 'By Opting in Alert' }
                                    helpMessage={ 'The alert to be displayed to the user when they opt-in to the service' }
                                    type={ preferenceAlertTypes.OPT_IN }
                                    inputErrors={ inputErrors.optInAlert }
                                    alertValues={ newPreferenceConfig.preferenceAlerts
                                        .find(alert => alert.type === preferenceAlertTypes.OPT_IN) }
                                />
                            }
                            {optInOutCheckboxes.find(checkbox => checkbox.value === preferenceAlertTypes.OPT_OUT).isChecked &&
                                <PreferenceAlertComponent
                                    callback={ handleContentCallback }
                                    legend={ 'By Opting out Alert' }
                                    helpMessage={ 'The alert to be displayed to the user when they opt-out to the service' }
                                    type={ preferenceAlertTypes.OPT_OUT }
                                    inputErrors={ inputErrors.optOutAlert }
                                    alertValues={ newPreferenceConfig.preferenceAlerts
                                        .find(alert => alert.type === preferenceAlertTypes.OPT_OUT) }
                                />
                            }
                            {newPreferenceConfig.duplicateCheckRequired &&
                                <PreferenceAlertComponent
                                    callback={ handleContentCallback }
                                    legend={ 'Duplicate Account Alert' }
                                    helpMessage={ 'The alert to be displayed to the user when they have a duplicate account' }
                                    type={ preferenceAlertTypes.DUPLICATE_ACCOUNT }
                                    inputErrors={ inputErrors.duplicateAccountAlert }
                                    alertValues={ newPreferenceConfig.preferenceAlerts
                                        .find(alert => alert.type === preferenceAlertTypes.DUPLICATE_ACCOUNT) }
                                />
                            }
                            {newPreferenceConfig.eligibilityCheckRequired &&
                                <PreferenceAlertComponent
                                    callback={ handleContentCallback }
                                    legend={ 'Ineligible Account Alert' }
                                    helpMessage={ 'The alert to be displayed to the user when they have an ineligible account' }
                                    type={ preferenceAlertTypes.INELIGIBLE }
                                    inputErrors={ inputErrors.ineligibleAlert }
                                    alertValues={ newPreferenceConfig.preferenceAlerts
                                        .find(alert => alert.type === preferenceAlertTypes.INELIGIBLE) }
                                />
                            }
                            {newPreferenceConfig.linkCheckRequired &&
                                <PreferenceAlertComponent
                                    callback={ handleContentCallback }
                                    legend={ 'Link Action Alert' }
                                    helpMessage={ 'The Linking action to display' }
                                    type={ preferenceAlertTypes.USER_ACTION }
                                    inputErrors={ inputErrors.linkActionAlert }
                                    alertValues={ newPreferenceConfig.preferenceAlerts
                                        .find(alert => alert.type === preferenceAlertTypes.USER_ACTION) }
                                />
                            }
                        </Fieldset>
                    </Col>
                </Row>
                <div style={ { marginTop: '4rem', display: 'flex', gap: '2rem' } }>
                    <Button
                        onClick={ createTile }
                        variant='primary'
                        id='createPreferenceTileBtn'
                    >Create Preference Tile</Button>
                    <Button variant='secondary' id='backBtn' onClick={ open }>Back</Button>
                    {showModal && (
                        <Modal
                            title='Are you sure you want to discard your unsaved changes?' // eslint-disable-next-line max-len
                            description='By going back you will loose all the progress on this form and no Preference Tile will be created.'
                            buttons={ [
                                { text: 'Discard', onClick: () => navigate('/preference-tiles') },
                                { text: 'Cancel', onClick: () => close() }
                            ] }
                        />
                    )}
                </div>
            </Form>
        </PortalFormContainer>
    );
};

export default CreatePreferenceTileComponent;
