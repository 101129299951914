import {GreenCodeFont, Paragraph} from './APIDocsComponent.styled';
import React from 'react';

const TroubleShootingServiceErrorCode = (props) => {
    return (
        <div id={ props.id }>
            <Paragraph>curl --location -g --request
                POST <GreenCodeFont>{'\'https://api-psm.g.testservicensw.net/notifications/v2/guest\''}</GreenCodeFont> \</Paragraph>
            <Paragraph>--header <GreenCodeFont>{'\'Authorization: Bearer <token>\''}</GreenCodeFont> \</Paragraph>
            <Paragraph>--header <GreenCodeFont>{'\'x-service-code: EXAMPLE_SERVICE_CODE\''}</GreenCodeFont> \</Paragraph>
            <Paragraph>--data-raw <GreenCodeFont>{'\'{'}</GreenCodeFont></Paragraph>
            {/* eslint-disable-next-line max-len */}
            <Paragraph style={ {textIndent: '1em'} }><GreenCodeFont>"notificationCode"</GreenCodeFont>: <GreenCodeFont>"EXAMPLE_NOTIFICATION_CODE"</GreenCodeFont>,</Paragraph>
            {/* eslint-disable-next-line max-len */}
            <Paragraph style={ {textIndent: '1em'} }><GreenCodeFont>"contact"</GreenCodeFont>: <GreenCodeFont>"example@yopmail.com"</GreenCodeFont>,</Paragraph>
            {/* eslint-disable-next-line max-len */}
            <Paragraph style={ {textIndent: '1em'} }><GreenCodeFont>"variables"</GreenCodeFont>: [</Paragraph>
            <Paragraph style={ {textIndent: '2em'} }>{'{'}</Paragraph>
            {/* eslint-disable-next-line max-len */}
            <Paragraph style={ {textIndent: '3em'} }><GreenCodeFont>"name"</GreenCodeFont>: <GreenCodeFont>"size"</GreenCodeFont>,</Paragraph>
            {/* eslint-disable-next-line max-len */}
            <Paragraph style={ {textIndent: '3em'} }><GreenCodeFont>"value"</GreenCodeFont>: <GreenCodeFont>"78MB"</GreenCodeFont></Paragraph>
            <Paragraph style={ {textIndent: '2em'} }>{'}'}</Paragraph>
            <Paragraph style={ {textIndent: '1em'} }>{'],'}</Paragraph>
            <Paragraph style={ {textIndent: '1em'} }><GreenCodeFont>"attachments"</GreenCodeFont>: []</Paragraph>
            <Paragraph>{'}\''}</Paragraph>
        </div>
    );
};

export default TroubleShootingServiceErrorCode;