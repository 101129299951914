import MultiSelectComponent from '../CommonComponents/SelectComponent/MultiSelectComponent';
import * as PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {TertiaryButton} from '../CommonComponents/CustomComponents/CustomComponents.styled';

const FilterPanelComponent = ({showFilter, dataSource, setResults}) => {
    const agencySet = new Set();
    const serviceSet = new Set();
    const agencyFilterRef = useRef(MultiSelectComponent);
    const serviceFilterRef = useRef(MultiSelectComponent);
    const channelFilterRef = useRef(MultiSelectComponent);
    const typeFilterRef = useRef(MultiSelectComponent);
    const statusFilterRef = useRef(MultiSelectComponent);

    dataSource.forEach(item => {
        agencySet.add(item.agencyDescription);
        serviceSet.add(item.serviceDescription);
    });

    const allAgencies = sortAndMap(agencySet);
    const allServices = sortAndMap(serviceSet);

    const [filterOnAgencies, setFilterOnAgencies] = useState([]);
    const [filterOnServices, setFilterOnServices] = useState([]);
    const [filterOnChannels, setFilterOnChannels] = useState([]);
    const [filterOnTypes, setFilterOnTypes] = useState([]);
    const [filterOnStatuses, setFilterOnStatuses] = useState([]);
    const [services, setServices] = useState([]);

    const channels = [
        'Email',
        'SMS',
        'Inbox',
        'Push'
    ];
    const notificationTypeNames = [
        {value: 'CAMPAIGN', label: 'Campaign'},
        {value: 'AUTOMATED', label: 'Automated'}
    ];
    // const durations = [
    //     'Past week',
    //     'Past month',
    //     'Past year',
    //     'Past 2 years',
    //     'Custom date range'
    // ];
    const statuses = [
        {value: 'IN_REVIEW', label: 'In review'},
        {value: 'LIVE', label: 'Live'},
        {value: 'SENT', label: 'Sent'}
    ];
    const agencyTitle = 'Agency';
    const serviceTitle = 'Service';
    const channelTitle = 'Channel';
    const typeTitle = 'Type';
    // const lastUpdatedTitle = 'Date last updated';
    const statusTitle = 'Status';

    const setInitialOptions = (values) => {
        let count = 0;
        const options = [];
        values.forEach((obj) => {
            const exists = options.findIndex(item => item.label === obj) !== -1;
            if (!exists) {
                options.push({value: count++, label: obj});
            }
        });
        return options;
    };

    function sortAndMap(set) {
        return Array.from(set)
            .sort((a, b) => a.localeCompare(b))
            .map((label, index) => ({
                value: index,
                label: label
            }));
    }

    const agencyServiceMap = dataSource.reduce((resultMap, obj) => {
        const {agencyDescription, serviceDescription} = obj;
        if (!resultMap[agencyDescription]) {
            resultMap[agencyDescription] = new Set();
        }
        resultMap[agencyDescription].add(serviceDescription);
        return resultMap;
    }, {});

    const getServicesByAgencies = (agencies) => {
        if (agencies.length === 0) {
            return allServices;
        }

        const servicesSet = new Set();
        agencies.forEach(agency => {
            if (agencyServiceMap[agency]) {
                agencyServiceMap[agency].forEach(service => servicesSet.add(service));
            }
        });
        return sortAndMap(servicesSet);
    };

    function filteredBy(selectedValues, targetAttribute, data) {
        if (selectedValues?.length === 0) { // no filter
            return data;
        }
        return data.filter(notification => selectedValues.includes(notification[targetAttribute]));
    }

    useEffect(() => {
        let filtered = dataSource;
        filtered = filteredBy(filterOnAgencies, 'agencyDescription', filtered);
        filtered = filteredBy(filterOnServices, 'serviceDescription', filtered);
        filtered = filteredBy(filterOnTypes, 'notificationType', filtered);
        filtered = filteredBy(filterOnStatuses, 'status', filtered);
        if (filterOnChannels?.length > 0) {
            filtered = filtered.filter(n => filterOnChannels.some((c) => n.channels.includes(c)));
        }
        setResults(filtered);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterOnAgencies, filterOnServices, filterOnChannels, filterOnTypes, filterOnStatuses, dataSource,
        setResults]);

    const getFilterLabels = (selectedValue) => {
        const labels = [];
        selectedValue.forEach(s => labels.push(s.label));
        return labels;
    };

    const getFilterValues = (selectedValue) => {
        const values = [];
        selectedValue.forEach(s => values.push(s.value));
        return values;
    };

    const filterByAgency = (selectedValue) => {
        const labels = getFilterLabels(selectedValue);
        if (selectedValue.length === 0) {
            setFilterOnAgencies(getFilterLabels(allAgencies));
            setServices(allServices);
        } else {
            setFilterOnAgencies(labels);
            setServices(getServicesByAgencies(labels));
        }
    };

    const filterByService = (selectedValue) => {
        const labels = getFilterLabels(selectedValue);
        if (selectedValue.length === 0) {
            setFilterOnServices(getFilterLabels(allServices));
        } else {
            setFilterOnServices(labels);
        }
    };

    const filterByChannels = (selectedValue) => {
        const labels = getFilterLabels(selectedValue);
        if (selectedValue.length === 0) {
            setFilterOnChannels(channels);
        } else {
            setFilterOnChannels(labels);
        }
    };

    const filterByNotificationType = (selectedValue) => {
        if (selectedValue.length > 0) {
            setFilterOnTypes(getFilterValues(selectedValue));
        } else {
            setFilterOnTypes(getFilterValues(notificationTypeNames));
        }
    };

    const filterByStatus = (selectedValue) => {
        if (selectedValue.length > 0) {
            setFilterOnStatuses(getFilterValues(selectedValue));
        } else {
            setFilterOnStatuses(getFilterValues(statuses));
        }
    };
    const clearAllFilters = () => {
        agencyFilterRef.current.setState({
            optionSelected: null
        });
        serviceFilterRef.current.setState({
            optionSelected: null
        });
        channelFilterRef.current.setState({
            optionSelected: null
        });
        statusFilterRef.current.setState({
            optionSelected: null
        });
        typeFilterRef.current.setState({
            optionSelected: null
        });
        setFilterOnAgencies([]);
        setFilterOnServices([]);
        setFilterOnChannels([]);
        setFilterOnTypes([]);
        setFilterOnStatuses([]);
    };

    return <>
        <div id={ 'filterPanel' } data-testid={ 'filterPanel' }
            style={ {
                display: showFilter ? 'flex' : 'none',
                gap: '10px',
                whiteSpace: 'nowrap',
                alignItems: 'center',
                marginTop: '-6px'
            } }>

            <MultiSelectComponent
                name={ 'agency-filter' }
                placeholder={ agencyTitle }
                initialValue={ agencyTitle }
                options={ allAgencies }
                onOptionChange={ filterByAgency }
                label={ 'Filter by agencies' }
                ref={ agencyFilterRef }
                width={ `${agencyTitle.length + 8}ch` }
                hideLabel
                hideTags
            />
            <MultiSelectComponent
                name={ 'service-filter' }
                placeholder={ serviceTitle }
                initialValue={ serviceTitle }
                options={ filterOnAgencies.length === 0 ? allServices : services }
                onOptionChange={ filterByService }
                label={ 'Filter by services' }
                ref={ serviceFilterRef }
                width={ `${serviceTitle.length + 8}ch` }
                hideLabel
                hideTags
            />
            <MultiSelectComponent
                name={ 'channel-filter' }
                placeholder={ channelTitle }
                initialValue={ channelTitle }
                options={ setInitialOptions(channels) }
                onOptionChange={ filterByChannels }
                label={ 'Filter by channels' }
                ref={ channelFilterRef }
                width={ `${channelTitle.length + 8}ch` }
                hideLabel
                hideTags
            />
            <MultiSelectComponent
                name={ 'notification-type-filter' }
                placeholder={ typeTitle }
                initialValue={ typeTitle }
                options={ notificationTypeNames }
                onOptionChange={ filterByNotificationType }
                label={ 'Filter by notification types' }
                ref={ typeFilterRef }
                width={ `${typeTitle.length + 8}ch` }
                hideLabel
                hideTags
            />
            <MultiSelectComponent
                name={ 'status-filter' }
                placeholder={ statusTitle }
                initialValue={ statusTitle }
                options={ statuses }
                onOptionChange={ filterByStatus }
                label={ 'Filter by statuses' }
                ref={ statusFilterRef }
                width={ `${statusTitle.length + 8}ch` }
                hideLabel
                hideTags
            />
            <TertiaryButton id='clearFiltersBtn' variant='tertiary' style={ {marginLeft: '-3rem', marginTop: '1rem'} }
                onClick={ clearAllFilters }>Clear filters</TertiaryButton>
        </div>
    </>
    ;
};

FilterPanelComponent.propTypes = {
    showFilter: PropTypes.bool,
    setResults: PropTypes.func,
    dataSource: PropTypes.arrayOf(PropTypes.any)
};

export default FilterPanelComponent;