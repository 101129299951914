import React, {useEffect, useMemo, useState} from 'react';
import apiServices from '../../services/api-services';
import {Button, SkeletonHeading, Table, TableBody, TableCell, TableHead, TableRow} from '@snsw/react-component-library';
import {TeamsHeading} from './TeamsComponent.styled';
import SearchBar from '../CommonComponents/SearchBar/SearchBar';
import {CustomTableContainer} from '../CommonComponents/CustomComponents/CustomComponents.styled';
import {Link} from 'react-router-dom';
import {Cell, TH, TR} from '../DashboardComponent/DashboardComponent.styled';
import {orderButtons, useUserRoleState} from '../GlobalStateComponent/GlobalState';
import {IconAdd, IconChevronRight} from '@snsw/react-component-library/build/Icons/system';

const TeamsComponent = () => {
    const [teams, setTeams] = useState([]);
    const [displayedTeams, setDisplayedTeams] = useState([]);
    const [order, setOrder] = useState('ASC');
    const {userRole} = useUserRoleState();

    useEffect(() => {
        const servicesMap = {};
        //Maps service code to their descriptions in the agency object
        apiServices.fetchAgencies(true).then(agencies => {
            agencies.forEach(agency => {
                agency.services.forEach(service => {
                    servicesMap[service.serviceCode] = service.description ? service.description : service.serviceCode;
                });
            });
            //Mutates team.authorisedServices to have service descriptions instead of service codes.
            apiServices.fetchTeams().then(teamsData => {
                teamsData = teamsData.map(team => {
                    team.authorisedServices = team.authorisedServices.map(serviceCode =>
                        serviceCode === 'ALL' ? 'All Services' : servicesMap[serviceCode]);
                    return team;
                });
                setTeams(teamsData);
            });
        })
            .catch(e => console.log(e));
    }, []);

    const sort = (columnName, displayedTeams, setDisplayedTeams, order, setOrder) => {
        const comparisonFunction = (a, b, order) => {
            if (order === 'ASC') {
                return a < b ? 1 : -1;
            } else if (order === 'DSC') {
                return a > b ? 1 : -1;
            }
        };

        const newOrder = order === 'ASC' ? 'DSC' : 'ASC';

        let sortingFunction;
        switch (columnName) {
        case 'Team':
            sortingFunction = (a, b) => comparisonFunction(a.name.toLowerCase(),
                b.name.toLowerCase(), newOrder);
            break;
        case 'Services':
            sortingFunction = (a, b) => comparisonFunction(a.authorisedServices.join(', ').toLowerCase(),
                b.authorisedServices.join(', ').toLowerCase(), newOrder);
            break;
        default:
            return;
        }

        // Sort teams and update state
        setDisplayedTeams([...displayedTeams.sort(sortingFunction)]);

        // Update the order state
        setOrder(newOrder);
    };

    const handleOrderButtonClicked = (columnName) => {
        sort(columnName, displayedTeams, setDisplayedTeams, order, setOrder);
    };

    const getEmptyTable = () => {
        const emptyTableRows = [];
        for (let i = 0; i < 3; i++) {
            const emptyTableCells = [];
            for (let j = 0; j < 3; j++) {
                emptyTableCells.push(
                    <TableCell key={ `${i}.${j}` } id={ `${i}.${j}` }>
                        <SkeletonHeading level={ 5 } noMargin/>
                    </TableCell>
                );
            }
            emptyTableRows.push(
                <TableRow id={ i } key={ i }>
                    {emptyTableCells}
                </TableRow>
            );
        }
        return emptyTableRows;
    };
    const searchKeys = useMemo(() => ['name', 'authorisedServices'], []);

    return <>
        <CustomTableContainer role='main'>
            <TeamsHeading>Teams</TeamsHeading>
            <div style={ {display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '1rem'} }>
                <SearchBar setResults={ setDisplayedTeams }
                    searchKeys={ searchKeys }
                    dataSource={ teams }
                    dataSetName={ 'team' }
                />
                <Button
                    iconStart={ <IconAdd/> }
                    variant='primary'
                    as={ Link }
                    href='/teams/add'
                    style={ {
                        fontFamily: 'Public Sans',
                        fontWeight: '600',
                        textDecoration: 'none',
                        margin: '0.948rem 0 0.948rem 0'
                    } }
                    data-testid={ 'addTeamButton' }
                >
                    &nbsp;Add team
                </Button>
            </div>
            <div style={ {display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '7px'} }>
                <Table data-testid={ 'teamTable' }>
                    <TableHead>
                        <TR key={ 'th-row' } style={ {
                            background: '#FFFFFF', borderBottom: '2px solid #DEE3E5',
                            borderRadius: '6px', overflow: 'hidden'
                        } }>
                            <TH key={ 'th-teams' } width={ '30%' } data-testid={ 'teamHeading' }>
                                {orderButtons('Order Team Name in Ascending/Descending Order',
                                    'Team', handleOrderButtonClicked)}
                            </TH>
                            <TH key={ 'th-services' } width={ '64%' } data-testid={ 'servicesHeading' }>
                                {orderButtons('Order Auth Services in Ascending/Descending Order',
                                    'Services', handleOrderButtonClicked)}
                            </TH>
                        </TR>
                    </TableHead>
                    <TableBody style={ {background: '#FFFFFF'} }>
                        {teams.length === 0 ?
                            <>{getEmptyTable()}</>
                            :
                            displayedTeams.length === 0 ?
                                <TR style={ {background: '#FFFFFF'} }>
                                    <TableCell colSpan={ 3 }>No teams were found. Try again with a different search
                                        term.</TableCell>
                                </TR>
                                :
                                displayedTeams.map((team) => {
                                    return <TR key={ team.id }>
                                        <Cell key={ `${team.id}.1` }>{team.name}</Cell>
                                        <Cell key={ `${team.id}.2` }>
                                            <div style={ {
                                                display: 'flex', flexDirection: 'row',
                                                alignItems: 'center', justifyContent: 'space-between'
                                            } }>
                                                {team.authorisedServices.join(', ')}
                                                <div key={ `${team.id}.3` } style={ {
                                                    display: 'flex',
                                                    height: '39px'
                                                } }>
                                                    {userRole.permissions.TEAM.includes('UPDATE') &&
                                                        <Button key={ `${team.id}.3.btn` }
                                                            variant='link'
                                                            id={ `editTeamBtn-${team.name}` }
                                                            data-testid={ `editTeamBtn-${team.name}` }
                                                            as={ Link } href={ `/teams/${team.id}/update` }
                                                            style={ {pointerEvents: 'auto'} }>
                                                            <title>Edit team</title>
                                                            <IconChevronRight key={ `${team.id}.3.icon` }/>
                                                        </Button>
                                                    }
                                                </div>
                                            </div>
                                        </Cell>
                                    </TR>;
                                })
                        }
                    </TableBody>
                </Table>
            </div>
        </CustomTableContainer>
    </>
    ;
};

export default TeamsComponent;