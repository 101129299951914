import React from 'react';
import { FormGroup, Input } from '@snsw/react-component-library';
import * as PropTypes from 'prop-types';

export function SubjectComponent(props) {
    return <FormGroup
        id={ props.id +'form' }
        label={ props.label }
        helpMessage={ props.helpMessage }
        hasError={ !props.isOptional && props.inputError.hasError }
        errorMessage={ props.inputError.errorMessage }
        isOptional={ props.isOptional }
    >
        <Input
            data-testid={ props.dataTest }
            id={ props.id }
            name={ props.name }
            inputWidth={ 'xl' }
            value={ props.value }
            onChange={ props.onChange }
            disabled={ props.disabled }
        />
    </FormGroup>
    ;
}

SubjectComponent.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func
};
