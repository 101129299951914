import styled from 'styled-components';

export const GreenCodeFont = styled.span`
    color: #00D80B;
`;

export const Paragraph = styled.p`
    margin: 0px;
    + p {
        margin-top: 10px;
    }
`;