import React, {memo, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {defaultSearchInputWidth, SearchBarContainer, SearchIconStyled, SearchInput} from './SearchBar.styled';

const SearchBar = ({dataSource, searchKeys, setResults, searchInputWidth, customStyle}) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [searchFocus, setSearchFocus] = useState(false);

    useEffect(() => {
        if (searchQuery && searchQuery !== '') {
            const results = [];
            searchKeys.forEach(eachKey => {
                dataSource.forEach(eachData => {
                    Object.entries(eachData).forEach(([key, value]) => {
                        // The value is stringified, because the value in the data can be any type (int, string, Obj, etc)
                        if (key === eachKey && value && JSON.stringify(value).toLowerCase()
                            .includes(searchQuery.toLowerCase())) {
                            results.push(eachData);
                        }
                    });
                });
            });
            if (results && results.length > 0) {
                // There can be a match in multiple columns so duplicates are possible in the results
                // We use a Set to ensure only unique values are in the result array
                const resultSet = new Set(results);
                // We turn the Set into an array, because Sets do not have the same inherit methods as arrays in JS.
                setResults(Array.from(resultSet));
            } else {
                setResults([]);
            }
        } else {
            setResults(dataSource);
        }
    }, [dataSource, setResults, searchQuery, searchKeys]);

    const handleClearSearch = () => {
        setSearchQuery('');
    };
    return (
        <SearchBarContainer data-testid={ 'searchBar' } style={ customStyle }>
            <label style={ {display: 'none'} } id='search-bar-label' htmlFor='searchQueryInput'>Search</label>
            <SearchInput name='searchQueryInput'
                id='searchQueryInput'
                data-testid={ 'searchQueryInput' }
                type='text'
                placeholder={ 'Search' }
                onChange={ (event) => {
                    setSearchQuery(event.target.value);
                } }
                value={ searchQuery }
                onFocus={ () => setSearchFocus(true) }
                onBlur={ () => setSearchFocus(false) }
                aria-labelledby={ 'search-bar-label' }
                style={ {width: searchInputWidth || defaultSearchInputWidth} }

            />
            <SearchIconStyled data-testid={ 'clearSearchBtn' }
                isSearchBarActive={ searchQuery.length && searchQuery.length > 0 }
                onClick={ searchQuery.length && searchQuery.length > 0 ?
                    handleClearSearch :
                    null
                }
                isSearchFocused={ searchFocus }
            />
        </SearchBarContainer>
    );
};

SearchBar.propTypes = {
    setResults: PropTypes.func,
    dataSource: PropTypes.array,
    searchKeys: PropTypes.array,
    searchInputWidth: PropTypes.any,
    customStyle: PropTypes.any
};
export default memo(SearchBar);
