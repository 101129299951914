import React, {useCallback, useEffect} from 'react';
import Heading from '@snsw/react-component-library/build/Components/Headings/Heading';
import { ContentSubHeader } from './NotificationContent.styled';
import { InboxContentComponent } from './InboxContentComponent';
import { EmailContentComponent } from './EmailContentComponent';
import { SmsContentComponent } from './SmsContentComponent';
import { PushContentComponent } from './PushContentComponent';
import HorizontalRule from '@snsw/react-component-library/build/HorizontalRule/hr.styled';
import { ContentVariableComponent } from './ContentVariables/ContentVariableComponent';
import {InPageAlert} from '@snsw/react-component-library';
import {Buffer} from 'buffer';

const NotificationContentComponent = ({
    setNotificationRequest,
    notificationRequest,
    inputErrors,
    setInputErrors,
    notificationsConfig,
    setOptionalVariables,
    optionalVariables,
    htmlValidation
}) => {
    const {sendEmailEnabled, sendSmsEnabled, sendPushEnabled, showInInbox} = notificationsConfig;

    useEffect(() => {
        if (notificationsConfig.inboxContent !== undefined) {
            const decodedContent = Buffer.from(notificationsConfig.inboxContent, 'base64').toString('utf8');
            // searching for {{request.<variableName>}}
            const regexp = /{{[^}]*request.[a-zA-z0-9]*[^}]*}}/g;
            const matches = Array.from(decodedContent.matchAll(regexp)).map(match => match[0]);
            const variableList = [];
            matches.forEach(match => {
                let variable = match.replace(/{{[^}]*request\./, '');
                variable = variable.replace(/\s*(".*")*}}/, '');
                if (!variableList.includes(variable)) { variableList.push(variable); }
                if (decodedContent.match(new RegExp('{{\\s*#if\\s*request.' + variable + '\\s*}}')) != null) {
                    setOptionalVariables(prevState => [...prevState , variable]);
                }
            });
            setNotificationRequest(prevState => ({
                ...prevState,
                notificationDetails: {
                    data: variableList.map(variable => {
                        return { name: variable, value: '' };
                    })
                },
            }));
        }
    }, [notificationsConfig.inboxContent, setNotificationRequest, setOptionalVariables]);

    const getInputErrorName = (name, channel) => {
        if (channel === 'web') {
            switch (name) {
            case 'title': return 'inboxTitle';
            case 'content': return 'inboxContent';
            default: return 'inboxVariable';
            }
        } else if (channel === 'push') {
            switch (name) {
            case 'title': return 'pushTitle';
            case 'content': return 'pushContent';
            default: return 'inboxVariable';
            }
        } else if (channel === 'email') {
            switch (name) {
            case 'subject': return 'emailSubject';
            case 'content': return 'emailContent';
            default: return 'inboxVariable';
            }
        } else if (channel === 'sms') {
            switch (name) {
            case 'content': return 'smsContent';
            default: return 'inboxVariable';
            }
        }
    };

    const variableContentCallback = useCallback(event => {
        const {name, value} = event.target;
        setNotificationRequest(prevState => ({
            ...prevState,
            notificationDetails: {
                data: prevState.notificationDetails.data.map(variable => {
                    if (variable.name === name) {
                        return {...variable, value: value};
                    }
                    return variable;
                })
            },
        })); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setNotificationRequest, inputErrors]);

    const handleContentCallback = useCallback((event, channel) => {
        const {name, value} = event.target;
        const inputErrorName = getInputErrorName(name, channel);
        if (inputErrors[inputErrorName].hasError) {
            setInputErrors(prevState => ({
                ...prevState,
                [inputErrorName]: { ...prevState[inputErrorName], hasError: false }
            }));
        }
        setNotificationRequest(prevState => ({
            ...prevState,
            [channel]: { ...prevState[channel], [name]: value }
        })); // eslint-disable-next-line react-hooks/exhaustive-deps
        if (name === 'content' && value.length > 0 && htmlValidation.current ) {
            htmlValidation.current = false;
        }
    }, [setNotificationRequest, inputErrors, setInputErrors, htmlValidation]);

    return (
        <div>
            <Heading data-testid='content-header' level={ 2 } style={ {height: 'fit-content'} }>Notification Content</Heading>
            <ContentSubHeader data-testid='content-subheader'>
                <p>
                    Provide notification content for the channels you wish to use for this notification. Fields left empty
                    will not be used. When sending via Digital Notification inbox or Email, both the subject and content
                    must be provided.
                </p>
            </ContentSubHeader>
            <HorizontalRule/>
            {(sendEmailEnabled === false && sendSmsEnabled === false && showInInbox === false) &&
                <InPageAlert id='notification-content-error' variant={ 'error' } title={ 'No enabled channels' }>
                    <p data-test='notStatusDesc'>
                        This notification has no notification channels enabled so all notifications will be unsuccessful.
                    </p>
                </InPageAlert>
            }
            {showInInbox !== false && <>
                <InboxContentComponent
                    webInboxCallback={ (event) => handleContentCallback(event, 'web') }
                    inputErrors={ inputErrors }
                    value={ notificationRequest.web }
                />
                {notificationRequest.notificationDetails.data.length > 0 &&
                    <ContentVariableComponent
                        variableContentCallback={ variableContentCallback }
                        inputError={ inputErrors.inboxVariable }
                        notificationRequest={ notificationRequest }
                        optionalVariables={ optionalVariables }
                    />
                }
                <HorizontalRule/>
            </>}
            {(sendPushEnabled !== false && showInInbox !== false)&& <>
                <PushContentComponent
                    title='Push Notification'
                    id='push-notification'
                    contentCallback={ (event) => handleContentCallback(event, 'push') }
                    value={ notificationRequest.push }
                    inputError={ inputErrors.pushContent }
                />
                <HorizontalRule/>
            </>}
            {sendEmailEnabled !== false && <>
                <EmailContentComponent
                    emailCallback={ (event) => handleContentCallback(event, 'email') }
                    notificationRequest={ notificationRequest }
                    setNotificationRequest={ setNotificationRequest }
                    inputErrors={ inputErrors }
                    setInputErrors={ setInputErrors }
                    htmlValidation={ htmlValidation }
                />
                <HorizontalRule/>
            </>}
            {sendSmsEnabled !== false && <>
                <SmsContentComponent
                    id='sms-content-text'
                    title='SMS Notification'
                    dataTest='sms-content'
                    label='Notification content'
                    helpMessage='The content of the SMS notification'
                    contentCallback={ (event) => handleContentCallback(event, 'sms') }
                    name='content'
                    value={ notificationRequest.sms.content }
                    inputError={ inputErrors.smsContent }
                />
                <HorizontalRule/>
            </>}
        </div>
    );
};

export default NotificationContentComponent;
