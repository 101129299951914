import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useOktaAuth} from '@okta/okta-react';
import {toRelativeUrl} from '@okta/okta-auth-js';
import {ComponentLoader} from '@snsw/react-component-library';
import authenticateServices from '../../services/authenticate-services';
import apiServices from '../../services/api-services';
import {useUserRoleState} from '../GlobalStateComponent/GlobalState';

const permissionAccessTitle = 'Permission access required';
const permissionAccessDescription = 'To access the notification portal you\'ll need to complete the ' +
    'Service NSW form and verify your agency identity.';

function ProtectedComponent(props) {
    const {oktaAuth, authState} = useOktaAuth();
    const {userRole, setUserRole} = useUserRoleState();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const state = useLocation().state;

    useEffect(() => {
        if (!authState) {
            return;
        }

        if (!(authState?.isAuthenticated)) {
            const originalUri = toRelativeUrl(window.location.href, window.location.origin);
            oktaAuth.setOriginalUri(originalUri);
            oktaAuth.signInWithRedirect();
        } else {
            function redirectToRequestAccessPage() {
                navigate('/AccessRequest', {
                    state: {
                        variant: 'warning',
                        title: permissionAccessTitle,
                        description: permissionAccessDescription,
                        visible: true
                    }
                });
            }

            function isDataValid(field) {
                return !(field.isEmpty || field.length === 0);
            }

            const accessErrorCodes = ['USR_NOT_FOUND', 'PERMISSIONS_ACCESS'];

            /**
             * Setting up axios interceptor with the Authorization header if there is a valid auth already present
             */
            if (authState && authState.isAuthenticated) {
                authenticateServices.setupAxiosInterceptors(oktaAuth.getIdToken(), {navigate});
            }
            if (userRole === undefined) {
                apiServices.fetchUserRole()
                    .then(userRole => {
                        setUserRole(userRole);
                        if (!isDataValid(Object.keys(userRole?.permissions)) ||
                            (!isDataValid(userRole?.permissions?.MANAGE_NOTIFICATION_TILE)
                                && !isDataValid(userRole?.permissions?.MANAGE_PREFERENCE_CONFIG_TILE)
                                && !isDataValid(userRole?.permissions?.TEST_NOTIFICATION_TILE)
                                && !isDataValid(userRole?.permissions?.SEND_CAMPAIGN)
                            )
                        ) {
                            redirectToRequestAccessPage();
                        }
                        state && setLoading(false);
                    })
                    .catch((error) => {
                        if (error.response && accessErrorCodes.includes(error.response.data.errorCode)) {
                            redirectToRequestAccessPage();
                        } else {
                            navigate('/', {
                                state: {
                                    variant: 'error',
                                    title: 'Network Error',
                                    description: error.message,
                                    visible: true

                                }
                            });
                        }
                        setLoading(false);
                    });
            } else {
                setLoading(false);
            }
        }
    }, [oktaAuth, authState, setLoading, navigate, userRole, setUserRole, state]);
    return (
        <div style={ {display: 'flex', flexDirection: 'column', height: '100vh'} }>
            {(!authState || !(authState?.isAuthenticated) || loading) ?
                <ComponentLoader label={ 'Loading...' }/> : props.children}
        </div>
    );
}

export default ProtectedComponent;